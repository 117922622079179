import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import * as Sc from './inputEmail.style'
import InputText from 'components/inputText'
import { checkEmail } from 'services/email/email'
import { emailIsCorrect } from 'utils'

const InputEmail = ({ autoFocus, checkHasEmail, emailValue }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setSetError] = useState(false)
  const [isCorrect, setIsCorrect] = useState(null)
  const [errorMsg, setErrorMsg] = useState('')

  const changeStates = ({ correct, isError }) => {
    setSetError(isError)
    setIsCorrect(correct)
    emailValue({
      emailValue: value.toLowerCase(),
      isCorrect: correct,
      isValid: !isError,
    })
  }

  useEffect(() => {
    let timeOut = null
    if (value.trim() !== '') {
      setLoading(true)
      setErrorMsg('')
      timeOut = setTimeout(async () => {
        const isValidEmail = emailIsCorrect({ email: value })
        changeStates({ icorrect: null, sError: true })
        setErrorMsg(t('El correo electrónico no es correcto'))
        if (isValidEmail) {
          try {
            const { exists } = await checkEmail({ email: value })
            changeStates({
              correct: checkHasEmail ? !exists : exists,
              isError: false,
            })
          } catch {
            changeStates({ correct: null, isError: true })
            setLoading(false)
            setErrorMsg(t('El correo electrónico no es correcto'))
          }
        }
        setLoading(false)
      }, 1000)
    }
    return () => clearTimeout(timeOut)
  }, [value])

  return (
    <>
      <InputText
        autoFocus={autoFocus}
        id='RecoverPassword'
        isCorrect={!error && isCorrect}
        label={t('Correo electrónico')}
        onChange={({ target }) => setValue(target.value.toLowerCase())}
        spinner={loading}
        type='text'
        value={value}
      />
      {isCorrect === false && checkHasEmail && (
        <Sc.Error>
          {t('Ya existe un usuario con este correo electrónico Inicia sesión')}
        </Sc.Error>
      )}
      {isCorrect === false && !checkHasEmail && (
        <Sc.Error>{t('El correo electrónico no es correcto')}</Sc.Error>
      )}

      {error && errorMsg && <Sc.Error>{errorMsg}</Sc.Error>}
    </>
  )
}

InputEmail.defaultProps = {
  autoFocus: true,
  checkHasEmail: false,
}

InputEmail.propTypes = {
  autoFocus: PropTypes.bool,
  checkHasEmail: PropTypes.bool,
  emailValue: PropTypes.func.isRequired,
}

export default InputEmail
