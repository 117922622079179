function Logo() {
  return (
    <svg
      height='512'
      viewBox='0 0 2449 512'
      width='2449'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Logo</title>
      <path
        d='M290.769 487.164c-40.12 16.238-61.134 23.88-111.761 23.88-91.701-.955-176.716-52.538-176.716-198.687 0-95.522 40.12-165.254 154.746-165.254 96.478 0 140.418 50.627 140.418 129.911v13.373H90.172c0 58.269 33.433 173.851 132.776 173.851 22.925 0 46.806-6.687 67.82-15.284v38.209zM211.484 256c0-24.835-10.507-73.552-58.268-73.552-50.627 0-61.134 57.314-64 73.552h122.269zM550.589 445.135h-1.91C538.171 467.105 511.425 512 454.112 512c-85.971-.955-115.582-82.149-115.582-179.582 0-116.537 37.254-183.403 127.044-183.403 56.358 0 73.552 34.388 84.06 54.447h1.91V.955h87.88v502.448h-87.88v-58.269zm-57.313 11.462c49.671 0 52.537-77.374 52.537-125.134 0-54.447-2.865-133.732-54.447-133.732s-58.269 79.284-58.269 133.732c.955 47.761 7.642 125.134 60.179 125.134zM903.067 425.075h-1.91c-13.373 28.657-40.12 85.971-106.03 85.971-66.865 0-94.567-42.03-94.567-115.582V155.702h87.88v216.836c0 42.985 12.418 75.462 44.895 75.462 57.313 0 69.731-100.298 69.731-155.702V155.701h87.88v346.746h-87.88v-77.374zM1458.052 329.552c0-131.821 56.358-181.493 144.239-181.493 88.836 0 144.239 48.716 144.239 181.493 0 131.821-55.403 181.493-144.239 181.493s-144.239-49.671-144.239-181.493zm144.239 148.06c54.447 0 58.268-70.686 58.268-147.104s-3.821-147.105-58.268-147.105c-53.493 0-58.268 70.686-58.268 147.105s4.776 147.105 58.268 147.105zM1783.783 329.552c0-131.821 56.358-181.493 144.239-181.493 88.836 0 144.239 48.716 144.239 181.493 0 131.821-55.403 181.493-144.239 181.493-87.88 0-144.239-49.671-144.239-181.493zm145.194 148.06c54.447 0 58.268-70.686 58.268-147.104s-3.821-147.105-58.268-147.105c-53.493 0-58.268 70.686-58.268 147.105-.955 76.418 3.821 147.105 58.268 147.105zM2133.395 0h87.881v289.433h1.91l110.806-134.686h97.433l-133.731 147.105 149.015 199.642h-106.985l-115.582-168.119h-1.91v168.12h-87.881L2133.396.002z'
        fill='#1d1d1b'
      />
      <path
        d='M1302.35 175.761c0 36.929-29.937 66.865-66.865 66.865s-66.865-29.936-66.865-66.865c0-36.929 29.937-66.865 66.865-66.865s66.865 29.936 66.865 66.865z'
        fill='#e30613'
      />
      <path
        d='M1416.022 318.089v-2.865c0-2.865 0-4.776-.955-7.642v-1.911c-4.776-35.344-18.149-66.865-40.12-93.612-5.732-6.686-12.418-13.373-19.104-20.059l-30.568 37.254c-6.687 10.507-14.328 20.059-22.925 28.657 10.507 9.552 18.149 21.97 22.925 35.344v.955c.955 1.911 1.91 4.776 1.91 6.687 0 .955.955 1.911.955 3.821 0 .955.955 1.911.955 2.865 0 1.911.955 3.821.955 4.776v1.911c0 1.911.955 3.821.955 5.731v8.598c0 53.493-42.03 96.478-95.523 96.478-52.537 0-94.567-42.03-94.567-94.567V.003h-87.88v502.448h87.88v-19.105s.955 0 .955.955c2.866 1.911 4.776 3.821 8.597 4.776.955 0 .955.955 1.91.955 3.821 1.911 6.687 3.821 10.507 4.776.955 0 .955.955 1.91.955 2.866.955 5.732 2.865 8.597 3.821.955 0 1.91.955 1.91.955 3.821.955 6.687 2.865 10.507 3.821h1.91c3.821.955 5.732 1.911 9.552 2.865.955 0 1.91 0 2.866.955 3.821.955 6.687 1.911 11.463 1.911h.955c3.821.955 6.687.955 10.507.955H1237.39c31.523 0 61.134-7.642 86.926-21.97 2.866-1.911 4.776-2.865 7.641-4.776 4.776-3.821 9.552-5.731 14.328-9.552 12.418-8.597 21.97-19.104 31.523-30.567 3.821-4.776 6.687-9.552 10.507-13.373 20.06-29.612 31.523-64 31.523-102.209-2.866-3.821-3.821-7.642-3.821-10.507z'
        fill='#e30613'
      />
    </svg>
  )
}

export default Logo
