function HamMenu() {
  return (
    <svg
      height='512'
      viewBox='0 0 512 512'
      width='512'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Hamburger Menu</title>
      <path d='M486.4 102.4H25.6C10.2 102.4 0 89.6 0 76.8s10.2-25.6 25.6-25.6h460.8c15.4 0 25.6 10.2 25.6 25.6s-10.2 25.6-25.6 25.6zM486.4 281.6H25.6C10.2 281.6 0 268.8 0 256s10.2-25.6 25.6-25.6h460.8c15.4 0 25.6 10.2 25.6 25.6s-10.2 25.6-25.6 25.6zM486.4 460.8H25.6C10.2 460.8 0 450.6 0 435.2s10.2-25.6 25.6-25.6h460.8c15.4 0 25.6 10.2 25.6 25.6 0 12.8-10.2 25.6-25.6 25.6z' />
    </svg>
  )
}

export default HamMenu
