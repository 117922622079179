import {
  initializeFaro as coreInit,
  FaroErrorBoundary,
} from '@grafana/faro-react'
import PropTypes from 'prop-types'
import { grafanaUtils } from 'utils/Grafana'

const GrafanaFaro = ({ children }) => {
  const { enabledGrafanaFaro } = window.fflags || {}

  const initializeFaro = () => {
    try {
      const initialOptions = grafanaUtils.getInitialOptions()
      coreInit(initialOptions)
    } catch (error) {
      console.error(error)
    }
  }

  if (enabledGrafanaFaro) {
    initializeFaro()
  }
  return <FaroErrorBoundary>{children}</FaroErrorBoundary>
}

GrafanaFaro.propTypes = {
  children: PropTypes.node.isRequired,
}
export default GrafanaFaro
