import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import * as Sc from './AplicationsDesktop.style'
import LinuxOsIcon from 'assets/icons/linux'
import MacOsIcon from 'assets/icons/mac'
import NewsIcon from 'assets/icons/news'
import WindowsOsIcon from 'assets/icons/windows'
import OtherOSCA from 'assets/images/desktop/ca/laptop.png'
import MacCA from 'assets/images/desktop/ca/mac.png'
import aplicationsImg from 'assets/images/desktop/cover.webp'
import OtherOSEN from 'assets/images/desktop/en/laptop.png'
import MacEN from 'assets/images/desktop/en/mac.png'
import OtherOSES from 'assets/images/desktop/es/laptop.png'
import MacES from 'assets/images/desktop/es/mac.png'
import Footer from 'components/footer'
import Hero from 'components/hero'
import i18n from 'config/i18n'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const AplicationsDesktop = () => {
  const { t } = useTranslation()
  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.APLICATIONS_DESKTOP)
  }, [])
  const img = {
    mac: {
      ca: MacCA,
      en: MacEN,
      es: MacES,
    },
    otherOs: {
      ca: OtherOSCA,
      en: OtherOSEN,
      es: OtherOSES,
    },
  }
  const defaultLang = i18n.language === 'en' ? 'es' : i18n.language
  return (
    <>
      <Hero
        alt={t('Dispositivos con la app de edubook abierta')}
        image={aplicationsImg}
        subTitle={t(
          'Descarga la aplicación de escritorio según tu sistema operativo'
        )}
        title={t('Edubook offline en tu ordenador')}
      />

      <Sc.News>
        <Sc.NewsContainer>
          <NewsIcon />
          <div>
            <h4>{t('Novedades')}</h4>
            <p>
              {t(
                '¡Ya está disponible la nueva versión de la aplicación de escritorio!'
              )}
            </p>
          </div>
        </Sc.NewsContainer>
      </Sc.News>
      <Sc.App>
        <Sc.Container>
          <div>
            <Sc.Title>
              <WindowsOsIcon />
              <h3>{t('Para Windows')}</h3>
            </Sc.Title>
            <Sc.Details>
              <p>{t('Windows 7 o superior')}</p>
              <p>{t('Mínimo 1GB de RAM (Recomendado 2GB)')}</p>
              <a
                href={`http://edubook.vicensvives.com/hc/${defaultLang}/articles/205864282-Gu%C3%ADa-de-instalaci%C3%B3n-para-Windows`}
                rel='noreferrer'
                target='_blank'
              >
                {t('Guía de instalación')}
              </a>
            </Sc.Details>
          </div>
          <Sc.Image>
            <img
              alt={t('App de edubook en un portatil')}
              src={img.otherOs[i18n.language]}
            />
          </Sc.Image>
        </Sc.Container>
        <Sc.Download
          download
          href='/downloads/Edubook-windows.exe'
          rel='external'
        >
          {t('Descargar')}
        </Sc.Download>
      </Sc.App>
      <Sc.Line />
      <Sc.App>
        <Sc.Container>
          <div>
            <Sc.Title>
              <LinuxOsIcon />
              <h3>{t('Para Linux')}</h3>
            </Sc.Title>
            <Sc.Details>
              <p>{t('Ubuntu 16 o superior')}</p>
              <p>{t('Guadalinex EDU 2013 o superior')}</p>
              <p>{t('Mínimo 1GB de RAM (Recomendado 2GB)')}</p>
              <a
                href={`https://vicensvivesdigital.zendesk.com/hc/${defaultLang}/articles/205864262-Gu%C3%ADa-de-instalac%C3%ADon-para-Linux`}
                rel='noreferrer'
                target='_blank'
              >
                {t('Guía de instalación')}
              </a>
            </Sc.Details>
          </div>
          <Sc.Image>
            <img
              alt={t('App de edubook en un portatil')}
              src={img.otherOs[i18n.language]}
            />
          </Sc.Image>
        </Sc.Container>
        <Sc.Download
          download
          href='/downloads/Edubook-i386.AppImage'
          rel='external'
        >
          {t('Descargar 32 bits')}
        </Sc.Download>
        <Sc.Download
          download
          href='/downloads/Edubook-x86_64.AppImage'
          rel='external'
        >
          {t('Descargar 64 bits')}
        </Sc.Download>
      </Sc.App>
      <Sc.Line />
      <Sc.App>
        <Sc.Container>
          <div>
            <Sc.Title>
              <MacOsIcon />
              <h3>{t('Para Mac')}</h3>
            </Sc.Title>
            <Sc.Details>
              <p>{t('Mac OS X 10.6 Snow Leopard o superior')}</p>
              <p>{t('Mínimo 1GB de RAM (Recomendado 2GB)')}</p>
              <a
                href={`https://vicensvivesdigital.zendesk.com/hc/${defaultLang}/articles/205864272-Gu%C3%ADa-de-instalaci%C3%B3n-para-MAC`}
                rel='noreferrer'
                target='_blank'
              >
                {t('Guía de instalación')}
              </a>
            </Sc.Details>
          </div>
          <Sc.Image>
            <img
              alt={t('App de edubook en un portatil')}
              src={img.mac[i18n.language]}
            />
          </Sc.Image>
        </Sc.Container>
        <Sc.Download download href='/downloads/Edubook-mac.zip' rel='external'>
          {t('Descargar')}
        </Sc.Download>
      </Sc.App>
      <Footer />
    </>
  )
}

export default AplicationsDesktop
