import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Image = styled.section`
  height: 150px;
  position: relative;
  > img {
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
  > h1 {
    align-items: center;
    bottom: 0;
    color: var(--white);
    display: flex;
    font-family: var(--fontRegular);
    font-size: 55px;
    font-weight: 400;
    justify-content: center;
    line-height: 59px;
    position: absolute;
    text-align: center;
    text-shadow: 0.7px 0.7px 3px var(--grey);
    top: 0;
    width: 100%;
    @media (max-width: ${DEVICES.SIZE_TABLET}) {
      font-size: 48px;
      line-height: 52px;
    }
    @media (max-width: ${DEVICES.SIZE_TABLET}) {
      font-size: 40px;
      line-height: 49px;
    }
  }
`

export const Main = styled.main`
  display: block;
  height: 100%;
  margin: 0 auto;
  padding: 60px 0;
  width: 790px;
  > p {
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 17px;
    line-height: normal;
    margin-bottom: 7px;
    > strong {
      font-family: var(--fontSansBold);
    }
  }
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    width: 80%;
  }
`

export const Row = styled.div`
  display: grid;
  gap: 0px 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'title title title'
    'BoxImage License Offer';
  margin-top: 67px;
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    grid-template-areas:
      'title title title'
      'BoxImage BoxImage BoxImage'
      'License License License'
      'Offer Offer Offer';
  }
  > h2 {
    color: var(--darkRed);
    font-family: var(--fontRegular);
    font-size: 30px;
    font-weight: 400;
    grid-area: title;
    line-height: 34px;
    margin-bottom: 30px;
    width: 100%;
  }
`

export const BoxImage = styled.div`
  grid-area: BoxImage;
  > img {
    width: 100%;
    @media (max-width: ${DEVICES.SIZE_MOBILE}) {
      padding: 30px;
    }
  }
`

export const License = styled.div`
  grid-area: License;
  margin-bottom: 30px;
`
export const Offer = styled.div`
  grid-area: Offer;
`

export const Question = styled.p`
  font-size: 17px;
  margin-bottom: 15px;
  min-height: 36px;
  > strong {
    font-family: var(--fontSansBold);
  }
`

export const List = styled.ul`
  list-style: inside;
  > li {
    font-family: var(--fontSansRegular);
    font-size: 17px;
    line-height: normal;
    margin-bottom: 15px;
    > a {
      color: var(--blueLink);
      text-decoration: none;
      :hover {
        color: var(--blueHover);
      }
    }
  }
`
