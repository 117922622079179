export const DEVICES = {
  SIZE_LAPTOP: '1024px',
  SIZE_MOBILE: '425px',
  SIZE_MOBILE_BIG: '600px',
  SIZE_TABLET: '768px',
}

export const EXCEPTIONS_LICENSE = {
  ACTIVATION: 'LicenseActivationException',
  ALREADY_ACTIVE: 'LicenseAlreadyActivatedException',
  BASIC_UPGRADED_PREMIUM: 'LicenseBasicUpgradedToPremiumException',
  ENTITY_NOT_EXIST: 'EntityNotExistException',
  ILLEGAL_ARGUMENT: 'IllegalArgumentException',
  ILLEGAL_PUBLICATION: 'IllegalPublicationStateException',
  INCORRECT_PREFIX: 'LicenseIncorrectPrefixException',
  LICENSE_EXPIRED_EXCEPTION: 'LicenseExpiredException',
  LICENSE_INCORRECT_LENGTH: 'LicenseIncorrectLengthException',
  LICENSE_NOT_VALID: 'LicenseNotValidException',
  NOT_FOUND: 'LicenseNotFoundException',
  NOT_FOUND_HTTP: 'NotFoundHttpException',
  PUBLICATION_WITHOUT_AUTONOMIES:
    'LicenseForPublicationWithoutAutonomiesException',
  REST_CLIENT: 'RestClientException',
}

export const USER_ROLES = {
  TEACHER: 'TEACHER',
}

export const AGE_RANGES = {
  UNDER_14: 'UNDER_14',
}

export const TYPE_LICENSE = {
  BASIC: 'BASIC',
  PREMIUM: 'PREMIUM',
}

export const PUBLICATION_TYPES = {
  BOOK: 'BOOK',
  BOOK_AGGREGATION: 'BOOK_AGGREGATION',
}

export const PUBLICATION_STATUS = {
  INACTIVE: 'INACTIVE',
  PUBLISH: 'PUBLISH',
}

export const KEYEVENT = {
  DOM_VK_ESCAPE: 27,
}

export const TYPES_DOMAIN = {
  PRE: 'pre',
  PRO: 'com',
}
