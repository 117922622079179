function Marks() {
  return (
    <svg
      height='512'
      viewBox='0 0 512 512'
      width='512'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M430 384H221c-6.4.6-11.2 6.2-10.6 12.8.6 5.6 5 10 10.6 10.6h209c6.4-.6 11.2-6.2 10.6-12.8-.6-5.6-5-10-10.6-10.6zM116.4 69.8l-12.6 33.8h-34l27.4 21-12.6 38 31.8-23.2 31.8 23.2-12.6-38 27.6-21.2h-34l-12.8-33.6zM129 383l-12.6-33.8-12.6 33.8h-34l27.4 21-12.6 38 31.8-23.2 31.8 23.2-12.6-38 27.6-21.2-34.2.2zm-12.6-173.6l-12.6 33.8h-34l27.4 21.2-12.6 38 31.8-23.2 31.8 23.2-12.6-38 27.6-21.2h-34l-12.8-33.8zm313.6 35H221c-6.4-.6-12.2 4.2-12.8 10.6s4.2 12.2 10.6 12.8H430c6.4.6 12.2-4.2 12.8-10.6s-4.2-12.2-10.6-12.8c-.6-.2-1.4-.2-2.2 0zM465.4 0H46.6C20.8 0 0 20.8 0 46.6v418.8C0 491 20.8 512 46.4 512h419c25.6 0 46.6-20.8 46.6-46.4v-419C512 20.8 491.2 0 465.4 0zm23.2 465.4c0 12.8-10.4 23.2-23.2 23.2H46.6c-12.8 0-23.2-10.4-23.2-23.2V46.6c0-12.8 10.4-23.2 23.2-23.4h419c12.8 0 23.2 10.4 23.2 23.2v419zM430 104.8H221c-6.4-.6-12.2 4.2-12.8 10.6s4.2 12.2 10.6 12.8H430c6.4.6 12.2-4.2 12.8-10.6s-4.2-12.2-10.6-12.8c-.6-.2-1.4-.2-2.2 0z' />
    </svg>
  )
}

export default Marks
