import styled from 'styled-components/macro'

export const Container = styled.div`
  h1,
  h3 {
    margin: 0 auto;
    max-width: 820px;
  }
`

export const Feature = styled.section`
  p {
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 17px;
    line-height: normal;
    margin-bottom: 7px;
  }

  strong {
    font-family: var(--fontSansBold);
  }
`
