import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Container = styled.header`
  background-color: var(--white);
  box-shadow: 0 1px 3px var(--silverLine);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;
`

export const Nav = styled.nav`
  display: flex;
  height: 70px;
  justify-content: space-between;
  margin: 0 auto;
  width: 990px;
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    width: 100%;
  }
`

export const Logo = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 130px;
  > svg {
    height: 100%;
    width: 100%;
  }
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    margin-left: 20px;
  }
`

export const List = styled.ul`
  align-items: center;
  display: flex;
  font-family: var(--fontRegular);
  font-size: 14px;
  height: 100%;
`

export const BoxLink = styled.div`
  align-items: center;
  border-top: ${({ active }) => active && '7px solid var(--orange)'};
  display: flex;
  height: 100%;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    background-color: ${({ active }) => active && 'var(--orange)'};
    border-bottom: 1px solid var(--orange);
    border-top: 0;
    &:last-child {
      background-color: var(--white);
      > a {
        color: var(--black);
      }
    }
    :hover {
      background-color: var(--orange);
    }
  }
  > a {
    align-items: center;
    color: ${({ active }) => !active && 'var(--silverLine)'};
    display: flex;
    flex-direction: column-reverse;
    font-family: var(--fontBold);
    justify-content: center;
    min-width: 95px;
    padding: ${({ active }) => (active ? '20px 10px;' : '27px 10px')};
    text-decoration: none;
    :hover {
      color: var(--black);
      font-family: var(--fontBold);
    }
    @media (max-width: ${DEVICES.SIZE_TABLET}) {
      color: ${({ active }) => (active ? 'var(--black);' : 'var(--white);')};
      display: inline-block;
      font-family: var(--fontRegular);
      font-size: 16px;
      padding: 15px 20px;
      text-align: left;
      width: 100%;
      :hover {
        font-family: var(--fontRegular);
      }
    }
  }
`

export const IconMenu = styled.div`
  align-items: center;
  background-color: ${({ open }) => open && 'var(--grey)'};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 65px;
  > svg {
    fill: ${({ open }) => (open ? 'var(--white)' : 'var(--grey)')};
    height: 25px;
    width: 25px;
  }
`

export const MenuHamburger = styled.nav`
  background-color: var(--grey);
  height: 100%;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 70px;
  transform: ${({ open }) =>
    open ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)'};
  transition: all 0.5s;
  width: 280px;
  z-index: 99999;
  > ul {
    border: solid;
    display: flex;
    flex-direction: column;
    @media (max-width: ${DEVICES.SIZE_TABLET}) {
      border: 0;
    }
    > a {
      align-items: center;
      border-bottom: 1px solid var(--orange);
      color: var(--white);
      display: flex;
      font-size: 15px;
      height: 50px;
      justify-content: flex-start;
      padding-left: 15px;
      text-decoration: none;
      width: 100%;
      :hover {
        background-color: var(--orange);
      }
      @media (max-width: ${DEVICES.SIZE_TABLET}) {
        background-color: var(--transparentGrey);
      }
    }
  }
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    background-color: var(--transparentGrey);
  }
`

export const CustomButton = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.9375rem;
  font-weight: 400;
  justify-content: center;
  margin: 0 0.3125rem 0 0.9375rem;
  > a {
    align-items: center;
    background-color: ${({ primary }) =>
      primary ? 'var(--yellow)' : 'var(--white)'};
    border-radius: 0.1875rem;
    ${({ primary }) => !primary && 'border: solid 1px'};
    display: flex;
    height: 2.8125rem;
    justify-content: center;
    padding: 0.6875rem;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
    :hover {
      background-color: ${({ primary }) =>
        primary ? 'var(--yellowHover)' : 'var(--whiteHover)'};
    }
  }
`
