import { useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import * as Sc from './SelectInput.style'
import { ArrowSelect } from 'assets/icons'

const SelectInput = ({
  defaultValue,
  disabled,
  options,
  label,
  name,
  placeholder,
  onValueSelected,
}) => {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(false)
  const [value, setValue] = useState(null)

  const setNewValue = (newValue) => {
    setValue(newValue)
    onValueSelected(newValue)
  }

  return (
    <Sc.SelectContainer>
      {label && <Sc.SelectLabel htmlFor={name}>{label}</Sc.SelectLabel>}
      <Sc.SelectComponent isFocused={isFocused}>
        <Sc.SelectArrowContainer>
          <ArrowSelect />
        </Sc.SelectArrowContainer>
        <Sc.SelectOptionsContainer
          disabled={disabled}
          onBlur={() => setIsFocused(!isFocused)}
          onChange={(event) => setNewValue(event.target.value)}
          onFocus={() => setIsFocused(!isFocused)}
          value={
            value ||
            placeholder ||
            (defaultValue && defaultValue.value) ||
            (options && options[0].value)
          }
        >
          {placeholder && (
            <option disabled key='placeHolder' value={null}>
              {t(placeholder)}
            </option>
          )}
          {options &&
            options.map((option) => (
              <option key={option.value} value={option.value}>
                {t(option.description)}
              </option>
            ))}
        </Sc.SelectOptionsContainer>
      </Sc.SelectComponent>
    </Sc.SelectContainer>
  )
}

SelectInput.defaultProps = {
  defaultValue: false,
  disabled: false,
  label: null,
  placeholder: null,
}

SelectInput.propTypes = {
  defaultValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onValueSelected: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  placeholder: PropTypes.string,
}

export default SelectInput
