export const Base = `
  body{
    color: var(--grey);
    font-family: var(--fontRegular);
  }
  h1, h2 {
    display: block;
    font-family: var(--fontRegular);
    font-weight: 400;
    padding: 0;
    width: 100%;
  }

  h1 {
    font-size: 55px;
    line-height: normal;
  }

  h2 {
    color: var(--darkRed);
    font-size: 30px;
    line-height: normal;
  }

  h3 {
    color: var(--grey);
    display: block;
    font-family: var(--fontBold);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    padding: 0;
    width: 100%;
  }

  p {
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 17px;
    line-height: normal;
  }
  
  a {
    color: var(--grey);
  }

  ul {
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 17px;
    line-height: normal;
  }
`
