import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Container = styled.section`
  display: flex;
  justify-content: center;
`

export const Main = styled.main`
  align-items: center;
  color: var(--grey);
  margin-top: 190px;
  width: 500px;
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    width: 80%;
  }
  > h2 {
    color: var(--darkRed);
    font-family: var(--fontRegular);
    font-size: 30px;
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 30px;
  }
  > p {
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 17px;
    line-height: normal;
    margin-bottom: 7px;
    width: 100%;
  }
  > div {
    margin-top: 20px;
    width: 180px;
  }
`
