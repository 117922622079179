import styled from 'styled-components/macro'

import ca from 'assets/images/home/ca/cover.webp'
import en from 'assets/images/home/en/cover.webp'
import es from 'assets/images/home/es/cover.webp'
import { DEVICES } from 'utils/constants'

export const Section = styled.section`
  align-items: center;
  background-image: ${({ language }) => {
    switch (language) {
      case 'ca':
        return `url(${ca})`
      case 'en':
        return `url(${en})`
      default:
        return `url(${es})`
    }
  }};
  background-position: center -20px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 90vh;
  min-height: 600px;
  position: relative;

  > main {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-shadow: 0.7px 0.7px 3px var(--grey);
    width: 790px;
    @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
      width: 85%;
      margin: 40px auto;
      max-height: 650px;
    }
  }
`
export const TitleBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 430px;
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    min-height: 480px;
  }
`

export const Title = styled.h1`
  color: var(--white);
  font-size: 55px;
  line-height: 59px;
  padding-bottom: 30px;
  text-align: center;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    font-size: 45px;
    line-height: 49px;
  }
`

export const SubTitle = styled.h3`
  color: var(--white);
  font-family: var(--fontSansRegular);
  font-size: 22px;
  line-height: 26px;
  padding-bottom: 60px;
  text-align: center;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    font-size: 20px;
    line-height: 26px;
    padding-bottom: 25px;
  }
`

export const LinkEdubook = styled.div`
  cursor: pointer;
  margin: 120px 0 0;
  > a {
    align-items: center;
    color: var(--white);
    display: flex;
    flex-direction: column;
    font-family: var(--fontBold);
    font-size: 14px;
    justify-content: space-around;
    :hover {
      color: var(--whiteHover);
    }
    :active {
      color: var(--orange);
    }
    > svg {
      fill: var(--white);
      height: 40px;
      padding-top: 7px;
      width: 40px;
    }
  }
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    margin: 5px 0;
  }
`

export const SectionFeatures = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  main {
    width: 880px;
    @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
      width: 80%;
    }
    > h2 {
      color: var(--darkRed);
      font-size: 30px;
      line-height: 34px;
      margin: 60px 0;
      text-align: center;
    }
  }
  a {
    color: var(--blueLink);
    font-family: var(--fontSansRegular);
    font-size: 17px;
    line-height: inherit;
    margin: 30px 0 60px;
    outline: 0;
    text-align: center;
    text-decoration: underline;
    :hover {
      color: var(--blueHover);
    }
  }
`
