import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  LicenseNotFoundException,
  LicenseAlreadyActivatedException,
  LicenseExpiredException,
} from './ErrorMgs'
import * as Sc from './InfoMessage.style'
import { EXCEPTIONS_LICENSE } from 'utils/constants'

const InfoMessage = ({ infoLicense, isUnder }) => {
  const { t } = useTranslation()

  if (infoLicense.error || infoLicense.correct) {
    return (
      <Sc.BoxMessage data-testid='box-message' isError={infoLicense.error}>
        <p>
          {(infoLicense.message === EXCEPTIONS_LICENSE.ALREADY_ACTIVE && (
            <LicenseAlreadyActivatedException />
          )) ||
            ([
              EXCEPTIONS_LICENSE.ACTIVATION,
              EXCEPTIONS_LICENSE.ENTITY_NOT_EXIST,
              EXCEPTIONS_LICENSE.ILLEGAL_ARGUMENT,
              EXCEPTIONS_LICENSE.ILLEGAL_PUBLICATION,
              EXCEPTIONS_LICENSE.INCORRECT_PREFIX,
              EXCEPTIONS_LICENSE.LICENSE_NOT_VALID,
              EXCEPTIONS_LICENSE.NOT_FOUND_HTTP,
              EXCEPTIONS_LICENSE.NOT_FOUND,
              EXCEPTIONS_LICENSE.PUBLICATION_WITHOUT_AUTONOMIES,
              EXCEPTIONS_LICENSE.REST_CLIENT,
            ].includes(infoLicense.message) && <LicenseNotFoundException />) ||
            (infoLicense.message ===
              EXCEPTIONS_LICENSE.BASIC_UPGRADED_PREMIUM && (
              <>
                {t(infoLicense.message)}
                <Sc.ExtraMessage>
                  {t(
                    'Para acceder al contenido debes iniciar sesión en Edubook y seleccionar el libro.'
                  )}
                </Sc.ExtraMessage>
              </>
            )) ||
            (infoLicense.message ===
              EXCEPTIONS_LICENSE.LICENSE_EXPIRED_EXCEPTION && (
              <LicenseExpiredException />
            )) ||
            t(infoLicense.message)}
        </p>

        {infoLicense.correct && isUnder && (
          <span>
            {t(
              'Si eres menor de 14 años debe registrarte tu familiar o representante legal. Si ya tienes cuenta debes iniciar sesión'
            )}
          </span>
        )}
      </Sc.BoxMessage>
    )
  }

  return (
    <Link to={t('routes::/license')}>{t('¿Aún no tienes una licencia?')}</Link>
  )
}

InfoMessage.propTypes = {
  infoLicense: PropTypes.shape({
    correct: PropTypes.bool,
    error: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
  isUnder: PropTypes.bool.isRequired,
}

export default InfoMessage
