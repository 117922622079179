import { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import * as Sc from './Aplications.style'
import LinuxOs from 'assets/icons/linux'
import MacOs from 'assets/icons/mac'
import WindowsOs from 'assets/icons/windows'
import Monitor from 'assets/images/aplications/aplication_pc_geo.png'
import MoodleCa from 'assets/images/aplications/ca/moodle.png'
import aplicationsImg from 'assets/images/aplications/cover.webp'
import MoodleEn from 'assets/images/aplications/en/moodle.png'
import MoodleEs from 'assets/images/aplications/es/moodle.png'
import Alexia from 'assets/images/aplications/logo-alexia.png'
import GClassroom from 'assets/images/aplications/logo-classroom.png'
import Clickedu from 'assets/images/aplications/logo-clickedu.png'
import Escholarium from 'assets/images/aplications/logo-escholarium.png'
import Moodle from 'assets/images/aplications/logo-moodle.png'
import Weeras from 'assets/images/aplications/logo-weeras.png'
import Footer from 'components/footer'
import Hero from 'components/hero'
import Tablets from 'components/tablets'
import i18n from 'config/i18n'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const Aplications = () => {
  const { t } = useTranslation()
  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.APPLICATIONS)
  }, [])
  const img = {
    moodle: {
      ca: MoodleCa,
      en: MoodleEn,
      es: MoodleEs,
    },
  }
  return (
    <>
      <Hero
        alt={t('Dispositivos con la app de edubook abierta')}
        image={aplicationsImg}
        subTitle={t(
          'Accede a Edubook a través de nuestras aplicaciones para tableta, ordenador o desde el LMS de tu centro educativo'
        )}
        title={t('Disponible en todos los dispositivos')}
      />
      <Tablets />
      <Sc.Article>
        <Sc.Description>
          <Trans i18nKey='Escritorio App'>
            <h2>Escritorio</h2>
            <p>Descarga tu aplicación de escritorio para:</p>
            <div>
              <MacOs />
              <div>
                <p>Mac OS X 10.6 Snow Leopard o superior </p>
                <p>Mínimo 1GB de RAM (Recomendado 2GB)</p>
              </div>
            </div>
            <div>
              <WindowsOs />
              <div>
                <p>Windows 7 o superior </p>
                <p>Mínimo 1GB de RAM (Recomendado 2GB)</p>
              </div>
            </div>
            <div>
              <LinuxOs />
              <div>
                <p>Ubuntu 16 o superior </p>
                <p>Guadalinex EDU 2013 o superior </p>
                <p>Mínimo 1GB de RAM (Recomendado 2GB)</p>
              </div>
            </div>
          </Trans>
          <Link to={t('routes::/apps/desktop')}>{t('Ver más')}</Link>
        </Sc.Description>
        <Sc.Image>
          <img alt={t('Libro Abierto')} src={Monitor} />
        </Sc.Image>
      </Sc.Article>
      <Sc.Line />
      <Sc.Article>
        <Sc.Description>
          <Trans i18nKey='Integración Moodle'>
            <h2>
              <img alt={t('Logo de Alexia')} src={Moodle} />
              Integración Moodle por LTI
            </h2>
            <strong>
              Vicens Vives ha desarrollado 5 plugins que hacen uso del protocolo
              LTI para que la integración de Edubook en Moodle sea ágil, rápida
              y sencilla. Estos nuevos plugins permiten:
            </strong>

            <ul>
              <li>Importación automática de cursos.</li>
              <li>Asignación automática de licencias.</li>
              <li>Sincronización automática de calificaciones.</li>
              <li>Integración con tabletas, web y escritorio.</li>
            </ul>
          </Trans>
          <Link to={t('routes::/apps/moodle')}>
            {t('Descubre los plugins de Edubook')}
          </Link>
          <p>
            <em>{t('Integración también disponible por Marsupial')}</em>
          </p>
        </Sc.Description>
        <Sc.Image>
          <img
            alt={t('Vista del Moodle de edubook')}
            src={img.moodle[i18n.language]}
          />
        </Sc.Image>
      </Sc.Article>
      <Sc.Platforms>
        <h3>{t('Otras plataformas compatibles:')}</h3>
        <div>
          <img alt={t('Logo de Alexia')} src={Alexia} />
          <img alt={t('Logo de Google Classroom')} src={GClassroom} />
          <img alt={t('Logo de ClickEdu')} src={Clickedu} />
          <img alt={t('Logo de Escholarium')} src={Escholarium} />
          <img alt={t('Logo de Weeras')} src={Weeras} />
        </div>
      </Sc.Platforms>
      <Footer />
    </>
  )
}

export default Aplications
