import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const News = styled.section`
  background-color: var(--bannerYellow);
  padding: 15px 0;
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    padding: 15px 20px;
  }
  svg {
    width: 40px;
    margin-right: 20px;
  }
`

export const NewsContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 800px;
  h4 {
    font-family: var(--fontBold);
    font-size: 20px;
    line-height: normal;
  }
  p {
    font-family: var(--sourceSansRegular);
    font-size: 17px;
    @media (max-width: ${DEVICES.SIZE_TABLET}) {
      font-size: 15px;
    }
    line-height: normal;
  }
`

export const App = styled.article`
  padding: 60px 0;
  max-width: 800px;
  margin: 0 auto;
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    padding: 60px 20px;
  }
`

export const Download = styled.a`
  @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
    width: 100%;
  }
  border-radius: 3px;
  border: 1px solid var(--grey);
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  height: 60px;
  line-height: 45px;
  margin-right: 5px;
  margin-top: 20px;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  &:hover {
    border: 1px solid var(--greyHover);
    color: var(--greyHover);
  }
  &:active {
    border: 1px solid var(--orange);
    color: var(--orange);
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
    flex-direction: column;
    align-items: center;
  }

  & > a {
    border-radius: 3px;
    border: 1px solid var(--grey);
    box-sizing: border-box;
    display: inline-block;
    font-size: 14px;
    height: 60px;
    line-height: 45px;
    margin-right: 5px;
    margin-top: 20px;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    &:hover {
      border: 1px solid var(--greyHover);
      color: var(--greyHover);
    }
    &:active {
      border: 1px solid var(--orange);
      color: var(--orange);
    }
  }
`

export const Title = styled.div`
  display: flex;
  margin-bottom: 37px;
  svg {
    margin-right: 10px;
    width: 30px;
  }

  h3 {
    color: var(--darkRed);
    font-family: var(--fontRegular);
    font-size: 30px;
    font-weight: 400;
    line-height: 34px;
    @media (max-width: ${DEVICES.SIZE_TABLET}) {
      font-size: 25px;
    }
  }
`

export const Details = styled.div`
  p {
    line-height: normal;
    font-size: 17px;
    @media (max-width: ${DEVICES.SIZE_TABLET}) {
      font-size: 15px;
    }
  }
  a {
    color: var(--blueLink);
    cursor: pointer;
    display: block;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 20px;
    outline: 0;
    font-size: 14px;
    text-decoration: underline;
  }
`

export const Image = styled.div`
  width: 350px;
  img {
    width: 100%;
  }
`

export const Line = styled.div`
  border-bottom: 1px solid var(--buttonSilver);
  height: 3px;
  width: 100%;
`
