import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'

import * as Sc from './Footer.style'
import { LogoFooter } from 'assets/icons'
import { IconFacebook } from 'assets/icons/facebookIcon'
import { IconInstragram } from 'assets/icons/instagramIcon'
import { IconTwitter } from 'assets/icons/twitterIcon'
import { IconYoutube } from 'assets/icons/youtubeIcon'
import AppsBanner from 'components/AppsBanner'
import DemoBanner from 'components/demoBanner'
import SelectInput from 'components/selectInput'
import { isDevelopment, getDomain, getUrlParams } from 'utils'

const Footer = ({ showAppsSection, showDemoSection }) => {
  const {
    t,
    i18n,
    i18n: { store },
    i18n: { language },
  } = useTranslation()
  const { push } = useHistory()

  const { pathname } = useLocation()
  const domain = getDomain()
  const protocol = isDevelopment() ? 'http' : 'https'

  const handleChangeLanguage = (newLanguage) => {
    // WIP: MEJORAR
    const { routes } = store.data[language]
    i18n.changeLanguage(newLanguage)
    if (pathname === '/' || pathname === `/${language}`) {
      push(t(`routes::/${newLanguage}`))
    } else {
      const email = getUrlParams('email')
      const newPath =
        pathname.substr(pathname.length - 1) === '/'
          ? pathname.slice(0, -1)
          : pathname
      const translatedPath = Object.keys(routes).find(
        (key) => routes[key] === newPath
      )
      if (email) {
        const newRoute = t(`routes::${translatedPath}`)
        push(`${newRoute}/?email=${email}`)
      } else {
        push(t(`routes::${translatedPath}`))
      }
    }
  }

  const EdubookMenu = () => (
    <>
      <Sc.SectionTitle>EDUBOOK</Sc.SectionTitle>
      <Link
        to={(location) => ({
          ...location,
          pathname: t('routes::/features'),
          search: '?qr=0',
        })}
      >
        {t('Características')}
      </Link>
      <Link to={t('routes::/classroom')}>{t('En el aula')}</Link>
      <Link to={t('routes::/apps')}>{t('Aplicaciones')}</Link>
      <Link to={t('routes::/demo')}>{t('Solicita una demo')}</Link>
    </>
  )

  const CompanyMenu = () => (
    <>
      <Sc.SectionTitle>{t('EMPRESA')}</Sc.SectionTitle>
      <a
        href='http://www.vicensvives.com/vvweb/view/pages/p01/load.php?id=2554&amp;_ga=2.157434553.2087473364.1616999960-1077744128.1614613966'
        rel='noreferrer'
        target='_blank'
      >
        {t('¿Quiénes somos?')}
      </a>
      <a
        href='http://www.vicensvives.com/vvweb/view/pages/p01/load.php?id=2554&amp;_ga=2.219194650.2087473364.1616999960-1077744128.1614613966'
        rel='noreferrer'
        target='_blank'
      >
        {t('¿Dónde estamos?')}
      </a>
    </>
  )

  const SupportMenu = () => (
    <>
      <Sc.SectionTitle>{t('APOYO TÉCNICO')}</Sc.SectionTitle>
      <Link to={t('routes::/license')}>{t('Conseguir licencia')}</Link>
      <a
        href={`//vicensvivesdigital.zendesk.com/hc/${language}/`}
        rel='noreferrer'
        target='_blank'
      >
        {t('Ayuda')}
      </a>
    </>
  )

  const SocialLangMenu = () => (
    <>
      <SelectInput
        defaultValue={{ description: t(language), value: language }}
        name={t('Idioma')}
        onValueSelected={(value) => handleChangeLanguage(value)}
        options={[
          { description: t('Català'), value: 'ca' },
          { description: t('English'), value: 'en' },
          { description: t('Español'), value: 'es' },
        ]}
      />
      <Sc.IconContainer>
        <a
          href='https://es-es.facebook.com/vicensvives'
          rel='noreferrer'
          target='_blank'
        >
          <IconFacebook />
        </a>
        <a
          href='https://twitter.com/vicensvives'
          rel='noreferrer'
          target='_blank'
        >
          <IconTwitter />
        </a>
        <a
          href='https://www.youtube.com/channel/UCab3k182MBZSBjzSKlsOaow'
          rel='noreferrer'
          target='_blank'
        >
          <IconYoutube />
        </a>
        <a
          href='https://www.instagram.com/vicensvives'
          rel='noreferrer'
          target='_blank'
        >
          <IconInstragram />
        </a>
      </Sc.IconContainer>
    </>
  )

  return (
    <Sc.Container>
      {showDemoSection && <DemoBanner />}
      {showAppsSection && <AppsBanner />}
      <Sc.FooterTop>
        <Sc.BoxMenu>
          <span>
            <LogoFooter />
          </span>
          <Sc.CopyRightText>© Editorial Vicens Vives</Sc.CopyRightText>
        </Sc.BoxMenu>
        <Sc.BoxMenu>{EdubookMenu()}</Sc.BoxMenu>
        <Sc.BoxMenu>{CompanyMenu()}</Sc.BoxMenu>
        <Sc.BoxMenu>{SupportMenu()}</Sc.BoxMenu>
        <Sc.BoxMenuSocial>{SocialLangMenu()}</Sc.BoxMenuSocial>
      </Sc.FooterTop>
      <Sc.FooterBotton>
        <a
          href={`${protocol}://legal.${domain}/${language}?_ga=2.212090196.1915544382.1617786853-780997398.1615286388`}
          rel='noreferrer'
          target='_blank'
        >
          {t('Aviso legal y política de privacidad')}
        </a>
      </Sc.FooterBotton>
    </Sc.Container>
  )
}

Footer.defaultProps = {
  showAppsSection: false,
  showDemoSection: true,
}

Footer.propTypes = {
  showAppsSection: PropTypes.bool,
  showDemoSection: PropTypes.bool,
}

export default Footer
