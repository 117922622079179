import { createContext, useState } from 'react'

import PropTypes from 'prop-types'

export const LoginWithLicenseContext = createContext()

export const LoginWithLicenseProvider = ({ children }) => {
  const [licenseLogin, setLicenseLogin] = useState()

  return (
    <LoginWithLicenseContext.Provider value={{ licenseLogin, setLicenseLogin }}>
      {children}
    </LoginWithLicenseContext.Provider>
  )
}

LoginWithLicenseProvider.defaultProps = {
  children: null,
}

LoginWithLicenseProvider.propTypes = {
  children: PropTypes.node,
}
