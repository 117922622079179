import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import * as Sc from './Register.style'
import CoverAddBook from 'components/coverAddBook'
import FormRegister from 'components/formRegister'
import useLoginWithLicenseContext from 'hooks/useLoginWithLicenseContext'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const Register = () => {
  const { t } = useTranslation()
  const { licenseLogin } = useLoginWithLicenseContext()
  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.REGISTER)
  }, [])
  const isTeacher = licenseLogin?.license.product.target === 'TEACHER'

  const courseLevel =
    licenseLogin?.license.product.publication.course.level.name
  const courseName = licenseLogin?.license.product.publication.course.name
  const licenseCode = licenseLogin?.license.code

  if (!licenseLogin) {
    return <Redirect to={t('routes::/')} />
  }

  return (
    <Sc.Container>
      <Sc.Main>
        <h2>
          {isTeacher
            ? t('Regístrate como docente en Edubook')
            : t('Regístrate como estudiante en Edubook')}
        </h2>

        <Sc.SubTitle>
          {isTeacher
            ? t(
                'Vincula el libro digital a tu cuenta para aprovechar todas las ventajas de Edubook'
              )
            : t(
                'Vincula el libro digital a tu cuenta para aprender con Edubook'
              )}
        </Sc.SubTitle>
        <Sc.Box>
          {licenseLogin && (
            <CoverAddBook
              course={`${t(courseName)} ${t(courseLevel)}`}
              image={
                licenseLogin.license.product.publication.covers?.cua_256?.url
              }
              licenseCode={licenseLogin.license.code}
              title={licenseLogin.license.product.publication.title}
            />
          )}
          <Sc.ContainerForm>
            <FormRegister isTeacher={isTeacher} licenseCode={licenseCode} />
          </Sc.ContainerForm>
        </Sc.Box>
      </Sc.Main>
    </Sc.Container>
  )
}

export default Register
