import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Article = styled.div`
  ${({ reverse }) => reverse && 'flex-direction: row-reverse;'}
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 850px;
  overflow-x: hidden;
  padding: 60px 20px;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    align-items: center;
    flex-direction: column;
  }
`
export const Line = styled.div`
  border-bottom: 1px solid var(--buttonSilver);
  height: 3px;
  width: 100%;
`

export const Description = styled.div`
  max-width: 410px;
  & h2 {
    color: var(--darkRed);
    display: block;
    font-size: 30px;
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 10px;
    width: 100%;

    & img {
      margin-right: 10px;
      width: 30px;
      margin-bottom: -3px;
    }
  }
  & p,
  & strong {
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 17px;
    line-height: normal;
    margin-bottom: 30px;
  }
  & > div {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 0;
    min-height: 35px;
    position: relative;
    width: 100%;
    & p {
      margin-bottom: 2px;
    }
    & svg {
      width: 30px;
      margin-right: 20px;
    }
  }

  ul {
    list-style: none;
    margin: 15px 0;
    padding: 0;
    li:before {
      color: var(--darkRed);
      content: '• ';
      font-size: 22px;
      line-height: 21px;
    }
  }

  & a {
    color: var(--blueLink);
    cursor: pointer;
    display: block;
    margin-top: 30px;
    line-height: normal;
    outline: 0;
    text-decoration: underline;
  }
`
export const Image = styled.div`
  grid-area: image;
  max-width: 400px;
  padding: 0 20px;
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    max-width: 100%;
  }

  & img {
    display: block;
    max-width: 900px;
    @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
      margin: 20px 0;
      width: 100%;
    }
  }
`

export const Platforms = styled.div`
  margin: 0 auto;
  max-width: 850px;
  padding-bottom: 60px;
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    padding: 20px;
  }
  h3 {
    margin-bottom: 10px;
  }
  div {
    display: flex;
    @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
      flex-wrap: wrap;
    }
  }
  img {
    margin: 10px;
    object-fit: contain;
  }
`
