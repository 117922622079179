import { useEffect } from 'react'

import { PropTypes } from 'prop-types'
import FocusLock from 'react-focus-lock'

import { KEYEVENT } from '../../utils/constants'
import * as Sc from './Modal.style'
import Portal from 'components/portal'

const Modal = ({ children, id, isOpen, onClose }) => {
  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.keyCode === KEYEVENT.DOM_VK_ESCAPE && isOpen) {
        onClose()
      }
    }
    if (isOpen) {
      document.getElementById('root')?.setAttribute('aria-hidden', 'true')
      document.body.style.overflow = 'hidden'
      document.addEventListener('keydown', onKeyDown, false)
    }
    return () => {
      document.getElementById('root')?.removeAttribute('aria-hidden')
      document.body.style.overflow = 'unset'
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [isOpen])

  return isOpen ? (
    <Portal id={id}>
      <FocusLock autoFocus={false}>
        <Sc.ModalContainer
          aria-labelledby={id}
          aria-modal
          role='dialog'
          tabIndex={-1}
        >
          {children}
        </Sc.ModalContainer>
      </FocusLock>
    </Portal>
  ) : null
}

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Modal
