import Routes from './routes.json'
import i18n from 'config/i18n'
import Aplications from 'pages/aplications'
import AplicationsDesktop from 'pages/aplicationsDesktop'
import AplicationsMoodle from 'pages/aplicationsMoodle'
import Classroom from 'pages/classroom'
import Demo from 'pages/demo'
import Features from 'pages/features'
import Home from 'pages/home'
import License from 'pages/license'
import Login from 'pages/login'
import PasswordRecovery from 'pages/passwordRecovery'
import PasswordRecoverySent from 'pages/passwordRecoverySent'
import PasswordReset from 'pages/passwordReset'
import Register from 'pages/register'
import RegisterFamily from 'pages/registerFamily'

const availableLanguages = Object.keys(i18n.services.resourceStore.data)

const generateTranslatedPaths = (keyPath, component) =>
  availableLanguages.map((lng) => {
    const path = Routes[lng][keyPath]
      ? Routes[lng][keyPath].replace(/{{(.\w*)}}/g, ':$1')
      : keyPath
    return { component, path }
  })

const indexRoutes = window?.rebranding?.showRebrandingModal
  ? [...generateTranslatedPaths('/', Home)]
  : [
      ...generateTranslatedPaths('/', Home),
      ...generateTranslatedPaths('/apps', Aplications),
      ...generateTranslatedPaths('/apps/desktop', AplicationsDesktop),
      ...generateTranslatedPaths('/apps/moodle', AplicationsMoodle),
      ...generateTranslatedPaths('/classroom', Classroom),
      ...generateTranslatedPaths('/demo', Demo),
      ...generateTranslatedPaths('/demo/:mail', Demo),
      ...generateTranslatedPaths('/features', Features),
      ...generateTranslatedPaths('/license', License),
      ...generateTranslatedPaths('/login', Login),
      ...generateTranslatedPaths('/login:qa', Login),
      ...generateTranslatedPaths('/recoverPassword', PasswordRecovery),
      ...generateTranslatedPaths('/resetPassword', PasswordReset),
      ...generateTranslatedPaths('/recoverPassword/sent', PasswordRecoverySent),
      ...generateTranslatedPaths('/register', Register),
      ...generateTranslatedPaths('/register/family', RegisterFamily),
    ]

export default indexRoutes
