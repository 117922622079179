import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import * as Sc from './FormPasswordReset.style'
import Button from 'components/Button'
import InputText from 'components/inputText'
import { changePassword } from 'services/email/email'
import { getUrlParams } from 'utils'

const FormPasswordReset = () => {
  const { t } = useTranslation()
  const [newPassword1, setNewPassword1] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [disabled, setDisabled] = useState(true)
  const userID = getUrlParams('u')
  const [error, setError] = useState(false)
  const recoverPasswordRequestId = getUrlParams('id')

  useEffect(() => {
    setDisabled(true)
    if (
      (newPassword1.trim() !== '' || newPassword2.trim() !== '') &&
      newPassword1 === newPassword2
    ) {
      setDisabled(false)
    }
  }, [newPassword1, newPassword2])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await changePassword({
        newPassword1,
        newPassword2,
        recoverPasswordRequestId,
        userID,
      })
      window.location = window.config.defUrl
    } catch (err) {
      setDisabled(true)
      setError(true)
    }
  }

  return (
    <Sc.Form onSubmit={handleSubmit}>
      <Sc.Row>
        <InputText
          id='newPassword1'
          label={t('Nueva contraseña')}
          onChange={({ target: { value } }) => setNewPassword1(value)}
          type='password'
          value={newPassword1}
        />
      </Sc.Row>
      <Sc.Info>{t('La contraseña debe tener al menos 6 caracteres')}</Sc.Info>
      <Sc.Row>
        <InputText
          id='newPassword2'
          label={t('Repite la contraseña')}
          onChange={({ target: { value } }) => setNewPassword2(value)}
          type='password'
          value={newPassword2}
        />
      </Sc.Row>
      {error && (
        <Sc.Error>
          {t('Ha ocurrido un error, por favor inténtelo de nuevo más tarde')}
        </Sc.Error>
      )}
      <Sc.Box>
        <Button
          dataTestid='buttonPasswordResey'
          disabled={disabled}
          type='submit'
        >
          {t('Restablece contraseña')}
        </Button>
      </Sc.Box>
    </Sc.Form>
  )
}

export default FormPasswordReset
