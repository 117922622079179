import { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import * as Sc from './License.style'
import eyes from 'assets/images/licencias/cover.jpg'
import book from 'assets/images/licencias/licencia1.png'
import teacher from 'assets/images/licencias/licencia3.png'
import Footer from 'components/footer'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'
import { grafanaUtils } from 'utils/Grafana'

const License = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.LICENSE)
  }, [])

  return (
    <>
      <Sc.Image>
        <img alt={t('¿Qué es una licencia digital?')} src={eyes} />
        <h1>{t('¿Qué es una licencia digital?')}</h1>
      </Sc.Image>
      <div>
        <Sc.Main>
          <p>
            <Trans i18nKey='La licencia digital es un código que te permite acceder al contenido digital del libro de texto Vicens Vives Tienes a tu disposición dos tipos de códigos de licencia'>
              <strong>La licencia digital</strong> es un código que te permite
              acceder al contenido digital del libro de texto Vicens Vives.
              Tienes a tu disposición dos tipos de códigos de licencia
            </Trans>
          </p>
          <Sc.Row>
            <h2>{t('Licencia Basic')}</h2>
            <Sc.BoxImage>
              <img alt={t('libro')} src={book} />
            </Sc.BoxImage>
            <Sc.License>
              <Sc.Question>
                <strong>
                  {t('¿Cómo es la licencia y dónde puedo conseguirla?')}
                </strong>
              </Sc.Question>
              <Sc.List>
                <li>{t('La licencia tiene 8 caracteres')}</li>
                <li>{t('Está impresa en la última página del libro')}</li>
                <li>
                  {t(
                    'Solo tienes que introducirla en nuestra página de inicio'
                  )}
                </li>
                <li>
                  {t(
                    'Tendrás que introducir esta licencia cada vez que quieras acceder a tu libro'
                  )}
                </li>
              </Sc.List>
            </Sc.License>
            <Sc.Offer>
              <Sc.Question>
                <strong>{t('¿Qué ofrece?')}</strong>
              </Sc.Question>
              <Sc.List>
                <li>{t('Actividades digitales')}</li>
                <li>
                  {t(
                    'Gran variedad de contenido interactivo: vídeos, animaciones, audios, enlaces…'
                  )}
                </li>
              </Sc.List>
            </Sc.Offer>
          </Sc.Row>
          <Sc.Row>
            <h2>{t('Licencia Premium')}</h2>
            <Sc.BoxImage>
              <img alt={t('profesora')} src={teacher} />
            </Sc.BoxImage>
            <Sc.License>
              <Sc.Question>
                <strong>
                  {t('¿Cómo es la licencia y dónde puedo conseguirla?')}
                </strong>
              </Sc.Question>
              <Sc.List>
                <li>{t('La licencia tiene 12 caracteres')}</li>
                <li>{t('Necesitas registrarte para acceder al libro')}</li>
                <li>
                  {t(
                    'La licencia está impresa en la última página de los libros Aula 3D y Aula Activa'
                  )}
                </li>
                <li>
                  <Trans i18nKey='Si no tienes licencia, te la puede facilitar tu centro educativo o la puedes adquirir en nuestra tienda online'>
                    Si no tienes licencia, te la puede facilitar tu centro
                    educativo o la puedes adquirir en nuestra
                    <a
                      href={`http://www.vicensvivesonline.com/${language}/libro-digital/4?_ga=2.20487484.548018809.1616663432-780997398.1615286388`}
                      rel='noreferrer'
                      target='_blank'
                    >
                      tienda online
                    </a>
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey='Si eres docente, puedes probar Edubook sin compromiso ¡Solicita una demo!'>
                    Si eres docente, puedes probar Edubook sin compromiso
                    <Link to={t('routes::/demo')}>¡Solicita una demo!</Link>
                  </Trans>
                </li>
              </Sc.List>
            </Sc.License>
            <Sc.Offer>
              <Sc.Question>
                <strong>{t('¿Qué ofrece?')}</strong>
              </Sc.Question>
              <Sc.List>
                <li>{t('Disponible sin conexión a Internet')}</li>
                <li>{t('Completo sistema de calificaciones')}</li>
                <li>{t('Acceso a la Academia')}</li>
                <li>{t('Actividades digitales')}</li>
                <li>{t('Actividades del libro')}</li>
                <li>{t('Compatible con los principales LMS')}</li>
              </Sc.List>
            </Sc.Offer>
          </Sc.Row>
        </Sc.Main>
      </div>
      <Footer showDemoSection={false} />
    </>
  )
}

export default License
