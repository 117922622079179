import PropTypes from 'prop-types'

import * as Sc from './IconDescription.style'

const IconDescription = ({ Icon, children }) => (
  <Sc.IconDescription>
    <Icon />
    <p>{children}</p>
  </Sc.IconDescription>
)

IconDescription.propTypes = {
  Icon: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
}

export default IconDescription
