export const Colors = `
  :root {
    --bannerYellow: #ffe64c;
    --bgGrey: #fafafa;
    --black: #000000;
    --blueHover: #13A4CA;
    --blueLink: #0F7995;
    --buttonGrey: #575756;
    --buttonSilver: #C6C6C6;
    --cream: #FDEFD1;
    --darkGrey: #1D1D1B;
    --darkRed: #A52016;
    --footerLng: #706F6F;
    --green: #009640;
    --grey: #444444;
    --grey2: #9c9b9a;
    --greyBg: #F0F0F0;
    --greyHover: #9C9C9A;
    --lightBlue: #88b7d500;
    --lightRed: #F9CDD0;
    --lightRedNotification: #fdebec;
    --orange: #F6B019;
    --orangeLight: #FCF0D3;
    --red: #E20613;
    --shadow: #9d9d9d;
    --silver: #F6F6F6;
    --silverLine: #9D9D9C;
    --softGrey: #e5e7eb;
    --transparentGrey: #444444e6;
    --transparentRed: #e20613b3;
    --white: #FFF;
    --whiteOverlay: #ffffffbd;
    --whiteHover: #F2F2F2;
    --yellow: #F9CD6D;
    --yellowHover: #F9D997;
    --green:#4caf50;
    --error: #f44336;
  }
`
