import styled, { css } from 'styled-components'

import { DEVICES } from 'utils/constants'

export const Form = styled.div`
  width: 390px;
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    width: 100%;
  }
  input {
    :focus {
      ${({ isError }) =>
        isError ? 'border-color: var(--red)' : 'border-color: var(--green)'}
    }
    ${({ isEmpty }) => isEmpty && 'border: none'}
    ${({ isError }) =>
      isError
        ? 'border: 2px solid var(--red)'
        : 'border: 2px solid var(--green)'}
  }
  a {
    color: var(--white);
    display: inline-block;
    font-family: var(--fontBold);
    font-size: 14px;
    padding-top: 10px;
    :hover {
      color: var(--whiteHover);
    }
    :active {
      color: var(--orange);
    }
  }
`

export const ButtonsBox = styled.div`
  display: flex;
  ${({ isBasic }) => !isBasic && 'justify-content:space-around;'};
  button {
    ${({ isBasic }) =>
      !isBasic &&
      css`
        :first-child {
          background-color: transparent;
          border: 1px solid var(--white);
          color: var(--white);
          :hover {
            color: var(--orange);
            border: 1px solid var(--orange);
          }
        }
      `};
    margin-top: 10px;
    width: ${({ isBasic }) => !isBasic && '190px'};
  }
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    flex-wrap: wrap;
    button {
      width: 100%;
    }
  }
`
