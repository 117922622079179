import { useEffect, useState, useContext } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import * as Sc from './FormRegister.style'
import Button from 'components/Button'
import InputEmail from 'components/inputEmail'
import InputText from 'components/inputText'
import { ErrorContext } from 'context/ErrorContext'
import { student, teacher } from 'services/register'
import { isDevelopment, getDomain } from 'utils'
import { DATA_LAYER_VALUES } from 'utils/constants/gtm'
import { sessionStorageGetItem, sessionStorageRemoveItem } from 'utils/storage'

const FormRegister = ({ licenseCode, isTeacher }) => {
  const { setError } = useContext(ErrorContext)
  const { push } = useHistory()
  const [data, setData] = useState({
    email: '',
    name: '',
    password: '',
    surnames: '',
  })
  const [checked, setChecked] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [isValidPassword, setIsValidPassword] = useState(null)
  const [isValidEmail, setIsValidEmail] = useState(null)

  const {
    t,
    i18n: { language },
  } = useTranslation()
  const domain = getDomain()
  const protocol = isDevelopment() ? 'http' : 'https'

  useEffect(() => {
    let passwordValid = null
    const values = Object.values(data)
    const isAllData = values.every((value) => value !== '')

    if (data.password.length >= 6) {
      passwordValid = true
      setIsValidPassword(passwordValid)
    } else if (data.password.length > 0 && data.password.length < 6) {
      passwordValid = false
      setIsValidPassword(passwordValid)
    } else {
      passwordValid = null
      setIsValidPassword(passwordValid)
    }

    if (isAllData && checked && passwordValid && isValidEmail) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [data, checked, isValidEmail])

  const handleChange = ({ target: { id, value, isCorrect } }) => {
    if (id === 'email') {
      setIsValidEmail(isCorrect)
    }
    setData({ ...data, [id]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const userRegisterData = {
      ...data,
      bookId: sessionStorageGetItem('regionalBookId') || '',
      language,
      licenseCode,
      terms: '1',
    }
    try {
      if (isTeacher) {
        await teacher(userRegisterData)
      } else {
        await student(userRegisterData)
      }

      sessionStorageRemoveItem('regionalBookId')
      window.dataLayer.push({
        event: DATA_LAYER_VALUES.SIGN_UP,
        event_type: DATA_LAYER_VALUES.STANDARD,
      })

      window.location = window.config.defUrl
    } catch (error) {
      if (error?.response?.data?.code === 'LicenseAlreadyActivatedException') {
        setError(error.response.data)
        push(t('routes::/'))
      }

      if (error?.response?.data?.code === 'IllegalTargetLicenseException') {
        setError(error.response.data)
        push(t('routes::/'))
      }
    }
  }

  return (
    <Sc.Form onSubmit={handleSubmit}>
      <Sc.Row>
        <InputText
          id='name'
          label={t('Nombre')}
          onChange={handleChange}
          type='text'
          value={data.name}
        />
      </Sc.Row>
      <Sc.Row>
        <InputText
          id='surnames'
          label={t('Apellidos')}
          onChange={handleChange}
          type='text'
          value={data.surnames}
        />
      </Sc.Row>
      <Sc.Row>
        <InputEmail
          checkHasEmail
          emailValue={({ emailValue, isCorrect }) =>
            handleChange({
              target: { id: 'email', isCorrect, value: emailValue },
            })
          }
        />
      </Sc.Row>
      <Sc.Row>
        <InputText
          id='password'
          isCorrect={isValidPassword}
          label={t('Contraseña')}
          onChange={handleChange}
          type='password'
          value={data.password}
        />
        <Sc.PasswordInfo isValidPassword={isValidPassword}>
          {t('La contraseña debe tener al menos 6 caracteres')}
        </Sc.PasswordInfo>
      </Sc.Row>
      <Sc.Terms>
        <input
          checked={checked}
          onChange={() => setChecked(!checked)}
          type='checkbox'
        />
        <a
          href={`${protocol}://legal.${domain}/${language}?_ga=2.177188385.548018809.1616663432-780997398.1615286388`}
          rel='noreferrer'
          target='_blank'
        >
          {t('He leído y acepto las condiciones legales')}
        </a>
      </Sc.Terms>
      <Button dataTestid='buttonRegister' disabled={disabled} type='submit'>
        {t('Regístrate')}
      </Button>
    </Sc.Form>
  )
}

FormRegister.propTypes = {
  isTeacher: PropTypes.bool.isRequired,
  licenseCode: PropTypes.string.isRequired,
}

export default FormRegister
