import { useEffect, useRef } from 'react'

const useOnClickOutside = (handleClose) => {
  const ref = useRef(null)
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event?.target)) {
        return
      }
      handleClose(false)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      listener(null)
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handleClose])
  return { ref }
}

export default useOnClickOutside
