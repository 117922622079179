import styled from 'styled-components/macro'

export const Form = styled.form`
  width: 100%;
  button {
    margin-bottom: 20px;
  }
`

export const Row = styled.div`
  padding-bottom: 20px;
`

export const Error = styled.p`
  color: var(--red);
  font-family: var(--fontSansRegular);
  font-size: 14px;
  padding-top: 8px;
`

export const PasswordInfo = styled.p`
  color: ${({ isValidPassword }) =>
    isValidPassword === false ? 'var(--red)' : 'var(--silverLine)'};
  font-family: var(--fontSansRegular);
  font-size: 14px;
  line-height: inherit;
  margin: 5px 0 0;
`

export const Terms = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: 8px;
  padding-top: 40px;
  > a {
    color: var(--silverLine);
    font-family: var(--fontSansRegular);
    font-size: 14px;
    padding-left: 5px;
    :hover {
      color: var(--buttonSilver);
    }
  }
`

export const ButtonRegister = styled.div`
  border: solid;
`
