export const IconInstragram = () => (
  <svg viewBox='0 0 50 50' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
      <rect fill='#FFF' height='50' rx='8' width='50' x='0' y='0' />
      <g fill='#444' fillRule='nonzero' transform='translate(5 5)'>
        <path d='M27.5 0h-15C5.597 0 0 5.597 0 12.5v15C0 34.402 5.597 40 12.5 40h15C34.402 40 40 34.402 40 27.5v-15C40 5.597 34.402 0 27.5 0zm8.75 27.5c0 4.825-3.925 8.75-8.75 8.75h-15c-4.825 0-8.75-3.925-8.75-8.75v-15c0-4.825 3.925-8.75 8.75-8.75h15c4.825 0 8.75 3.925 8.75 8.75v15z' />
        <path d='M20 10c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10 5.522 0 10-4.478 10-10 0-5.523-4.478-10-10-10zm0 16.25A6.259 6.259 0 0113.75 20 6.257 6.257 0 0120 13.75 6.257 6.257 0 0126.25 20 6.259 6.259 0 0120 26.25z' />
        <circle cx='30.75' cy='9.25' r='1.332' />
      </g>
    </g>
  </svg>
)
