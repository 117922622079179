import { getWebInstrumentations } from '@grafana/faro-react'
import { faro } from '@grafana/faro-web-sdk'

const { REACT_APP_API_KEY_GRAFANA_FARO } = process.env

const { enabledGrafanaFaro } = window.fflags || {}
const { grafanaFaroEnvironment, grafanaFaroUrl } = window.config || {}
export const grafanaUtils = {
  getInitialOptions() {
    const initialOptions = {
      apiKey: REACT_APP_API_KEY_GRAFANA_FARO,
      app: {
        environment: grafanaFaroEnvironment,
        name: 'edubook_public',
      },
      batching: {
        enabled: true,
      },
      instrumentations: [
        ...getWebInstrumentations({
          captureConsole: true,
        }),
      ],
      session: window.__PRELOADED_STATE__?.faro?.session,
      url: grafanaFaroUrl,
    }
    return initialOptions
  },
  pushEvent(events) {
    if (!enabledGrafanaFaro) {
      return
    }
    faro.api.pushEvent(events)
  },
  setView(view) {
    if (!enabledGrafanaFaro) {
      return
    }
    faro.api.setView({ name: view })
  },
}
