import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const Main = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  width: 790px;
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    width: 80%;
  }
`

export const Title = styled.h1`
  color: var(--darkRed);
  font-size: 30px;
  font-weight: 400;
  line-height: 34px;
  padding-bottom: 30px;
  text-align: center;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    font-size: 25px;
  }
`

export const SubTitle = styled.p`
  color: var(--grey);
  font-family: var(--fontBold);
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 50px;
  text-align: center;
`

export const Box = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  width: 100%;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    align-items: center;
    flex-direction: column;
  }
`

export const Book = styled.div`
  align-items: center;
  border: 2px solid var(--yellow);
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 200px;
  padding: 15px 20px 20px;
  p {
    :first-child {
      font-family: var(--fontSansBold);
    }
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 17px;
    margin: 0;
  }
  img {
    margin-top: 15px;
    max-height: 150px;
    vertical-align: top;
    width: 100%;
  }
`

export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 65px;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    padding: 30px 0;
    svg {
      transform: rotate(90deg);
    }
  }
  p {
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 14px;
    line-height: initial;
    margin: 0 0 7px;
    max-width: 90px;
    text-align: center;
  }
  svg {
    fill: var(--yellow);
    height: 40px;
    width: 40px;
  }
`

export const ContainerForm = styled.div`
  width: ${({ hasLicense }) => hasLicense && '350px'};
  form {
    width: ${({ hasLicense }) => hasLicense && '100%'};
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    width: 100%;
  }
`

export const License = styled.div`
  margin: 20px 0 30px;
  div > a {
    font-family: var(--fontBold);
    text-align: center;
  }
  > p {
    color: var(--grey);
    font-family: var(--fontBold);
    font-size: 14px;
    margin: 10px 0 0;
    text-align: center;
  }
  div {
    align-items: center;
    display: flex;
    justify-content: center;
    a {
      color: var(--blueLink);
      display: block;
      font-size: 14px;
      padding: 5px 5px 0;
      :hover {
        color: var(--blueHover);
      }
    }
  }
`

export const Google = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  p {
    color: var(--grey);
    font-family: var(--fontBold);
    font-size: 14px;
    text-align: center;
  }
  a {
    color: var(--footerLng);
    font-family: var(--fontRegular);
    font-size: 14px;
    line-height: 1.4;
    :hover {
      color: var(--buttonSilver);
    }
  }
`
