function Chevron() {
  return (
    <svg
      height='512'
      viewBox='0 0 512 512'
      width='512'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M499.2 128c-2.6 0-7.6 2.6-10.2 2.6L256 353.2 23 130.6c-2.6 0-7.6-2.6-10.2-2.6C5.2 128 0 133.2 0 140.8c0 2.6 2.6 7.6 2.6 10.2l243.2 230.4c2.6 0 7.6 2.6 10.2 2.6s7.6-2.6 10.2-2.6L509.4 151c0-2.6 2.6-7.6 2.6-10.2 0-7.6-5.2-12.8-12.8-12.8z' />
    </svg>
  )
}

export default Chevron
