const Icon = () => (
  <svg viewBox='0 0 92 45' xmlns='http://www.w3.org/2000/svg' >
    <rect fill='transparent' />
    <path
      d='M77.8173 39H0.000488281V37H77.8173V31L91.8173 38L77.8173 45V39V39Z'
      fill='#F8B114'
    />
    <path
      d='M14.0001 7L91.8169 7V9L14.0001 9V15L0.0001297 8L14.0001 1V7Z'
      fill='#F8B114'
    />
    <rect fill='transparent' x='30.8169' y='8' />
    <path
      clipRule='evenodd'
      d='M23.8169 22C23.8169 9.8499 33.6668 0 45.8169 0C57.9671 0 67.8169 9.8499 67.8169 22C67.8169 34.1501 57.967 44 45.8169 44C33.6668 44 23.8169 34.1501 23.8169 22ZM52.2788 33.9996L57.8169 28.4612L51.3554 21.9998L57.8169 15.538L52.2788 10L45.8169 16.4614L39.355 10L33.8169 15.538L40.2784 21.9998L33.8169 28.4612L39.355 34L45.8169 27.5382L52.2788 33.9996Z'
      fill='#F05228'
      fillRule='evenodd'
    />
  </svg>
)

export default Icon
