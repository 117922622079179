import styled from 'styled-components/macro'

export const SelectAutonomousRegionForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 9.2rem;
  & > div {
    max-width: 25rem;
    font-family: var(--fontRegular);
    width: 100%;

    button {
      font-family: var(--fontRegular);
    }
  }
`
export const SelectAutonomousRegionTitle = styled.h2`
  text-align: center;
`
export const SelectAutonomousRegionSubTitle = styled.h3`
  font-family: var(--fontRegular);
  margin: 2rem 0;
  max-width: 31rem;
  text-align: center;
  b {
    font-family: var(--fontBold);
  }
`
export const SelectAutonomousRegionButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 2rem;
  width: 100%;
`
