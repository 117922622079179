import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const List = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 850px;

  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Moodle = styled.article`
  display: flex;
  margin: 0 auto;
  max-width: 800px;
  padding: 60px 0;
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    padding: 60px 20px;
  }
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`

export const Description = styled.div`
  max-width: 500px;
  padding-right: 10px;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    max-width: 80%;
    margin-bottom: 20px;
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
    max-width: 90%;
  }
  h3 {
    color: var(--darkRed);
    font-family: var(--fontRegular);
    font-size: 30px;
    line-height: normal;
  }
  h4 {
    color: var(--grey);
    display: block;
    font-family: var(--fontRegular);
    font-size: 17px;
    font-weight: 700;
    line-height: normal;
    margin: 10px 0 30px;
    padding: 0;
    width: 100%;
  }
  p {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
  }
`
export const Links = styled.div`
  max-width: 285px;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    max-width: 80%;
    width: 80%;
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
    max-width: 90%;
    width: 90%;
  }
  a {
    border-radius: 3px;
    border: 1px solid var(--grey);
    box-sizing: border-box;
    display: inline-block;
    font-size: 14px;
    line-height: normal;
    margin-right: 5px;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    width: 100%;
    @media (max-width: ${DEVICES.SIZE_TABLET}) {
      height: 60px;
      line-height: 40px;
    }
    span {
      color: var(--grey2);
    }
    &:hover {
      border: 1px solid var(--greyHover);
      color: var(--greyHover);
      span {
        color: var(--greyHover);
      }
    }
    &:active {
      border: 1px solid var(--orange);
      color: var(--orange);
      span {
        color: var(--orange);
      }
    }
  }
  p {
    color: var(--silverLine);
    font-family: var(--fontRegular);
    font-size: 12px;
    margin-bottom: 50px;
    margin-top: 10px;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Line = styled.div`
  border-bottom: 1px solid var(--buttonSilver);
  height: 3px;
  width: 100%;
`
