import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import * as Sc from './googleErrorModal.style'
import { Edubook, ArrowError, Google } from 'assets/icons'
import Button from 'components/Button'

const GoogleErrorModal = ({ setError }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  let url = `https://vicensvivesdigital.zendesk.com/hc/${language}/articles/360018622257`
  if (language === 'en') {
    url = 'https://vicensvivesdigital.zendesk.com/hc/es/articles/360018622257'
  }

  return (
    <Sc.Modal>
      <Sc.Container>
        <Sc.Logo>
          <Edubook width='100%' />
          <ArrowError width='100%' />
          <Google width='100%' />
        </Sc.Logo>
        <Sc.Message data-testid='messageLinkingGoogleError' role={'paragraph'}>
          {t(
            'No puedes iniciar sesión con Google porque antes debes vincular esta cuenta con Edubook'
          )}
        </Sc.Message>
        <Button
          dataTestid='buttonBackToLogin'
          onClick={() => setError(false)}
          type='submit'
        >
          {t('Iniciar sesión con Edubook')}
        </Button>
        <Sc.Help href={url}>
          {t('¿Necesitas ayuda para vincular tu cuenta?')}
        </Sc.Help>
      </Sc.Container>
    </Sc.Modal>
  )
}

GoogleErrorModal.propTypes = {
  setError: PropTypes.func.isRequired,
}

export default GoogleErrorModal
