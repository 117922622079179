import { useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import TableFeature from 'vv-feature-table'

import * as Sc from './Home.style'
import { Chevron } from 'assets/icons'
import Footer from 'components/footer'
import FormLicense from 'components/formLicense'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const Home = () => {
  const ref = useRef(null)
  const {
    t,
    i18n: { language },
  } = useTranslation()

  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.HOME)
  }, [])

  const scrollToEdubook = (e) => {
    e.preventDefault()
    const element = ref.current
    element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <>
      <Sc.Section language={language}>
        <main>
          <Sc.TitleBox>
            <Sc.Title>{t('Edubook, activa el aprendizaje')}</Sc.Title>
            <Sc.SubTitle>
              {t(
                'El espacio digital de Vicens Vives donde aprender y enseñar con los mejores contenidos'
              )}
            </Sc.SubTitle>
            <FormLicense />
          </Sc.TitleBox>
          <Sc.LinkEdubook>
            <a href='#edubook' onClick={scrollToEdubook}>
              {t('¿Qué es Edubook?')}
              <Chevron />
            </a>
          </Sc.LinkEdubook>
        </main>
      </Sc.Section>
      <Sc.SectionFeatures ref={ref}>
        <main>
          <h2>
            {t(
              'Contenidos y recursos digitales en una plataforma ágil y eficiente'
            )}
          </h2>
          <TableFeature />
        </main>
        <Link to={t('routes::/license')}>
          {t('¿Cómo consigo una licencia?')}
        </Link>
      </Sc.SectionFeatures>
      <Footer showAppsSection />
    </>
  )
}

export default Home
