import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Image = styled.div`
  height: 430px;
  position: relative;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    ${({ hasChildren }) => hasChildren && 'height: 650px'};
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
    height: ${({ hasChildren }) => (hasChildren ? '750px' : '500px')};
  }
`
export const ImgContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  & img {
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
`
export const Text = styled.div`
  padding: 30px;
  position: absolute;
  text-align: center;
  text-shadow: 0.7px 0.7px 3px var(--grey);
  top: ${({ hasChildren }) => (hasChildren ? '0' : '60px')};
  width: 100%;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    padding: 30px 10%;
  }
`

export const Title = styled.h1`
  color: var(--white);
  font-size: 55px;
  line-height: 59px;
  margin: auto;
  max-width: 810px;
  padding-bottom: 30px;
  text-align: center;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    font-size: 48px;
    line-height: 52px;
  }

  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    font-size: 45px;
    line-height: 49px;
  }
`

export const SubTitle = styled.h3`
  color: var(--white);
  font-family: var(--fontSansRegular);
  font-size: 22px;
  line-height: 26px;
  margin: auto;
  max-width: 790px;
  padding-bottom: 60px;
  text-align: center;
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    font-size: 22px;
    line-height: 26px;
  }
`

export const Content = styled.div`
  color: var(--white);
`
