import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 400px;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    width: 100%;
  }
`

export const Row = styled.div`
  padding: 10px 0;
`

export const Error = styled.span`
  color: var(--red);
  display: inline-block;
  font-family: var(--fontSansRegular);
  font-size: 14px;
  line-height: initial;
  margin: 8px 0 0;
`

export const Info = styled.span`
  color: var(--Black);
  display: inline-block;
  font-family: var(--fontSansRegular);
  font-size: 14px;
  line-height: initial;
  margin: 8px 0 0;
`

export const Box = styled.div`
  margin-top: 40px;
`
