import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { EXTERNAL_ROUTES } from 'utils/constants/externalRoutes'

const LicenseAlreadyActivatedException = () => {
  const { t } = useTranslation()

  return (
    <Trans i18nKey='LicenseAlreadyActivatedException'>
      El código de licencia ya está registrado.
      <Link to={t('routes::/login')}>Inicia sesión</Link> o
      <a href={EXTERNAL_ROUTES.ZENDESK} rel='noreferrer' target='_blank'>
        contacta con nosotros
      </a>
    </Trans>
  )
}

const LicenseNotFoundException = () => (
  <Trans i18nKey='LicenseNotFoundException'>
    El código de licencia no es válido. Comprueba que lo has introducido
    correctamente o
    <a href={EXTERNAL_ROUTES.ZENDESK} rel='noreferrer' target='_blank'>
      consulta la ayuda
    </a>
    .
  </Trans>
)

const LicenseExpiredException = () => (
  <Trans i18nKey='LicenseExpiredException'>
    El código de licencia ha caducado. Comprueba que lo has introducido
    correctamente o
    <a href={EXTERNAL_ROUTES.ZENDESK} rel='noreferrer' target='_blank'>
      consulta la ayuda
    </a>
  </Trans>
)

export {
  LicenseAlreadyActivatedException,
  LicenseExpiredException,
  LicenseNotFoundException,
}
