import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const Label = styled.label`
  color: var(--grey);
  display: block;
  font-family: var(--fontSansSemiBold);
  font-size: 17px;
  margin-bottom: 6px;
`

export const Input = styled.input`
  background-color: var(--white);
  border: ${({ border }) =>
    border ? 'none' : '1px solid var(--buttonSilver)'};

  border-color: ${({ isCorrect }) => {
    switch (isCorrect) {
      case true:
        return 'var(--green)'
      case false:
        return 'var(--red)'
      default:
        return 'var(--buttonSilver)'
    }
  }};
  box-sizing: border-box;
  color: var(--black);
  font-size: 19px;
  height: 48px;
  outline: none;
  padding-left: 10px;
  padding-right: ${({ spinner }) => (spinner ? '40px' : '0')};
  width: 100%;
  ${({ isCorrect }) =>
    isCorrect === null &&
    css`
      :focus {
        border-color: var(--orange);
      }
    `}
`

export const BoxSpinner = styled.div`
  width: 30px;
  position: absolute;
  right: 5px;
  top: 32px;
`

export const ShowPassword = styled.label`
  align-items: center;
  color: var(--grey);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 36px;
  width: fit-content;
  > span {
    font-family: var(--fontSansRegular);
    padding-left: 3px;
  }
`
