import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import * as Sc from './AppsBanner.style'
import android from 'assets/images/plataformas/appandroid.png'
import desk from 'assets/images/plataformas/appdesktop.png'
import apple from 'assets/images/plataformas/appiOs.png'
import moodle from 'assets/images/plataformas/moodle.png'

const AppsBanner = () => {
  const { t } = useTranslation()

  return (
    <Sc.AppsBanner>
      <h2>{t('Descárgate las aplicaciones para las distintas plataformas')}</h2>
      <Sc.AppsContainer>
        <Link aria-label='Apple' to={t('routes::/apps')}>
          <img alt={t('Imagen para aplicacion de apple')} src={apple} />
        </Link>
        <Link aria-label='Android' to={t('routes::/apps')}>
          <img alt={t('Imagen para aplicacion de android')} src={android} />
        </Link>
        <Link aria-label='Desktop' to={t('routes::/apps/desktop')}>
          <img alt={t('Imagen para aplicacion de escritorio')} src={desk} />
        </Link>
        <Link aria-label='Moodle' to={t('routes::/apps/moodle')}>
          <img alt={t('Imagen para moodle')} src={moodle} />
        </Link>
      </Sc.AppsContainer>
    </Sc.AppsBanner>
  )
}

export default AppsBanner
