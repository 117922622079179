import { useState } from 'react'

import PropTypes from 'prop-types'

import * as Sc from './InputText.style'
import LoaderIcon from 'assets/icons/loader'

const InputText = ({
  autoComplete,
  autoFocus,
  id,
  isCorrect,
  label,
  maxLength,
  onChange,
  placeholder,
  spinner,
  type,
  value,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const isTypePassword = type === 'password'

  return (
    <Sc.Container>
      {label && <Sc.Label htmlFor={id}>{label}</Sc.Label>}
      <Sc.Input
        autoComplete={autoComplete ? 'on' : 'off'}
        autoFocus={autoFocus}
        border={id === 'license'}
        id={id}
        isCorrect={isCorrect}
        maxLength={maxLength}
        onChange={onChange}
        placeholder={placeholder}
        spinner={spinner}
        type={showPassword ? 'text' : type}
        value={value}
      />
      {spinner && (
        <Sc.BoxSpinner>
          <LoaderIcon />
        </Sc.BoxSpinner>
      )}
      {isTypePassword && (
        <Sc.ShowPassword>
          <input
            onClick={() => setShowPassword(!showPassword)}
            type='checkbox'
          />
          <span>Mostrar</span>
        </Sc.ShowPassword>
      )}
    </Sc.Container>
  )
}

InputText.defaultProps = {
  autoComplete: false,
  autoFocus: false,
  isCorrect: null,
  label: '',
  maxLength: '',
  placeholder: '',
  spinner: false,
  value: '',
}

InputText.propTypes = {
  autoComplete: PropTypes.bool,
  autoFocus: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  spinner: PropTypes.bool,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default InputText
