function Register() {
  return (
    <svg
      height='512'
      viewBox='0 0 512 512'
      width='512'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M511.999 256.031C511.999 114.96 397.04 0 255.968 0S.001 114.96.001 256.031c0 77.696 35.248 147.599 90.096 194.591a8.589 8.589 0 002.557 1.898l.051.022c43.735 36.978 100.764 59.457 163.045 59.457.313 0 .627 0 .94-.002h-.048c61.376 0 118.207-22.192 162.591-58.112a10.31 10.31 0 005.846-4.546l.026-.046C478.065 401.645 512 333.069 512 255.997zm-488.479 0C23.52 128 128 23.519 256.032 23.519c128 0 232.512 104.48 232.512 232.512 0 62.72-24.8 119.488-65.312 161.327-6.528-30.72-26.128-82.96-86.24-116.88 13.728-16.992 21.536-39.2 21.536-62.72-.072-56.242-45.646-101.814-101.88-101.888h-.007c-56.255.064-101.84 45.649-101.904 101.897v.006c0 23.52 8.48 45.04 21.536 62.704-60.64 33.92-80.24 86.159-86.112 116.88-41.177-41.255-66.64-98.207-66.64-161.106v-.233.012zm154.144-17.664c.127-43.231 35.138-78.241 78.356-78.368h.012c43.231.127 78.241 35.138 78.368 78.356v.012c-.127 43.23-35.138 78.241-78.356 78.368h-.012c-43.231-.127-78.241-35.138-78.368-78.356v-.012zm-66.64 199.2c1.328-18.928 11.728-82.976 82.304-118.88 17.01 13.417 38.754 21.52 62.391 21.52h.33-.017c23.52 0 45.712-8.48 63.36-22.192 69.92 35.92 80.976 98.624 82.976 118.88-39.84 32-90.767 51.584-145.599 51.584-55.584.064-105.887-18.88-145.76-50.912z' />
    </svg>
  )
}

export default Register
