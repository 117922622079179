import { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import * as Sc from './Classroom.style'
import Envelope from 'assets/icons/envelope'
import School from 'assets/icons/school'
import Student from 'assets/icons/student'
import Teacher from 'assets/icons/teacher'
import Admin from 'assets/images/classroom/admin.png'
import Aula from 'assets/images/classroom/aula.png'
import Book1 from 'assets/images/classroom/book1.png'
import Book2 from 'assets/images/classroom/book2.png'
import studentsImg from 'assets/images/classroom/students.jpg'
import Students from 'assets/images/classroom/students.webp'
import Tichin from 'assets/images/classroom/tichin.png'
import Footer from 'components/footer'
import Hero from 'components/hero'
import TextImage from 'components/TextImage'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const Classroom = () => {
  const { t } = useTranslation()
  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.CLASSROOM)
  }, [])

  return (
    <>
      <Hero
        alt={t('foto de fondo de estudiantes')}
        image={studentsImg}
        subTitle={t(
          'Accede a los mejores contenidos en un entorno digital adaptado al proceso de aprendizaje de cada alumno'
        )}
        title={t('Edubook se adapta a la realidad de cada aula')}
      />
      <Sc.Classroom>
        <Sc.Separator>
          <div>
            <Teacher />
            <p>{t('Docente')}</p>
          </div>
        </Sc.Separator>
        <Sc.Article>
          <Sc.Description>
            <Trans i18nKey='Calidad Vicens Vives'>
              <h2>Calidad Vicens Vives</h2>
              <p>
                Vicens Vives cuenta con más de 50 años de experiencia dedicada
                exclusivamente a la creación de contenidos para todas las etapas
                educativas.
              </p>
              <p>
                En Edubook encontrarás los contenidos del libro de texto
                enriquecidos con audios, vídeos, animaciones 3D… para poder
                aprovechar todo el potencial de las nuevas tecnologías.
              </p>
            </Trans>
          </Sc.Description>
          <Sc.Image>
            <img alt={t('Libro Abierto')} src={Book1} />
          </Sc.Image>
        </Sc.Article>
        <Sc.Article reverse>
          <Sc.Description>
            <Trans i18nKey='Aprendizaje personalizado para todos los entornos y necesidades'>
              <h2>
                Aprendizaje personalizado para todos los entornos y necesidades
              </h2>
              <p>
                Proyecta el contenido del libro en la PDI cuando estés en clase,
                propón las actividades que realizará el alumnado en casa a
                través de su tableta u ordenador y realiza un seguimiento
                personalizado de cada estudiante gracias al completo sistema de
                calificaciones.
              </p>
            </Trans>
          </Sc.Description>
          <Sc.Image>
            <img alt={t('Profesor en el aula')} src={Aula} />
          </Sc.Image>
        </Sc.Article>

        <Sc.Article>
          <Sc.Description>
            <Trans i18nKey='Con todo el apoyo didáctico que necesitas'>
              <h2>Con todo el apoyo didáctico que necesitas</h2>
              <p>
                Con Edubook no dispondrás solo del contenido curricular del
                libro de texto, sino que podrás consultar también guías
                didácticas, propuestas curriculares, estándares de aprendizaje,
                recursos para la atención a la diversidad o propuestas de
                evaluación para simplificar tu tarea docente y tener todo el
                apoyo que necesitas.
              </p>
            </Trans>
          </Sc.Description>
          <Sc.Image>
            <img alt={t('Libro cerrado')} src={Book2} />
          </Sc.Image>
        </Sc.Article>

        <TextImage alt='Aplicacion de Tichin' border heightAuto img={Tichin}>
          <Trans i18nKey='Complementa tus clases con miles de recursos gratuitos'>
            <h2>Complementa tus clases con miles de recursos gratuitos</h2>
            <p>
              Edubook está conectado con la red educativa Tiching que te
              permitirá acceder fácilmente a más de 700.000 recursos educativos
              gratuitos. Además, podrás conectar con docentes de todo el mundo y
              compartir las experiencias educativas más interesantes.
            </p>
            <a href='http://es.tiching.com/' rel='noreferrer' target='_blank'>
              Conoce Tiching
            </a>
          </Trans>
        </TextImage>
        <Sc.Separator alignRight>
          <div>
            <Student />
            <p>{t('Estudiante')}</p>
          </div>
        </Sc.Separator>
        <Sc.Article border>
          <Sc.Description>
            <Trans i18nKey='Un aprendizaje actual, innovador y de calidad'>
              <h2>Un aprendizaje actual, innovador y de calidad</h2>
              <p>
                Edubook ofrece un espacio de aprendizaje adaptado al entorno
                digital, que hará que el alumnado se sienta cómodo y motivado.
              </p>
              <p>
                Las animaciones, vídeos o mapas consiguen una mayor atención del
                alumnado, facilitando el aprendizaje.
              </p>
              <p>
                Las actividades autocorrectivas permiten más de un intento y
                muestran una calificación inmediata, facilitando al alumnado
                aprender a su propio ritmo.
              </p>
              <p>
                Al ser multidispositivo y estar disponible en modo offline,
                Edubook permite realizar las actividades desde cualquier lugar
                sin necesidad de conexión a internet.
              </p>
            </Trans>
          </Sc.Description>
          <Sc.Image>
            <img
              alt={t('Estudiantes en el cesped viendo edubook')}
              src={Students}
            />
          </Sc.Image>
        </Sc.Article>
        <Sc.Line />
        <Sc.Separator isCenter>
          <div>
            <School />
            <p>{t('Centro')}</p>
          </div>
        </Sc.Separator>
        <TextImage
          alt={t('Portada del administrador de centros de edubook')}
          heightAuto
          img={Admin}
        >
          <Trans i18nKey='Gestión del centro'>
            <h2>Gestión del centro</h2>
            <p>
              El centro educativo podrá solicitar las licencias digitales de los
              libros y asignarlas tanto a alumnos como a docentes.
            </p>
            <p>
              Gracias a la gestión de usuarios, es posible dar de alta y editar
              sus perfiles, gestionar las matriculaciones y crear clases, entre
              otras opciones.Además, se han desarrollado los protocolos
              necesarios para que la integración de Edubook en Moodle sea rápida
              y sencilla.
            </p>
            <div>
              <Envelope />
              <h3>Apoyo técnico</h3>
            </div>
            <p>
              Vicens Vives ofrece apoyo constante durante todo el proceso de
              digitalización a través de sus consultores TIC y su Servicio de
              Atención al Usuario. Te ayudamos en la gestión de licencias, altas
              de alumnos, creación de clases, integración con Moodle… así como
              en la aplicación pedagógica de Edubook en el aula.
            </p>
          </Trans>
        </TextImage>
      </Sc.Classroom>
      <Footer />
    </>
  )
}

export default Classroom
