import { createContext, useState } from 'react'

import PropTypes from 'prop-types'

export const RecoverPasswordContext = createContext()

export const RecoverPasswordProvider = ({ children }) => {
  const [email, setEmail] = useState()
  return (
    <RecoverPasswordContext.Provider value={{ email, setEmail }}>
      {children}
    </RecoverPasswordContext.Provider>
  )
}

RecoverPasswordProvider.defaultProps = {
  children: null,
}

RecoverPasswordProvider.propTypes = {
  children: PropTypes.node,
}

export default RecoverPasswordContext
