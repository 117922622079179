import { useState } from 'react'

import Button from '@frontend_vv/base-ui.button'
import Select from '@frontend_vv/base-ui.select'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'

import * as Sc from './SelectAutonomousRegionModal.style'
import Modal from 'components/modal'
import { sessionStorageSetItem } from 'utils/storage'

const SelectAutonomousRegionModal = ({
  regions,
  bookTitle,
  isOpen,
  onClose,
  onLogin,
}) => {
  const [regionSelected, setRegionSelected] = useState('')
  const { t } = useTranslation()

  const handleOnChangeRegions = (valueSelect) => {
    const { bookId } = valueSelect || {}
    if (bookId) {
      sessionStorageSetItem('regionalBookId', bookId)
      setRegionSelected(valueSelect)
    }
  }

  return (
    <Modal id='autonomous-region-modal' isOpen={isOpen} onClose={onClose}>
      <Sc.SelectAutonomousRegionForm onSubmit={onLogin}>
        <Sc.SelectAutonomousRegionTitle>
          {t('SelectAutonomousRegionModal_Title')}
        </Sc.SelectAutonomousRegionTitle>

        <Sc.SelectAutonomousRegionSubTitle
          dangerouslySetInnerHTML={{
            __html: t('SelectAutonomousRegionModal_SubTitle', {
              title: bookTitle,
            }),
          }}
        />

        <Select
          dataTestId='select-region'
          keySelected={regionSelected}
          label={t('SelectAutonomousRegionModal_Title')}
          onChange={handleOnChangeRegions}
          options={regions}
        />

        <Sc.SelectAutonomousRegionButtons>
          <Button
            dataTestId='button-close-region-modal'
            onClick={onClose}
            text={t('Cancel')}
            variant='secondary'
          />
          <Button
            dataTestId='button-add-book'
            disabled={!regionSelected}
            text={t('Añadir libro')}
            type='submit'
            variant='primary'
          />
        </Sc.SelectAutonomousRegionButtons>
      </Sc.SelectAutonomousRegionForm>
    </Modal>
  )
}

SelectAutonomousRegionModal.propTypes = {
  bookTitle: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
}

export default SelectAutonomousRegionModal
