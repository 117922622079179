import styled from 'styled-components/macro'

export const Error = styled.span`
  color: var(--red);
  display: inline-block;
  font-family: var(--fontSansRegular);
  font-size: 14px;
  line-height: initial;
  margin: 8px 0 0;
`
