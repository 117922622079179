export const IconFacebook = () => (
  <svg viewBox='0 0 50 50' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
      <rect fill='#FFF' height='50' rx='8' width='50' x='0' y='0' />
      <path
        d='M12.5 13.75v-5a2.5 2.5 0 012.5-2.5h2.5V0h-5A7.5 7.5 0 005 7.5v6.25H0V20h5v20h7.5V20h5l2.5-6.25h-7.5z'
        fill='#444'
        fillRule='nonzero'
        transform='translate(13 5)'
      />
    </g>
  </svg>
)
