import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import Icon from '@frontend_vv/web-ui-kit.icon'
import Button from '@frontend_vv/base-ui.button'
import * as Sc from './App.style'
import Header from 'components/header'
import Loader from 'components/loader'
import i18n from 'config/i18n'
import { ErrorProvider } from 'context/ErrorContext'
import { LoginWithLicenseProvider } from 'context/LoginWithLicenseContext'
import { RecoverPasswordProvider } from 'context/RecoverPasswordContext'
import ScrollToTop from 'hooks/useScrollToTop'
import indexRoutes from 'routes'
import { checkLogin } from 'services/login'
import Portal from 'components/portal'

const App = () => {
  const [loader, setLoader] = useState(true)
  const { pathname } = useLocation()
  const currentLanguage = pathname.split('/')[1]
  const {
    i18n: { language },
    t,
  } = useTranslation()

  if (language !== currentLanguage) {
    i18n.changeLanguage(currentLanguage)
    localStorage.setItem('publicLanguage', currentLanguage)
  }

  useEffect(() => {
    document.getElementById('ze-snippet').src = window?.rebranding
      ?.showRebrandingModal
      ? ''
      : 'https://static.zdassets.com/ekr/snippet.js?key=5e6080ed-61b4-4523-a09f-7de92f91cdbb'
    const callCheckLogin = async () => {
      try {
        const { loggedIn } = await checkLogin()
        setLoader(false)
        if (loggedIn) {
          const recoveryPasswordUrl = `${t('routes::/recoverPassword')}`
          if (pathname !== recoveryPasswordUrl)
            window.location.href = window.config.defUrl
        }
      } catch {
        setLoader(false)
      }
    }
    callCheckLogin()
  }, [])

  return (
    <>
      <ScrollToTop />
      {loader && <Loader />}
      <RecoverPasswordProvider>
        <LoginWithLicenseProvider>
          <ErrorProvider>
            <Header />
            <Switch>
              {indexRoutes.map(({ path, component }) => (
                <Route component={component} exact key={path} path={path} />
              ))}
              <Redirect from='*' to={t('routes::/')} />
            </Switch>
          </ErrorProvider>
        </LoginWithLicenseProvider>
      </RecoverPasswordProvider>
      {window?.rebranding?.showRebrandingModal && (
        <Portal id='rebranding-modal'>
          <Sc.Overlay>
            <Sc.Container>
              <Icon height={65} icon={'edubookLandscapeGradient'} width={205} />
              <Sc.TitleModal>
                {t('¡Hemos renovado nuestra imagen!')}
              </Sc.TitleModal>
              <Sc.BodyModal>
                {t(
                  'Inicia sesión con tu usuario y accede a tus contenidos como siempre.'
                )}
              </Sc.BodyModal>
              <Button
                onClick={() =>
                  (window.location.href = `${window?.rebranding?.newEdubookUrl}/${language}`)
                }
                text={t('IR A LA NUEVA WEB')}
              />
            </Sc.Container>
          </Sc.Overlay>
        </Portal>
      )}
    </>
  )
}

export default App
