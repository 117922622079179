const Icon = () => (
  <svg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M113.47 309.408l-17.822 66.532-65.139 1.378c-19.467-36.107-30.509-77.418-30.509-121.318 0-42.451 10.324-82.483 28.624-117.732h0.014l57.992 10.632 25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456 0.002 18.792 3.406 36.797 9.651 53.408z'
      fill='#fbbb00'
    />
    <path
      d='M507.527 208.176c2.94 15.486 4.473 31.479 4.473 47.824 0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-0.014-0.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89v-101.275h245.899z'
      fill='#518ef8'
    />
    <path
      d='M416.253 455.624l0.014 0.014c-43.871 35.263-99.601 56.362-160.267 56.362-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z'
      fill='#28b446'
    />
    <path
      d='M419.404 58.936l-82.933 67.896c-23.335-14.586-50.919-23.012-80.471-23.012-66.729 0-123.429 42.957-143.965 102.724l-83.411-68.276c42.606-82.145 128.436-138.268 227.376-138.268 62.115 0 119.068 22.126 163.404 58.936z'
      fill='#f14336'
    />
  </svg>
)

export default Icon
