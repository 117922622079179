import PropTypes from 'prop-types'

import * as Sc from './Button.style'

const Button = ({ children, dataTestid, disabled, onClick, type }) => (
  <Sc.Button
    data-testid={dataTestid}
    disabled={disabled}
    onClick={onClick}
    type={type}
  >
    {children}
  </Sc.Button>
)

Button.defaultProps = {
  disabled: false,
  onClick: () => {},
}
Button.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestid: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired,
}

export default Button
