const Icon = () => (
  <svg
    preserveAspectRatio='xMidYMid'
    viewBox='0 0 100 100'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='50'
      cy='50'
      fill='none'
      r='35'
      stroke='#a09e9e'
      strokeDasharray='164.93361431346415 56.97787143782138'
      strokeWidth='10'
    >
      <animateTransform
        attributeName='transform'
        dur='0.5555555555555556s'
        keyTimes='0;1'
        repeatCount='indefinite'
        type='rotate'
        values='0 50 50;360 50 50'
      />
    </circle>
  </svg>
)

export default Icon
