import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import * as Sc from './PasswordRecovery.style'
import FormPasswordRecovery from 'components/formPasswordRecovery'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const PasswordRecovery = () => {
  const { t } = useTranslation()

  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.PASSWORD_RECOVERY)
  }, [])
  return (
    <Sc.Container>
      <Sc.Main>
        <h2>{t('Restablece tu contraseña')}</h2>
        <h3>
          {t(
            'Introduce el correo electrónico con el que te has registrado En caso de no tener correo electrónico, habla con tu tutor'
          )}
        </h3>
        <FormPasswordRecovery />
      </Sc.Main>
    </Sc.Container>
  )
}

export default PasswordRecovery
