import axios from 'axios'

export const checkEmail = async ({ email }) => {
  const { data } = await axios.get('/bff-vle/users/checkEmail', {
    params: { email },
  })
  return data
}

export const recoverPassword = async ({ email }) => {
  const { data } = await axios.post('/bff-vle/users/recoverPasswordRequest', {
    email,
  })
  return data
}

export const changePassword = async ({
  newPassword1,
  newPassword2,
  recoverPasswordRequestId,
  userID,
}) => {
  await axios.post(`/users/${userID}/changePassword`, {
    newPassword1,
    newPassword2,
    recoverPasswordRequestId,
  })
  const { data: userData } = await axios.get(`/users/${userID}`)

  const { data } = await axios.post('/login', {
    password: newPassword1,
    user: userData.userName || userData.email,
  })

  return data
}

export const checkPassword = async ({ password }) => {
  const { data } = await axios.post('/users/checkPasswordSecurity', {
    password,
  })
  return data
}
