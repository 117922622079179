import axios from 'axios'

export const login = async (newData) => {
  const { data } = await axios.post('/login', newData)
  return data
}

export const checkLogin = async () => {
  const { data } = await axios.get('/checkLogin')
  return data
}

export const loginWithGoogle = async (credentials) => {
  const { data } = await axios.post('/vle/login', {
    vleCredentials: credentials,
    vleType: 'GOOGLE_CLASSROOM',
  })
  return data
}

export const loginBasic = async ({ licenseCode }) => {
  const { data } = await axios.post('/loginBasic', { licenseCode })
  return data
}
