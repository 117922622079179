import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Classroom = styled.section`
  overflow: hidden;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    padding: 0 60px;
  }
  svg {
    fill: var(--grey);
  }
`

export const Separator = styled.div`
  margin: 0 auto;
  max-width: 810px;
  padding-top: 60px;
  position: relative;
  p {
    font-family: var(--fontBold);
  }
  div {
    ${({ alignRight }) => (alignRight ? 'margin-left: auto;' : '')}
    max-width: 150px;
    text-align: center;
  }

  &:before {
    ${({ alignRight }) => (alignRight ? ' left: 100%;' : 'right: 100%;')}
    background-color: var(--yellow);
    content: '';
    height: 2px;
    position: absolute;
    top: 70%;
    width: 300%;
  }

  &:after {
    ${({ alignRight }) => (alignRight ? 'right: 0;' : 'left: 0;')}
    background-color: var(--yellow);
    border-radius: 50%;
    content: '';
    height: 16px;
    margin-top: -7px;
    position: absolute;
    top: 70%;
    width: 16px;
  }
`

export const Article = styled.article`
  display: flex;
  margin: 0 auto;
  max-width: 810px;
  padding-top: 60px;
  ${({ reverse }) => (reverse ? 'flex-direction: row-reverse;' : '')}
  @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
    flex-direction: column;
  }
`

export const Line = styled.div`
  border-bottom: 1px solid var(--buttonSilver);
  height: 3px;
  width: 100%;
`

export const Description = styled.div`
  max-width: 410px;
  padding-bottom: 60px;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    max-width: 240px;
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
    max-width: 100%;
  }
  & h2 {
    color: var(--darkRed);
    display: block;
    font-size: 30px;
    @media (max-width: ${DEVICES.SIZE_TABLET}) {
      font-size: 25px;
    }
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 30px;
    width: 100%;
  }
  & p {
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 17px;
    line-height: normal;
    margin-bottom: 8px;
    @media (max-width: ${DEVICES.SIZE_TABLET}) {
      font-size: 15px;
    }
  }
  & div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px 0 15px;
    min-height: 35px;
    position: relative;
    width: 100%;

    & h3 {
      color: var(--grey);
      display: block;
      font-family: var(--fontRegular);
      font-size: 19px;
      font-weight: 700;
      line-height: 24px;
      margin: 0;
      padding-left: 20px;
      width: 100%;
    }
    & svg {
      width: 50px;
    }
  }

  & a {
    border-radius: 3px;
    border: 1px solid var(--grey);
    box-sizing: border-box;
    display: inline-block;
    font-size: 14px;
    height: 45px;
    line-height: 30px;
    margin-top: 10px;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    &:hover {
      border: 1px solid var(--greyHover);
      color: var(--greyHover);
    }
    &:active {
      border: 1px solid var(--orange);
      color: var(--orange);
    }
  }
`
export const Image = styled.div`
  grid-area: image;
  margin-right: auto;
  padding: 0 20px;

  & img {
    display: block;
    max-width: 900px;
    width: 100%;
  }
`
