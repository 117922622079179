import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import * as Sc from './Demo.style'
import Footer from 'components/footer'
import { EXTERNAL_ROUTES } from 'utils/constants/externalRoutes'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const CURRENT_ORIGIN = 'https://leads.vicensvives.com'

const Demo = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.DEMO)
    const handleMessageIframe = (event) => {
      if (
        event.origin.includes(CURRENT_ORIGIN) &&
        event.data === 'request sent'
      ) {
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: 0,
        })
      }
    }
    window.addEventListener('message', handleMessageIframe)
    return () => window.removeEventListener('message', handleMessageIframe)
  }, [])

  return (
    <>
      <Sc.Container>
        <Sc.Main>
          <Sc.Description>
            <h2>{t('¿Quieres probar Edubook?')}</h2>
            <p role={'paragraph'}>
              {t(
                'Si eres docente, solicita una demo sin compromiso para descubrir todo lo que te ofrece Edubook'
              )}
            </p>
            <p role={'paragraph'}>
              {t(
                'Completa este formulario y un delegado pedagógico de Vicens Vives se pondrá en contacto contigo'
              )}
            </p>
          </Sc.Description>
          <Sc.Form
            frameBorder='0'
            id='iframe_info'
            role='presentation'
            src={EXTERNAL_ROUTES.DEMO_FORM(language)}
          />
        </Sc.Main>
      </Sc.Container>
      <Footer showDemoSection={false} />
    </>
  )
}

export default Demo
