import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const IconDescription = styled.div`
  color: var(--white);
  max-width: 140px;
  text-shadow: 0.7px 0.7px 3px var(--grey);
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    margin: 15px;
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    margin: 5px;
    max-width: 100px;
  }
  svg {
    fill: var(--white);
    margin-bottom: 10px;
    width: 50px;
    @media (max-width: ${DEVICES.SIZE_MOBILE}) {
      width: 40px;
    }
  }
  p {
    color: var(--white);
    line-height: normal;
    text-align: center;
  }
`
