import { Trans, useTranslation } from 'react-i18next'

import * as Sc from './Tablets.style'
import appAndroidCA from 'assets/images/aplications/ca/appandroid.png'
import appIosCA from 'assets/images/aplications/ca/appiOs.png'
import appAndroidEN from 'assets/images/aplications/en/appandroid.png'
import appIosEN from 'assets/images/aplications/en/appiOs.png'
import appAndroidES from 'assets/images/aplications/es/appandroid.png'
import appIosES from 'assets/images/aplications/es/appiOs.png'
import TabletApp from 'assets/images/aplications/tablet_app.png'
import i18n from 'config/i18n'

const Tablets = () => {
  const { t } = useTranslation()
  const img = {
    android: {
      ca: appAndroidCA,
      en: appAndroidEN,
      es: appAndroidES,
    },
    ios: {
      ca: appIosCA,
      en: appIosEN,
      es: appIosES,
    },
  }
  return (
    <Sc.Tablets>
      <Sc.Container>
        <img
          alt={t('Aplicacion de edubook en table, android y ios')}
          src={TabletApp}
        />
        <Sc.Description>
          <Trans i18nKey='Descarga la nueva aplicación para estudiantes'>
            <h2>Tabletas</h2>
            <p>¡Descarga la nueva aplicación para estudiantes!</p>
            <Sc.Stores>
              <Sc.App>
                <a
                  href='https://itunes.apple.com/us/app/edubook/id1101111151?l=es&amp;ls=1&amp;mt=8'
                  rel='noreferrer'
                  target='_blank'
                >
                  <img
                    alt={t('app ios de edubook')}
                    src={img.ios[i18n.language]}
                  />
                  <p>iOS 10.0 o posterior.</p>
                </a>
              </Sc.App>
              <Sc.App>
                <a
                  href='https://play.google.com/store/apps/details?id=com.vicensvives.edubook&hl=es'
                  rel='noreferrer'
                  target='_blank'
                >
                  <img
                    alt={t('app android de edubook')}
                    src={img.android[i18n.language]}
                  />
                  <p>
                    A partir de Android 5.0 y Chromebooks táctiles compatibles
                  </p>
                </a>
              </Sc.App>
            </Sc.Stores>
          </Trans>
        </Sc.Description>
      </Sc.Container>
    </Sc.Tablets>
  )
}

export default Tablets
