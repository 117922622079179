import { useCallback, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import * as Sc from './FormLicense.style'
import InfoMessage from './LicenseMessage'
import Button from 'components/Button'
import InputText from 'components/inputText'
import SelectAutonomousRegionModal from 'components/selectAutonomousRegionModal'
import useDebounce from 'hooks/useDebounce'
import { useLoginBasic } from 'hooks/useLogin'
import useLoginWithLicenseContext from 'hooks/useLoginWithLicenseContext'
import { licenses } from 'services/license'
import { getUrlParams } from 'utils'
import {
  AGE_RANGES,
  EXCEPTIONS_LICENSE,
  PUBLICATION_STATUS,
  PUBLICATION_TYPES,
  TYPE_LICENSE,
  USER_ROLES,
} from 'utils/constants'
import { sessionStorageGetItem, sessionStorageRemoveItem } from 'utils/storage'

const FormLicense = () => {
  const [
    { data: dataLoginBasic, isSuccess: isSuccessLoginBasic },
    postLoginBasic,
  ] = useLoginBasic()
  const licenseToPremium = getUrlParams('license')
  const [license, setLicense] = useState(licenseToPremium || '')
  const [infoLicense, setInfoLicense] = useState({
    correct: false,
    error: false,
    message: '',
  })
  const [infoBookAggregationPublication, setInfoBookAggregationPublication] =
    useState({
      autonomies: [],
      title: '',
    })
  const [isUnder, setIsUnder] = useState(false)
  const [isBasic, setIsBasic] = useState(false)
  const handleAddBookByRegionsAndPremiumLicense = useRef(null)
  const [isAutonomousRegionModalOpen, setIsAutonomousRegionModalOpen] =
    useState(false)
  const { setLicenseLogin } = useLoginWithLicenseContext()
  const { t } = useTranslation()
  const { push } = useHistory()
  const delay = 1000
  const debouncedLicense = useDebounce(license, delay)

  const handleBookAggregationPublicationData = useCallback(
    ({ books, title }) => {
      const getRegions = () => {
        const regions = []
        books.forEach(({ autonomies, id: bookId }) => {
          autonomies.forEach(({ id, name }) => {
            regions.push({
              bookId,
              key: id,
              value: name,
            })
          })
        })
        return regions
      }
      const autonomies = getRegions()
      if (!autonomies.length) {
        throw new Error(EXCEPTIONS_LICENSE.NOT_FOUND)
      }
      setInfoBookAggregationPublication({
        autonomies,
        title,
      })
    },
    []
  )

  useEffect(() => {
    const checkLicense = async () => {
      if (debouncedLicense) {
        try {
          const response = await licenses({
            license,
          })
          const {
            ageRange,
            license: licenseResponse,
            redeemInfo: { code },
          } = response

          const bookAggregationPublicationData =
            licenseResponse.product.publication?.publicationStatus ===
              PUBLICATION_STATUS.INACTIVE &&
            licenseResponse.product.publication?.inheritor
              ? licenseResponse.product.publication?.inheritor
              : licenseResponse.product.publication

          licenseResponse.product.publication?.type ===
          PUBLICATION_TYPES.BOOK_AGGREGATION
            ? handleBookAggregationPublicationData(
                bookAggregationPublicationData
              )
            : setInfoBookAggregationPublication(null)

          const under =
            ageRange === AGE_RANGES.UNDER_14 &&
            licenseResponse.product.target !== USER_ROLES.TEACHER
          const isNewBasic =
            licenseResponse.product.accessType === TYPE_LICENSE.BASIC
          setIsUnder(under)
          setIsBasic(isNewBasic)
          setLicenseLogin(!isNewBasic && !code ? response : null)
          setInfoLicense({
            correct: !code,
            error: !!code,
            message: code || t('¡Licencia válida!'),
          })
        } catch (error) {
          const { message: clientMessage } = error || {}
          const { code: serverCode, message: serverMessage } =
            error.response?.data || {}
          // WIP: Change to new error code
          const isErrorLength = serverMessage?.includes('length')
          const code = serverCode || clientMessage
          setInfoLicense({
            correct: !code,
            error: !!code,
            message: isErrorLength
              ? EXCEPTIONS_LICENSE.LICENSE_INCORRECT_LENGTH
              : code,
          })
          setInfoBookAggregationPublication(null)

          setLicenseLogin(null)
        }
      } else {
        setInfoLicense({
          correct: false,
          error: false,
          message: '',
        })
        setIsUnder(false)
        setInfoBookAggregationPublication(null)
        setLicenseLogin(null)
      }
    }
    checkLicense()
  }, [debouncedLicense])

  useEffect(() => {
    if (isSuccessLoginBasic && dataLoginBasic) {
      const { id, link } = dataLoginBasic
      sessionStorage.setItem('userBookId', id)

      sessionStorageRemoveItem('regionalBookId')

      window.location.href = link
    }
  }, [dataLoginBasic, isSuccessLoginBasic])

  const handleAutonomousRegionModalOpen = () => {
    sessionStorageRemoveItem('regionalBookId')
    setIsAutonomousRegionModalOpen(!isAutonomousRegionModalOpen)
  }

  const goToRegisterPage = () => {
    handleAddBookByRegionsAndPremiumLicense.current = null
    if (isUnder) {
      return push(t('routes::/register/family'))
    }

    return push(t('routes::/register'))
  }

  const goToLogin = () => {
    handleAddBookByRegionsAndPremiumLicense.current = null
    return push(t('routes::/login'))
  }

  const handleGoTo = (goToFunction) => {
    !isBasic && (handleAddBookByRegionsAndPremiumLicense.current = goToFunction)
    if (infoBookAggregationPublication) {
      return handleAutonomousRegionModalOpen()
    }
    goToFunction()
  }

  const handleLogin = (event) => {
    event && event.preventDefault()
    if (isBasic) {
      postLoginBasic({
        body: isAutonomousRegionModalOpen
          ? {
              childBookId: sessionStorageGetItem('regionalBookId'),
              licenseCode: license,
            }
          : { licenseCode: license },
      })
    }
    if (!isBasic) {
      handleAddBookByRegionsAndPremiumLicense.current()
    }
  }

  const renderButton = ({ dataTestId, onClick, title }) => (
    <Button dataTestid={dataTestId} onClick={onClick} type='button'>
      {title}
    </Button>
  )

  return (
    <>
      <Sc.Form
        isEmpty={infoLicense.error === infoLicense.correct}
        isError={infoLicense.error}
      >
        <InputText
          id='license'
          maxLength='12'
          onChange={({ target: { value } }) =>
            !window?.rebranding?.showRebrandingModal && setLicense(value)
          }
          placeholder={t('Introduce tu código de licencia')}
          type='text'
          value={license}
        />
        <InfoMessage infoLicense={infoLicense} isUnder={isUnder} />
        {infoLicense.correct && (
          <Sc.ButtonsBox isBasic={isBasic}>
            {isBasic ? (
              renderButton({
                dataTestId: 'access-to-book',
                onClick: () => handleGoTo(handleLogin),
                title: t('Acceder al libro'),
              })
            ) : (
              <>
                {renderButton({
                  dataTestId: 'buttonLogin',
                  onClick: () => handleGoTo(goToLogin),
                  title: t('Inicia sesión'),
                })}
                {renderButton({
                  dataTestId: 'buttonRegister',
                  onClick: () => handleGoTo(goToRegisterPage),
                  title: isUnder
                    ? t('Regístrate como familiar')
                    : t('Regístrate'),
                })}
              </>
            )}
          </Sc.ButtonsBox>
        )}
      </Sc.Form>
      {isAutonomousRegionModalOpen && (
        <SelectAutonomousRegionModal
          bookTitle={infoBookAggregationPublication?.title || ''}
          isOpen={isAutonomousRegionModalOpen}
          onClose={handleAutonomousRegionModalOpen}
          onLogin={handleLogin}
          regions={infoBookAggregationPublication?.autonomies || []}
        />
      )}
    </>
  )
}

export default FormLicense
