import { useState, useCallback } from 'react'

import { sendRequest } from 'utils/api'

const useFetch = ({ method, url }) => {
  const [fetchState, setFetchState] = useState({
    data: null,
    error: null,
    isFailed: false,
    isLoading: false,
    isSuccess: false,
  })

  const fetchData = useCallback(
    async ({ body, params }) => {
      try {
        setFetchState({
          data: null,
          error: null,
          isFailed: false,
          isLoading: true,
          isSuccess: false,
        })
        const response = await sendRequest({ body, method, params, url })
        setFetchState((prevState) => ({
          ...prevState,
          data: response?.data,
          isLoading: false,
          isSuccess: true,
        }))
      } catch (error) {
        setFetchState((prevState) => ({
          ...prevState,
          error,
          isFailed: true,
          isLoading: false,
        }))
      }
    },
    [method, url]
  )

  return [fetchState, fetchData]
}

export default useFetch
