import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import * as Sc from './DemoBanner.style'
import { ArrowRight } from 'assets/icons'
import banner from 'assets/images/banner/banner.webp'

const DemoBanner = () => {
  const { t } = useTranslation()

  return (
    <Sc.BannerContainer>
      <Sc.BannerBg>
        <img alt={t('Foto de demo para docentes')} src={banner} />
      </Sc.BannerBg>
      <h2>{t('¡Solicita una demo para tu escuela!')}</h2>
      <p role={'paragraph'}>
        {t('Descubre todas las ventajas de integrar Edubook en tu aula.')}
      </p>
      <Sc.BannerData>
        <Sc.ButtonLink disabled={false}>
          <Link data-testid='requestDemoButton' to={t('routes::/demo')}>
            {t('Pide tu Demo')}
            <ArrowRight />
          </Link>
        </Sc.ButtonLink>
      </Sc.BannerData>
    </Sc.BannerContainer>
  )
}

export default DemoBanner
