import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import * as Sc from './RegisterFamily.style'
import { ArrowRight } from 'assets/icons'
import Button from 'components/Button'
import InputEmail from 'components/inputEmail'
import InputText from 'components/inputText'
import useLoginWithLicenseContext from 'hooks/useLoginWithLicenseContext'
import { checkPassword } from 'services/email'
import { relatives } from 'services/register'
import { isDevelopment, getDomain } from 'utils'
import { DATA_LAYER_VALUES } from 'utils/constants/gtm'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const RegisterFamily = () => {
  const [data, setData] = useState({
    dni: '',
    email: '',
    name: '',
    password: '',
    surnames: '',
  })

  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { licenseLogin } = useLoginWithLicenseContext()
  const { push } = useHistory()
  const [checked, setChecked] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [isValidEmail, setIsValidEmail] = useState(null)
  const [isValidDni, setIsValidDni] = useState(null)
  const [isSafePassword, setIsSafePassword] = useState(null)
  const [isValidPassword, setIsValidPassword] = useState(null)
  const [error, setError] = useState({
    message: t('Error'),
    state: false,
  })
  const domain = getDomain()
  const protocol = isDevelopment() ? 'http' : 'https'

  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.REGISTER_FAMILY)
    if (!licenseLogin) {
      return push(t('routes::/'))
    }
  }, [])

  useEffect(() => {
    let passwordValid = null
    const values = Object.values(data)
    const isAllData = values.every((value) => value !== '')

    setError({
      state: false,
    })

    const callCheckPassword = async () => {
      const { secure } = await checkPassword({ password: data.password })
      passwordValid = secure
      setIsSafePassword(passwordValid)
    }

    if (data.password.length >= 6) {
      passwordValid = true
      if (passwordValid) {
        callCheckPassword()
      }
    } else if (data.password.length > 0 && data.password.length < 6) {
      passwordValid = false
      setIsSafePassword(null)
    } else {
      passwordValid = null
    }
    setIsValidPassword(passwordValid)

    const isAllValid = isAllData && checked && isValidEmail && passwordValid
    setDisabled(!isAllValid)
  }, [data, checked])

  const handleChange = ({ target: { id, value, isEmailValid } }) => {
    if (id === 'email') {
      setIsValidEmail(isEmailValid)
    }
    if (id === 'dni' && value.trim() !== '') {
      setIsValidDni(true)
    } else {
      setIsValidDni(null)
    }
    setData({ ...data, [id]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const dataRelatives = { ...data, language, terms: checked ? 1 : 0 }
    try {
      const response = await relatives(dataRelatives)
      if (response.status === 200) {
        window.dataLayer.push({
          event: DATA_LAYER_VALUES.SIGN_UP,
          event_type: DATA_LAYER_VALUES.STANDARD,
        })
        window.location = window.config.defUrl
      }
    } catch (err) {
      const { data: dataError } = err.response
      const [codeError] = dataError.developerMessage.match(/\d+/g)

      if (codeError === '400') {
        setIsValidDni(false)
      } else {
        setIsValidDni(true)
      }
      if (codeError === '409') setIsValidEmail(false)

      setDisabled(true)
      setError({
        message: t(
          'Ha ocurrido un error, por favor inténtelo de nuevo más tarde'
        ),
        state: true,
      })
    }
  }

  return (
    <Sc.RegisterFamilyMain>
      <h2>
        {t('Regístrate en Edubook para poder crear la cuenta del estudiante')}
      </h2>
      <h3>
        {t(
          'Sigue los pasos para crear la cuenta del estudiante menor de 14 años'
        )}
      </h3>
      <Sc.StepsRegister>
        <Sc.FirstStep>
          <p>{t('Primero')}</p>
          <Sc.StepInfo>{t('Regístrate como familiar.')}</Sc.StepInfo>
        </Sc.FirstStep>
        <Sc.SecondStep>
          <p>{t('Después')}</p>
          <Sc.StepInfo>{t('Crea la cuenta del estudiante.')}</Sc.StepInfo>
        </Sc.SecondStep>
      </Sc.StepsRegister>
      <Sc.FormRegisterFamily disabled={disabled} onSubmit={handleSubmit}>
        <Sc.Row>
          <InputText
            autoFocus
            id='name'
            label={t('Nombre del familiar')}
            onChange={handleChange}
            type='text'
            value={data.name}
          />
        </Sc.Row>
        <Sc.Row>
          <InputText
            id='surnames'
            label={t('Apellidos del familiar')}
            onChange={handleChange}
            type='text'
            value={data.surnames}
          />
        </Sc.Row>
        <Sc.Row>
          <InputText
            id='dni'
            isCorrect={isValidDni}
            label={t('DNI/NIE')}
            onChange={handleChange}
            type='text'
            value={data.dni}
          />
          <Sc.InputInfo>
            {t(
              'Como representante legal de un estudiante, necesitamos que nos facilites tu documento de identidad. Ejemplo: DNI:12345678P, NIE:X1234567P'
            )}
          </Sc.InputInfo>
          {isValidDni === false && (
            <Sc.Error>{t('El DNI no es correcto')}</Sc.Error>
          )}
        </Sc.Row>
        <Sc.Row>
          <InputEmail
            autoFocus={false}
            checkHasEmail
            emailValue={({ emailValue, isValid: isEmailValid }) =>
              handleChange({
                target: { id: 'email', isEmailValid, value: emailValue },
              })
            }
          />
        </Sc.Row>
        <Sc.Row>
          <InputText
            id='password'
            isCorrect={isValidPassword}
            label={t('Contraseña')}
            onChange={handleChange}
            type='password'
            value={data.password}
          />
          {isSafePassword ||
            (isSafePassword === null && (
              <Sc.InputInfo isValidPassword={isValidPassword}>
                {t('La contraseña debe tener al menos 6 caracteres')}
              </Sc.InputInfo>
            ))}
          <Sc.InputInfoPass isSafePassword={isSafePassword}>
            {t('Escriba una contraseña más segura')}
          </Sc.InputInfoPass>
        </Sc.Row>
        <Sc.Terms>
          <input
            checked={checked}
            onChange={() => setChecked(!checked)}
            type='checkbox'
          />
          <a
            href={`${protocol}://legal.${domain}/${language}?_ga=2.177188385.548018809.1616663432-780997398.1615286388`}
            rel='noreferrer'
            target='_blank'
          >
            {t('He leído y acepto las condiciones legales')}
          </a>
        </Sc.Terms>
        <Button
          dataTestid='buttonNextRegiter'
          disabled={disabled}
          type='submit'
        >
          {t('Siguiente')}
          <ArrowRight />
        </Button>
      </Sc.FormRegisterFamily>
      {error.state && <Sc.ErrorApi>{error.message}</Sc.ErrorApi>}
      <Sc.AccountExists>
        <p>{t('¿Ya tienes una cuenta?')}</p>
        <Link to={t('routes::/login')}>{t('Iniciar sesión')}</Link>
      </Sc.AccountExists>
    </Sc.RegisterFamilyMain>
  )
}

export default RegisterFamily
