import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const Main = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  width: 790px;
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    width: 80%;
  }
  h2 {
    color: var(--darkRed);
    font-family: var(--fontRegular);
    font-size: 30px;
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
  }
`
export const SubTitle = styled.p`
  color: var(--grey);
  font-family: var(--fontBold);
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 50px;
  text-align: center;
  width: 100%;
`

export const Box = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  width: 100%;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    align-items: center;
    flex-direction: column;
  }
`

export const ContainerForm = styled.div`
  width: 350px;
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    width: 100%;
  }
`
