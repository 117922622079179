function Conexion() {
  return (
    <svg
      height='512'
      viewBox='0 0 747 512'
      width='747'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M320.32 385.396c-29.468 29.468-29.468 74.983 0 104.45s74.983 29.468 104.45 0 29.468-74.983 0-104.45-74.983-29.468-104.45 0zm85.778 85.777c-18.673 18.673-50.767 18.673-69.731 0-18.673-18.673-18.673-50.767 0-69.731 18.673-18.673 50.767-18.673 69.731 0 18.673 21.59 18.673 51.058 0 69.731zM87.495 238.349c-5.252 5.252-5.252 13.421 0 16.047 5.252 5.252 13.421 5.252 16.047 0 147.339-147.339 385.416-147.339 532.755 0 5.252 5.252 13.421 5.252 16.047 0 5.252-5.252 5.252-13.421 0-16.047-152.591-155.508-407.007-155.508-564.849 0zm88.112 88.111c-5.252 5.252-5.252 13.421 0 16.047 2.625 5.252 10.796 5.252 16.047 0 98.907-98.907 262.293-98.907 361.491 0 5.252 5.252 13.421 5.252 16.047 0 5.252-5.252 5.252-13.421 0-16.047-107.077-109.702-286.509-109.702-393.586 0zm567.766-173.889c-203.649-203.357-535.381-203.357-739.03 0-5.252 5.252-5.252 13.421-2.626 18.672 5.252 5.252 13.421 5.252 16.047 0 195.48-195.48 511.457-195.48 706.644 0 5.252 5.252 13.421 5.252 16.047 0 8.169-5.252 8.169-13.421 2.918-18.673z' />
    </svg>
  )
}

export default Conexion
