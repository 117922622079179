import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Tablets = styled.main`
  border-bottom: 1px solid var(--buttonSilver);
  padding: 60px 0;
  width: 100%;
`
export const Container = styled.article`
  display: flex;
  margin: 0 auto;
  max-width: 810px;
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
  }
  img {
    width: 100%;
  }
`
export const Description = styled.div`
  & > h2 {
    color: var(--darkRed);
    font-family: var(--fontRegular);
    font-size: 30px;
    line-height: 34px;
    padding-left: 30px;
  }
  & > p {
    font-family: var(--fontSansBold);
    font-size: 21px;
    line-height: initial;
    margin-bottom: 30px;
    margin-top: 35px;
    text-align: center;
  }
`
export const Stores = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
    margin-bottom: 20px;
  }
`
export const App = styled.div`
  cursor: pointer;
  margin-right: 10px;
  text-align: center;
  width: 250px;

  a {
    line-height: initial;
    text-decoration: none;
  }
  img {
    max-width: 190px;
    width: 100%;
  }
`
