export const IconTwitter = () => (
  <svg viewBox='0 0 50 50' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
      <rect fill='#FFF' height='50' rx='8' width='50' x='0' y='0' />
      <path
        d='M40 3.847a17.097 17.097 0 01-4.725 1.295A8.154 8.154 0 0038.882.61a16.388 16.388 0 01-5.2 1.985A8.217 8.217 0 0027.692 0c-4.54 0-8.195 3.685-8.195 8.202 0 .65.055 1.275.19 1.87-6.817-.332-12.85-3.6-16.902-8.577A8.256 8.256 0 001.662 5.64a8.212 8.212 0 003.643 6.815 8.098 8.098 0 01-3.705-1.01v.09c0 3.985 2.842 7.295 6.57 8.057a8.185 8.185 0 01-2.15.27c-.525 0-1.055-.03-1.553-.14 1.063 3.248 4.078 5.635 7.663 5.713a16.477 16.477 0 01-10.168 3.497c-.672 0-1.317-.03-1.962-.112 3.632 2.342 7.937 3.68 12.58 3.68 15.09 0 23.34-12.5 23.34-23.335 0-.363-.013-.713-.03-1.06C37.517 6.95 38.885 5.507 40 3.847z'
        fill='#444'
        fillRule='nonzero'
        transform='translate(5 11)'
      />
    </g>
  </svg>
)
