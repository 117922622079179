import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const AppsBanner = styled.div`
  background-color: var(--orangeLight);
  display: block;
  height: auto;
  padding: 60px 0 20px;
  text-align: center;
  h2 {
    font-size: 30px;
    line-height: 34px;
    color: var(--darkRed);
  }
`
export const AppsContainer = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 20px 0 30px;
  width: 790px;
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    display: block;
  }
  > a {
    margin: 0 8px;
    @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
      display: inline-block;
    }
    @media (max-width: ${DEVICES.SIZE_TABLET}) {
      margin: 5px 0;
      width: 46%;
    }
    @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
      width: 100%;
    }
    img {
      max-height: 53px;
    }
  }
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    width: 100%;
  }
`
