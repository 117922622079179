import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Article = styled.article`
  ${({ border }) => border && 'border-bottom: 1px solid var(--buttonSilver);'}
  ${({ heightAuto }) =>
    heightAuto ? ' min-height: auto;' : ' min-height: 720px;'}
  display: grid;
  grid-template-areas: '. description image image';
  grid-template-columns: 1.2fr minmax(50px, 320px) 1fr 1fr;
  overflow: hidden;
  padding: 60px 0;
  position: relative;
  width: 100%;
  ${({ even }) => {
    const gitTemplate = `
        grid-template-areas: 'image image description .';
        grid-template-columns: 1fr 1fr minmax(50px, 460px) 1.2fr;
      `

    if (even) {
      return `
        ${gitTemplate}
        & div:nth-child(2) {
          margin-left: auto;
          margin-right: inherit;
        }
      `
    }
    return ''
  }}
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    grid-template-areas: 'description image';
    grid-template-columns: minmax(50px, 320px) auto;
    padding: 20px;
    ${({ even }) => {
      const gitTemplate = `
        grid-template-areas: 'image description';
        grid-template-columns: auto minmax(50px, 320px);
      `
      if (even) {
        return gitTemplate
      }
      return ''
    }}
  }
  @media (max-width: 700px) {
    & {
      grid-template-areas: 'description' 'image';
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
    }
  }
`
export const Description = styled.div`
  grid-area: description;
  & h2 {
    color: var(--darkRed);
    display: block;
    font-size: 30px;
    @media (max-width: ${DEVICES.SIZE_TABLET}) {
      font-size: 25px;
    }
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 30px;
    width: 100%;
  }
  & p {
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 17px;
    line-height: normal;
    margin-bottom: 8px;
  }
  & div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px 0 15px;
    min-height: 35px;
    position: relative;
    width: 100%;
    & h3 {
      color: var(--grey);
      display: block;
      font-family: var(--fontRegular);
      font-size: 19px;
      font-weight: 700;
      line-height: 24px;
      margin: 0;
      padding-left: 20px;
      width: 100%;
    }
  }
  & svg {
    width: 40px;
  }

  & a {
    border-radius: 3px;
    border: 1px solid var(--grey);
    box-sizing: border-box;
    display: inline-block;
    font-size: 14px;
    height: 45px;
    line-height: 30px;
    margin-top: 10px;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    &:hover {
      border: 1px solid var(--greyHover);
      color: var(--greyHover);
    }
    &:active {
      border: 1px solid var(--orange);
      color: var(--orange);
    }
  }
`
export const Image = styled.div`
  grid-area: image;
  margin-left: 30px;
  margin-right: auto;
  padding: 0 20px;

  & img {
    display: block;
    max-width: 900px;
    width: 100%;

    @media (min-width: 705px) and (max-width: ${DEVICES.SIZE_TABLET}) {
      max-height: 100%;
      max-width: 100%;
      min-width: 100%;
      object-fit: cover;
      position: absolute;
      right: ${({ right }) => !right && '60%'};
    }
  }
`
