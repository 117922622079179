export const IconYoutube = () => (
  <svg viewBox='0 0 50 50' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
      <rect fill='#FFF' height='50' rx='8' width='50' x='0' y='0' />
      <path
        d='M38.3 2.65C37.215.72 36.037.365 33.64.23 31.245.067 25.222 0 20.005 0 14.777 0 8.752.067 6.36.227 3.967.365 2.787.717 1.692 2.65.575 4.577 0 7.897 0 13.742v.02c0 5.82.575 9.165 1.692 11.073 1.095 1.93 2.273 2.28 4.665 2.442 2.395.14 8.42.223 13.648.223 5.217 0 11.24-.083 13.637-.22 2.398-.163 3.575-.513 4.66-2.443C39.43 22.93 40 19.585 40 13.765v-.013-.007c0-5.848-.57-9.168-1.7-11.095zM15 21.25v-15l12.5 7.5-12.5 7.5z'
        fill='#444'
        fillRule='nonzero'
        transform='translate(5 11)'
      />
    </g>
  </svg>
)
