import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Container = styled.section`
  display: flex;
  justify-content: center;
`

export const Main = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;
  width: 790px;
  > h2 {
    color: var(--darkRed);
    font-family: var(--fontRegular);
    font-size: 30px;
    font-weight: 400;
    line-height: 34px;
    margin: 0 0 30px;
    text-align: center;
  }
  > h3 {
    color: var(--grey);
    font-family: var(--fontBold);
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 50px;
    text-align: center;
    width: 100%;
  }
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    width: 80%;
  }
`
