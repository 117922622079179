import { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import * as Sc from './AplicationsMoodle.style'
import BooksIcon from 'assets/icons/books'
import ImportIcon from 'assets/icons/import'
import IntegratedIcon from 'assets/icons/integrated'
import SincroIcon from 'assets/icons/sincro'
import aplicationsImg from 'assets/images/moodle/cover.webp'
import Footer from 'components/footer'
import Hero from 'components/hero'
import IconDescription from 'components/iconDescription'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const AplicationsMoodle = () => {
  const { t } = useTranslation()
  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.APPLICATIONS_MOODLE)
  }, [])
  return (
    <>
      <Hero
        alt={t('Edubook para Moodle')}
        image={aplicationsImg}
        subTitle={t(
          'Vicens Vives ha desarrollado plugins que hacen uso del protocolo LTI para que la integración de Edubook en Moodle sea ágil, rápida y sencilla:'
        )}
        title={t('Edubook para Moodle')}
      >
        <Sc.List>
          <IconDescription Icon={() => <ImportIcon />}>
            {t('Importación automática de cursos')}
          </IconDescription>
          <IconDescription Icon={() => <BooksIcon />}>
            {t('Asignación automática de licencias')}
          </IconDescription>
          <IconDescription Icon={() => <SincroIcon />}>
            {t('Sincronización automática de calificaciones')}
          </IconDescription>
          <IconDescription Icon={() => <IntegratedIcon />}>
            {t('Integración con tabletas, web y escritorio')}
          </IconDescription>
        </Sc.List>
      </Hero>
      <Sc.Moodle>
        <Sc.Description>
          <Trans i18nKey='Bloque de creación de cursos'>
            <h3>Bloque de creación de cursos</h3>
            <h4>
              Crea cursos de Moodle con los libros de Edubook de forma sencilla.
            </h4>
            <p>
              Con un solo clic puedes crear un curso en tu Moodle
              correspondiente a un libro de Edubook, manteniendo la estructura
              del libro y todos sus elementos: teoría, actividades, documentos,
              enlaces, etc.
            </p>
            <p>
              Además, ahora puedes crear los cursos en formato estándar y
              consultar las calificaciones organizadas por categorías.
            </p>
          </Trans>
        </Sc.Description>

        <Sc.Links>
          <a href='https://edubook.vicensvives.com/downloads/moodle/block_courses_vicensvives_moodle33_latest.zip'>
            {t('Descargar plugin de creación de cursos')}
            <span> 20.96 KB (.zip)</span>
          </a>
          <p>{t('Compatible para versiones 3.2, 3.3, 3.4, 3.8, 3.9 y 3.10')}</p>
          <a href='https://edubook.vicensvives.com/downloads/moodle/block_courses_vicensvives_moodle28_latest.zip'>
            {t('Descargar plugin de creación de cursos')}
            <span> 92.66 KB (.zip)</span>
          </a>
          <p>{t('Compatible para versiones 2.8, 2.9, 3.0 y 3.1')}</p>
        </Sc.Links>
      </Sc.Moodle>
      <Sc.Line />
      <Sc.Moodle>
        <Sc.Description>
          <Trans i18nKey='Formato de curso propio'>
            <h3>Formato de curso propio</h3>
            <h4>
              Formato de curso propio adaptado a las necesidades de aprendizaje.
            </h4>
            <p>
              Formato de curso propio Formato de curso propio adaptado a las
              necesidades de aprendizaje. El formato del curso de Moodle,
              desarrollado específicamente para Edubook, mejora la experiencia
              del usuario que accede a los contenidos a través de Moodle.
            </p>
            <p>
              Este diseño permite ocultar unidades, temas o actividades en los
              que no estés trabajando para facilitar la gestión del aula.
              Además, se ha conservado la iconografía propia de los elementos de
              los libros de Edubook para facilitar la comprensión.
            </p>
          </Trans>
        </Sc.Description>
        <Sc.Links>
          <a href='https://edubook.vicensvives.com/downloads/moodle/course_format_vv_moodle33_latest.zip'>
            {t('Descargar plugin de formato de curso')}
            <span> 58.93 KB (.zip)</span>
          </a>
          <p>{t('Compatible con todas las versiones de Moodle')}</p>
        </Sc.Links>
      </Sc.Moodle>
      <Sc.Line />
      <Sc.Moodle>
        <Sc.Description>
          <Trans i18nKey='Bloque resumen de licencias'>
            <h3>Bloque resumen de licencias</h3>
            <h4>
              Realiza un seguimiento del consumo de licencias en tu centro.
            </h4>
            <p>
              Este plugin permite consultar la información sobre las licencias
              de Edubook utilizadas en tu centro.
            </p>
            <p>
              Podrás hacer un seguimiento de las licencias disponibles y de las
              licencias en uso según si son de estudiante o de docente.
            </p>
          </Trans>
        </Sc.Description>
        <Sc.Links>
          <a href='https://edubook.vicensvives.com/downloads/moodle/block_licenses_vicensvives_moodle33_latest.zip'>
            {t('Descargar plugin de resumen de licencias')}
            <span> 12.16 KB (.zip)</span>
          </a>
          <p>{t('Compatible con todas las versiones de Moodle')}</p>
        </Sc.Links>
      </Sc.Moodle>
      <Sc.Line />
      <Sc.Moodle>
        <Sc.Description>
          <Trans i18nKey='Servicios web'>
            <h3>Servicios web</h3>
            <h4>Recibe todas las calificaciones de Edubook en tu Moodle.</h4>
            <p>
              Este plugin permite enviar a Moodle todas las calificaciones de
              las actividades realizadas en Edubook, independientemente de si se
              han realizado en la versión web o en cualquiera de las
              aplicaciones móviles disponibles.
            </p>
          </Trans>
        </Sc.Description>
        <Sc.Links>
          <a href='https://edubook.vicensvives.com/downloads/moodle/local_wsvicensvives_moodle33_latest.zip'>
            {t('Descargar plugin de servicios web')}
            <span> 6.13 KB (.zip)</span>
          </a>
          <p>{t('Compatible con todas las versiones de Moodle')}</p>
        </Sc.Links>
      </Sc.Moodle>
      <Footer />
    </>
  )
}

export default AplicationsMoodle
