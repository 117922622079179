const Icon = () => (
  <svg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >
    <path d='M0 0h512v512h-512v-512z' fill='#e20613' />
    <path
      d='M208.334 198.695c0-26.778 21.423-48.201 48.201-48.201s48.201 21.422 48.201 48.201c0 26.778-21.423 48.201-48.201 48.201s-48.201-21.422-48.201-48.201zM387.749 301.523c0-0.536 0-1.071 0-2.142 0-1.606-0.536-3.749-0.536-5.356 0-0.536 0-1.071 0-1.606-3.214-25.707-13.389-48.737-28.921-67.481v0c-4.284-5.356-9.104-10.175-13.925-14.46l-21.958 27.314c-4.82 7.498-10.712 14.46-17.138 20.887 7.498 6.962 13.389 16.067 16.603 25.707 0 0 0 0.536 0 0.536 0.536 1.606 1.071 3.214 1.606 4.82 0 0.536 0.536 1.606 0.536 2.142s0.536 1.071 0.536 2.142c0.536 1.071 0.536 2.678 0.536 3.749 0 0.536 0 0.536 0 1.071 0 1.606 0.536 2.678 0.536 4.284 0 0.536 0 1.071 0 1.071 0 1.606 0 3.214 0 4.82 0 38.56-31.063 69.624-69.088 70.159-38.025 0-69.088-31.063-69.088-69.088v-238.862h-63.732v364.719h63.732v-13.925c0 0 0.536 0.536 0.536 0.536 2.143 1.071 4.285 2.142 5.891 3.214 0.536 0 0.536 0.536 1.071 0.536 2.143 1.071 4.82 2.142 6.963 3.214 0.536 0 0.536 0.536 1.071 0.536 2.143 1.071 4.285 1.606 6.427 2.678 0.536 0 1.071 0.536 1.607 0.536 2.678 1.071 4.82 1.606 7.498 2.678 0.536 0 0.536 0 1.071 0.536 2.143 0.536 4.285 1.071 6.963 1.606 0.536 0 1.071 0.536 1.607 0.536 2.678 0.536 5.356 1.071 8.034 1.606 0.536 0 0.536 0 1.071 0 2.678 0.536 4.82 0.536 7.498 0.536 0.536 0 1.607 0 2.143 0 2.678 0 5.356 0.536 8.034 0.536v0c23.029 0 44.452-5.891 63.197-16.067 1.606-1.071 3.749-2.142 5.356-3.214 3.749-2.142 6.962-4.82 10.712-6.962 8.569-6.427 16.067-13.925 23.029-21.958 2.678-3.214 5.356-6.427 7.498-10.175 14.461-20.887 22.493-46.594 22.493-73.908 0.536-2.678 0.536-5.356 0.536-7.498z'
      fill='#fff'
    />
  </svg>
)

export default Icon
