import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import * as Sc from './TextImage.style'

const TextImage = ({
  alt,
  border,
  children,
  heightAuto,
  img,
  imgLeft,
  right,
}) => {
  const { t } = useTranslation()

  return (
    <Sc.Article border={border} even={imgLeft} heightAuto={heightAuto}>
      <Sc.Description>{children}</Sc.Description>
      <Sc.Image right={right}>
        <img alt={t(alt)} src={img} />
      </Sc.Image>
    </Sc.Article>
  )
}

TextImage.defaultProps = {
  border: false,
  heightAuto: false,
  imgLeft: true,
  right: false,
}

TextImage.propTypes = {
  alt: PropTypes.string.isRequired,
  border: PropTypes.bool,
  children: PropTypes.node.isRequired,
  heightAuto: PropTypes.bool,
  img: PropTypes.string.isRequired,
  imgLeft: PropTypes.bool,
  right: PropTypes.bool,
}

export default TextImage
