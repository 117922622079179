import styled from 'styled-components/macro'

export const SelectContainer = styled.div`
  position: relative;
`

export const SelectLabel = styled.label`
  color: var(--grey);
  display: block;
  font-family: var(--fontSansSemiBold);
  font-size: 17px;
`

export const SelectComponent = styled.div`
  position: relative;
`
export const SelectArrowContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 8px;
  width: 18px;
  svg {
    fill: var(--white);
  }
`

export const SelectOptionsContainer = styled.select`
  appearance: none;
  background-color: var(--footerLng);
  border: 0;
  color: var(--white);
  cursor: pointer;
  font-size: 14px;
  outline: none;
  padding: 10px;
  width: 100%;
`
