import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const BannerContainer = styled.div`
  display: block;
  height: 400px;
  text-align: center;
  overflow: hidden;
  padding: 120px 0;
  position: relative;
  h2 {
    color: var(--darkRed);
    font-family: var(--fontBold);
    font-size: 40px;
    font-weight: 400;
    line-height: 44px;
    margin: 0 0 10px;
    position: relative;
    text-shadow: 0.7px 0.7px 3px var(--white);
    width: 100%;
  }
  p {
    position: relative;
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    height: fit-content;
  }
`
export const BannerBg = styled.div`
  bottom: 0;
  position: absolute;
  top: 0;
  width: 100%;
  img {
    height: auto;
    min-height: 100%;
    min-width: 100%;
    width: auto;
  }
`
export const BannerData = styled.div`
  display: flex;
  margin: 50px auto 0;
  max-width: 400px;
  position: relative;
  width: 50%;
  > div {
    flex-grow: 1;
    margin-left: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    input {
      border: 0;
    }
  }
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    width: 95%;
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    display: block;
    > div {
      margin: 10px auto;
    }
  }
`
export const ButtonLink = styled.div`
  background-color: var(--red);
  color: var(--white);
  font-family: var(--oxigenBold);
  height: auto;

  :hover {
    background-color: var(--darkRed);
  }
  > a {
    color: var(--white);
    display: block;
    font-size: 14px;
    line-height: 21px;
    padding: 13px 0;
    text-decoration: none;
    :hover {
      text-decoration: none;
    }
  }
  svg {
    fill: var(--white);
    height: 20px;
    margin-left: 5px;
    vertical-align: middle;
    width: 25px;
  }
`
