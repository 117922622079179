import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import * as Sc from './FormLogin.style'
import Button from 'components/Button'
import InputText from 'components/inputText'
import { login } from 'services/login'
import { getUrlByParams } from 'utils'
import { DATA_LAYER_VALUES } from 'utils/constants/gtm'
import { sessionStorageRemoveItem } from 'utils/storage'

const FormLogin = ({ licenseCode, target, bookId }) => {
  const { t } = useTranslation()
  const [data, setData] = useState({ email: '', password: '' })
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (data.password !== '' && data.email !== '') {
      return setDisabled(false)
    }
    return setDisabled(true) || setError(null)
  }, [data])

  const handleChange = ({ target: { id, value } }) => {
    if (value.trim() !== '') {
      setData({ ...data, [id]: value })
    } else {
      setData({ ...data, [id]: '' })
    }
  }

  const handleSubmit = async (e) => {
    setDisabled(true)
    e.preventDefault()
    const password = data.password.trim()
    const user = data.email.trim()
    try {
      const dataLogin = licenseCode
        ? {
            bookId,
            licenseCode,
            password,
            target,
            user,
          }
        : { password, user }
      await login(dataLogin)
      sessionStorageRemoveItem('regionalBookId')
      const redirectUrl = getUrlByParams()
      window.location.href = redirectUrl || window.config.defUrl
      setError(null)
      window.dataLayer.push({
        event: DATA_LAYER_VALUES.LOGIN,
      })
    } catch (error) {
      const { status } = error?.response || {}

      setError(status)
      setDisabled(false)
    }
  }

  const renderErrorMessage = (error) => {
    const errorMessage =
      error === 401
        ? t(
            'El nombre de usuario y la contraseña que has introducido no coinciden'
          )
        : t(
            'En estos momentos no se puede acceder a Edubook. Disculpa las molestas e inténtalo de nuevo más tarde.'
          )
    return <p role={'paragraph'}>{errorMessage}</p>
  }

  return (
    <Sc.Form onSubmit={handleSubmit}>
      <Sc.Row>
        <InputText
          autoComplete
          id='email'
          label={t('Correo electrónico o nombre de usuario')}
          onChange={handleChange}
          type='text'
          value={data.email}
        />
      </Sc.Row>
      <Sc.Row>
        <InputText
          autoComplete
          id='password'
          label={t('Contraseña')}
          onChange={handleChange}
          type='password'
          value={data.password}
        />
      </Sc.Row>
      <Sc.PasswordRecovery>
        <Trans i18nKey='¿Has olvidado la contraseña?'>
          ¿Has olvidado la
          <Link to={t('routes::/recoverPassword')}>
            <strong>contraseña</strong>
          </Link>
          ?
        </Trans>
      </Sc.PasswordRecovery>
      <Sc.Button>
        {error && renderErrorMessage(error)}
        <Button dataTestid='buttonAccess' disabled={disabled} type='submit'>
          {t('Entra')}
        </Button>
      </Sc.Button>
    </Sc.Form>
  )
}

FormLogin.defaultProps = {
  bookId: '',
  licenseCode: '',
  target: '',
}
FormLogin.propTypes = {
  bookId: PropTypes.string,
  licenseCode: PropTypes.string,
  target: PropTypes.string,
}

export default FormLogin
