import { useContext, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import * as Sc from './PasswordRecoverySent.style'
import Button from 'components/Button'
import { RecoverPasswordContext } from 'context/RecoverPasswordContext'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const PasswordRecoverySent = () => {
  const { email } = useContext(RecoverPasswordContext)
  const { t } = useTranslation()
  const { push } = useHistory()
  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.PASSWORD_RECOVERY_SENT)
  }, [])

  return (
    <Sc.Container>
      <Sc.Main>
        <h2>{t('¡Hemos recibido tu solicitud!')}</h2>
        <p>
          {t(
            'Te hemos enviado las instrucciones para recuperar tu contraseña a {{email}}',
            { email }
          )}
        </p>
        <p>
          {t(
            'Revisa tu correo y sigue las instrucciones que se detallan en él'
          )}
        </p>
        <div>
          <Button
            dataTestid='buttonAccept'
            disabled={false}
            onClick={() => push('routes::/')}
            type='button'
          >
            {t('Aceptar')}
          </Button>
        </div>
      </Sc.Main>
    </Sc.Container>
  )
}

export default PasswordRecoverySent
