import { useEffect, useLayoutEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'

import * as Sc from './Header.style'
import { HamMenu, Logo } from 'assets/icons'
import useOnClickOutside from 'hooks/useOnClickOutside'

const Header = () => {
  const { push } = useHistory()
  const { pathname } = useLocation()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [sizeScreen, setSizeScreen] = useState(window.innerWidth)
  const [open, setOpen] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const { ref } = useOnClickOutside(setOpen)
  const isDesktop = sizeScreen > 768
  const blockAction = window?.rebranding?.showRebrandingModal
  const currentTeachingArea = blockAction
    ? '#'
    : `${window.config?.teachingAreaUrl}?lang=${language}`

  useLayoutEffect(() => {
    const updateSize = () => {
      setSizeScreen(window.innerWidth)
    }
    window.onresize = updateSize
    updateSize()
    return () => {
      window.onresize = null
    }
  }, [])

  // WIP: MEJORAR
  useEffect(() => {
    setShowMenu(
      !pathname.includes(t('routes::/recoverPassword')) &&
        !pathname.includes(t('routes::/register')) &&
        !pathname.includes(t('routes::/resetPassword')) &&
        !pathname.includes(t('routes::/login'))
    )
  }, [pathname])

  const menus = () => {
    const renderLinkTeachingArea = () => (
      <a
        data-testid='teaching-area-button-public-web'
        href={currentTeachingArea}
        rel='noreferrer'
      >
        {t('Solicitar acceso')}
      </a>
    )

    return (
      <>
        <Sc.BoxLink active={pathname.includes(t('routes::/features'))}>
          <Link
            to={(location) => ({
              ...location,
              pathname: t('routes::/features'),
              search: '?qr=0',
            })}
          >
            {t('Características')}
          </Link>
        </Sc.BoxLink>
        <Sc.BoxLink active={pathname.includes(t('routes::/classroom'))}>
          <Link to={t('routes::/classroom')}>{t('En el aula')}</Link>
        </Sc.BoxLink>
        <Sc.BoxLink active={pathname.includes(t('routes::/apps'))}>
          <Link to={t('routes::/apps')}>{t('Aplicaciones')}</Link>
        </Sc.BoxLink>
        <Sc.BoxLink active={pathname.includes(t('routes::/demo'))}>
          <Link to={t('routes::/demo')}>{t('Demo')}</Link>
        </Sc.BoxLink>
        <Sc.BoxLink>
          <a
            href={blockAction ? '#' : t('routes::/help')}
            rel='noreferrer'
            target={blockAction ? '_self' : '_blank'}
          >
            {t('Ayuda')}
          </a>
        </Sc.BoxLink>
        {window.fflags?.showTeachingArea && (
          <Sc.BoxLink>
            {isDesktop ? (
              <Sc.CustomButton>{renderLinkTeachingArea()}</Sc.CustomButton>
            ) : (
              renderLinkTeachingArea()
            )}
          </Sc.BoxLink>
        )}
      </>
    )
  }

  const ButtonLogin = () => (
    <Sc.CustomButton primary>
      <Link to={t('routes::/login')}>{t('Iniciar sesión')}</Link>
    </Sc.CustomButton>
  )

  const getMenu = () =>
    isDesktop ? (
      <Sc.List>
        {menus()}
        {ButtonLogin()}
      </Sc.List>
    ) : (
      <Sc.List>
        {ButtonLogin()}
        <Sc.IconMenu onClick={() => setOpen(!open)} open={open} ref={ref}>
          <HamMenu />
          <Sc.MenuHamburger open={open}>
            <ul>{menus()}</ul>
          </Sc.MenuHamburger>
        </Sc.IconMenu>
      </Sc.List>
    )

  return (
    <Sc.Container>
      <Sc.Nav>
        <Sc.Logo onClick={() => push(t('routes::/'))}>
          <Logo />
        </Sc.Logo>
        {showMenu && getMenu()}
      </Sc.Nav>
    </Sc.Container>
  )
}

export default Header
