import { StrictMode } from 'react'

import GlobalFonts from '@frontend_vv/base-ui.fonts'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import App from 'app'
import GlobalStyle from 'assets/styles'

import 'config/i18n'
import 'config/httpMiddleware'
import GrafanaFaro from 'components/GrafanaFaro'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <StrictMode>
    <GlobalStyle />
    <GlobalFonts />
    <GrafanaFaro>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GrafanaFaro>
  </StrictMode>
)
reportWebVitals()
