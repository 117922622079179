import i18n, { use } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import Catalan from 'locales/ca.json'
import English from 'locales/en.json'
import Spanish from 'locales/es.json'
import Routes from 'routes/routes.json'

use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    defaultNS: 'translation',
    fallbackLng: ['es', 'ca', 'en'],
    keySeparator: false,
    ns: ['translation', 'routes'],
    nsSeparator: '::',
    resources: {
      ca: { routes: Routes.ca, translation: Catalan },
      en: { routes: Routes.en, translation: English },
      es: { routes: Routes.es, translation: Spanish },
    },
  })

export default i18n
