import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import * as Sc from './LoginWithGoogle.styles'
import GoogleErrorModal from 'components/googleSigninErrorModal'
import { loginWithGoogle } from 'services/login'
import { getUrlByParams } from 'utils'

// Google API shortcut
const { google } = window

const LoginWithGoogle = () => {
  const [error, setError] = useState(false)
  const { apiGoogleClientID } = window.config || {}
  const {
    i18n: { language },
  } = useTranslation()

  const apiLogin = async (credential) => {
    if (credential) {
      try {
        await loginWithGoogle(credential)
        const redirectUrl = getUrlByParams()
        window.location = redirectUrl || window.config.defUrl
      } catch {
        setError(true)
      }
    }
  }

  useEffect(() => {
    const handleCredentialResponse = (response) => {
      const { credential } = response || {}
      if (credential) {
        apiLogin(credential)
      } else {
        setError(true)
      }
    }
    google?.accounts.id.initialize({
      callback: handleCredentialResponse,
      client_id: apiGoogleClientID,
    })

    google?.accounts.id.renderButton(
      document.getElementById('buttonLoginWithGoogle'),
      {
        locale: language,
        size: 'large',
        text: 'signin_with',
      }
    )
  }, [google])

  return (
    <>
      <Sc.Container>
        <div id='buttonLoginWithGoogle' />
      </Sc.Container>
      {error && <GoogleErrorModal setError={setError} />}
    </>
  )
}

export default LoginWithGoogle
