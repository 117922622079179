import oxygenBoldEot from 'assets/fonts/Oxygen_Bold/oxygen-bold.eot'
import oxygenBoldSvg from 'assets/fonts/Oxygen_Bold/oxygen-bold.svg'
import oxygenBoldTtf from 'assets/fonts/Oxygen_Bold/oxygen-bold.ttf'
import oxygenBoldWoff from 'assets/fonts/Oxygen_Bold/oxygen-bold.woff'
import oxygenRegularEot from 'assets/fonts/Oxygen_Regular/oxygen-regular.eot'
import oxygenRegularSvg from 'assets/fonts/Oxygen_Regular/oxygen-regular.svg'
import oxygenRegularTtf from 'assets/fonts/Oxygen_Regular/oxygen-regular.ttf'
import oxygenRegularWoff from 'assets/fonts/Oxygen_Regular/oxygen-regular.woff'
import sourceSansBoldEot from 'assets/fonts/SourceSansPro_Bold/sourcesanspro-bold.eot'
import sourceSansBoldSvg from 'assets/fonts/SourceSansPro_Bold/sourcesanspro-bold.svg'
import sourceSansBoldTtf from 'assets/fonts/SourceSansPro_Bold/sourcesanspro-bold.ttf'
import sourceSansBoldWoff from 'assets/fonts/SourceSansPro_Bold/sourcesanspro-bold.woff'
import sourceSansRegularEot from 'assets/fonts/SourceSansPro_Regular/sourcesanspro-regular.eot'
import sourceSansRegularSvg from 'assets/fonts/SourceSansPro_Regular/sourcesanspro-regular.svg'
import sourceSansRegularTtf from 'assets/fonts/SourceSansPro_Regular/sourcesanspro-regular.ttf'
import sourceSansRegularWoff from 'assets/fonts/SourceSansPro_Regular/sourcesanspro-regular.woff'
import sourceSansSemiBoldEot from 'assets/fonts/SourceSansPro_Semibold/sourcesanspro-semibold.eot'
import sourceSansSemiBoldSvg from 'assets/fonts/SourceSansPro_Semibold/sourcesanspro-semibold.svg'
import sourceSansSemiBoldTtf from 'assets/fonts/SourceSansPro_Semibold/sourcesanspro-semibold.ttf'
import sourceSansSemiBoldWoff from 'assets/fonts/SourceSansPro_Semibold/sourcesanspro-semibold.woff'

export const Fonts = `
  @font-face {
    font-family: 'oxygenRegular';
      src: local('oxygenRegular'), local('oxygenRegular'),
      url(${oxygenRegularEot}) format("embedded-opentype"),
      url(${oxygenRegularTtf}) format("truetype"),
      url(${oxygenRegularWoff}) format("woff"),
      url(${oxygenRegularSvg}) format("svg");
      font-style: normal;
  }
  @font-face {
    font-family: 'oxygenBold';
      src: local('oxygenBold'), local('oxygenBold'),
      url(${oxygenBoldEot}) format("embedded-opentype"),
      url(${oxygenBoldTtf}) format("truetype"),
      url(${oxygenBoldWoff}) format("woff"),
      url(${oxygenBoldSvg}) format("svg");
      font-style: normal;
  }
  @font-face {
    font-family: 'sourceSansBold';
      src: local('sourceSansBold'), local('sourceSansBold'),
      url(${sourceSansBoldEot}) format("embedded-opentype"),
      url(${sourceSansBoldTtf}) format("truetype"),
      url(${sourceSansBoldWoff}) format("woff"),
      url(${sourceSansBoldSvg}) format("svg");
      font-style: normal;
  }
  @font-face {
    font-family: 'sourceSansRegular';
      src: local('sourceSansRegular'), local('sourceSansRegular'),
      url(${sourceSansRegularEot}) format("embedded-opentype"),
      url(${sourceSansRegularTtf}) format("truetype"),
      url(${sourceSansRegularWoff}) format("woff"),
      url(${sourceSansRegularSvg}) format("svg");
      font-style: normal;
  }
  @font-face {
    font-family: 'sourceSansSemiBold';
      src: local('sourceSansSemiBold'), local('sourceSansSemiBold'),
      url(${sourceSansSemiBoldEot}) format("embedded-opentype"),
      url(${sourceSansSemiBoldTtf}) format("truetype"),
      url(${sourceSansSemiBoldWoff}) format("woff"),
      url(${sourceSansSemiBoldSvg}) format("svg");
      font-style: normal;
  }
  :root{
    --fontRegular:'oxygenRegular',-apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    --fontBold:'oxygenBold',-apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    --fontSansBold:'sourceSansBold',-apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    --fontSansRegular:'sourceSansRegular',-apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    --fontSansSemiBold:'sourceSansSemiBold',-apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }
`
