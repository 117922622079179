import axios from 'axios'

const httpMiddleware = () => {
  axios.interceptors.request.use(
    (request) => {
      if (!request.url.includes('/bff-vle')) {
        if (!request.url.includes('/vle')) {
          request.url = `/bk${request.url}`
        } else {
          request.url = `/bff-vle${request.url}`
        }
      }
      return request
    },
    (err) => Promise.reject(err.response.data)
  )

  axios.interceptors.response.use(
    (response) => response,
    (asyncError) => {
      const { status } = asyncError.response
      if (status === 401) {
        const {
          data: { loggedIn },
        } = asyncError.response
        if (loggedIn === false) {
          window.location.href = '/'
        }
      }
      return Promise.reject(asyncError)
    }
  )
}

httpMiddleware()
