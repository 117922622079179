import styled from 'styled-components/macro'

export const ModalContainer = styled.div`
  background-color: var(--bgGrey);
  height: 100vh;
  left: 50%;
  padding: 1rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  z-index: 999999;
`
