import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const RegisterFamilyMain = styled.div`
  margin: 0 auto;
  padding: 60px 0;
  width: 790px;
  h2 {
    color: var(--darkRed);
    font-family: var(--fontRegular);
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 30px;
    text-align: center;
    @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
      padding: 0 10px;
    }
  }
  h3 {
    color: var(--grey);
    font-family: var(--fontBold);
    font-size: 16px;
    margin-bottom: 50px;
    text-align: center;
    @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
      padding: 0 10px;
    }
  }
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    width: 100%;
  }
`
export const StepsRegister = styled.div`
  margin: 0 auto;
  margin-bottom: 50px;
  max-width: 500px;
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    display: flex;
    justify-content: flex-start;
  }
  > div {
    display: inline-block;
    padding: 12px;
    position: relative;
    vertical-align: middle;
    width: 48%;
    @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
      min-height: 75px;
      width: 40%;
    }
    @media (max-width: ${DEVICES.SIZE_MOBILE}) {
      min-height: 75px;
      padding: 10px 5px;
      width: 40%;
    }
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
    padding: 0 10px;
    text-align: center;
  }
`
export const FirstStep = styled.div`
  background-color: var(--yellow);
  text-align: left;
  z-index: 100;
  > p {
    font-family: var(--fontSansBold);
    font-size: 17px;
  }
  ::after {
    border: solid transparent;
    border-color: var(--lightBlue);
    border-width: 30px;
    border-left-color: var(--yellow);
    content: ' ';
    left: 100%;
    height: 0;
    margin-top: -30px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 0;
    @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
      border-width: 37px;
      top: 42%;
    }
    @media (max-width: ${DEVICES.SIZE_MOBILE}) {
      border-width: 38px;
      left: 86%;
      top: 40%;
    }
  }
`
export const StepInfo = styled.div`
  font-family: var(--fontSansRegular);
  font-size: 14px;
`
export const SecondStep = styled.div`
  background-color: var(--silver);
  margin-left: -8px;
  padding-left: 45px;
  text-align: right;
  > p {
    color: var(--silverLine);
    font-family: var(--fontSansBold);
    font-size: 17px;
    padding-left: 10px;
  }
  > div {
    color: var(--silverLine);
    padding-left: 10px;
  }
  ::after {
    border: solid transparent;
    border-color: var(--lightBlue);
    border-width: 30px;
    border-left-color: var(--silver);
    content: ' ';
    left: 100%;
    height: 0;
    margin-top: -30px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 0;
    @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
      border-width: 33px;
      top: 42%;
    }
  }
`
export const FormRegisterFamily = styled.form`
  display: block;
  margin: 0 auto;
  max-width: 400px;
  button {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    margin: 10px 0;
    svg {
      fill: var(--buttonGrey);
      height: 20px;
      margin-left: 5px;
      vertical-align: middle;
      width: 25px;
    }
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE_BIG}) {
    padding: 10px;
    width: 100%;
  }
`

export const Row = styled.div`
  margin-top: 20px;
  input {
    font-size: 17px;
    margin-top: 5px;
    padding: 10px;
  }
  > div > label:last-child {
    margin-top: 7px;
  }
`
export const InputInfo = styled.div`
  color: ${({ isValidPassword }) =>
    isValidPassword === false ? 'var(--red)' : 'var(--silverLine)'};
  font-family: var(--fontSansRegular);
  font-size: 14px;
  line-height: 1.3;
  margin-top: 5px;
`
export const InputInfoPass = styled.div`
  display: ${({ isSafePassword }) =>
    isSafePassword === false ? 'block' : 'none'};
  color: var(--red);
  font-family: var(--fontSansRegular);
  font-size: 14px;
  line-height: 1.3;
  margin-top: 5px;
`
export const Terms = styled.div`
  margin-top: 40px;
  a {
    color: var(--silverLine);
    font-family: var(--fontSansRegular);
    font-size: 14px;
    :hover {
      color: var(--buttonSilver);
    }
  }
`
export const AccountExists = styled.div`
  text-align: center;
  margin-top: 40px;
  p {
    color: var(--grey);
    font-family: oxygenbold;
    font-size: 14px;
    margin: 10px 0 0;
  }
  a {
    color: var(--blueLink);
    font-family: oxygenbold;
    font-size: 14px;
  }
`

export const Error = styled.div`
  color: var(--red);
  font-family: var(--fontSansRegular);
  font-size: 14px;
  padding-top: 8px;
`

export const ErrorApi = styled.div`
  color: var(--red);
  font-family: var(--fontSansRegular);
  font-size: 15px;
  margin-top: 8px;
  text-align: center;
`
