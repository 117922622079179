import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Form = styled.form`
  width: 400px;
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    width: 100%;
  }
`
export const Row = styled.div`
  padding: 10px 0;
`

export const PasswordRecovery = styled.p`
  color: var(--silverLine);
  font-family: var(--fontSansRegular);
  font-size: 14px;
  line-height: inherit;
  > a {
    color: var(--silverLine);
    :hover {
      color: var(--buttonSilver);
    }
    > strong {
      color: var(--silverLine);
      font-family: var(--fontSansBold);
      :hover {
        color: var(--buttonSilver);
      }
    }
  }
`

export const Button = styled.div`
  display: flex;
  flex-direction: column;
  height: 114px;
  justify-content: space-around;
  margin-top: 10px;
  > p {
    color: var(--red);
    font-family: var(--fontSansRegular);
    font-size: 14px;
  }
`
