import { useContext, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import * as Sc from './FormPasswordRecovery.style'
import Button from 'components/Button'
import InputEmail from 'components/inputEmail'
import { RecoverPasswordContext } from 'context/RecoverPasswordContext'
import { recoverPassword } from 'services/email/email'

const FormPasswordRecovery = () => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const [disabled, setDisabled] = useState(true)
  const [value, setEmailValue] = useState('')
  const [error, setError] = useState('')
  const { setEmail } = useContext(RecoverPasswordContext)

  const handleChange = ({ emailValue, isCorrect }) => {
    setDisabled(!isCorrect)
    setEmailValue(emailValue)
    setEmail(emailValue)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await recoverPassword({ email: value })
      push(t('routes::/recoverPassword/sent'))
    } catch (err) {
      setDisabled(true)
      setError({
        message: t(
          'Ha ocurrido un error, por favor inténtelo de nuevo más tarde'
        ),
        state: true,
      })
    }
  }

  return (
    <Sc.Form onSubmit={handleSubmit}>
      <InputEmail
        emailValue={({ emailValue, isCorrect }) =>
          handleChange({ emailValue, isCorrect })
        }
      />
      {error.state && <Sc.Error>{error.message}</Sc.Error>}

      <Sc.Box>
        <Button
          dataTestid='buttonRecoveryPassword'
          disabled={disabled}
          type='submit'
        >
          {t('Restablece contraseña')}
        </Button>
      </Sc.Box>
    </Sc.Form>
  )
}

export default FormPasswordRecovery
