import styled from 'styled-components/macro'

export const BoxMessage = styled.div`
  background-color: ${({ isError }) =>
    isError ? 'rgba(226, 6, 19, 0.7)' : 'rgba(0,150,64,0.7)'};
  margin-top: 14px;
  padding: 10px;
  position: relative;
  text-align: center;
  ::before {
    border: solid transparent;
    border-bottom-color: ${({ isError }) =>
    isError ? 'rgba(226, 6, 19, 0.7)' : 'rgba(0,150,64,0.7)'};
    border-width: 8px;
    bottom: 100%;
    content: ' ';
    height: 0;
    left: 50%;
    position: absolute;
    width: 0;
  }
  > p {
    color: var(--white);
    font-family: var(--fontSansBold);
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    text-align: center;
    text-shadow: none;
    a {
      font-family: var(--fontSansBold);
      padding: 0;
    }
  }
  span {
    color: var(--white);
    font-family: var(--fontSansRegular);
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    text-align: center;
    text-shadow: none;
  }
`

export const ExtraMessage = styled.span`
  color: var(--white);
  display: block;
  font-family: var(--fontSansRegular);
  font-size: 14px;
  margin: 0 auto;
  padding: 4px 16px 0;
  text-align: center;
`
