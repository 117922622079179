import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import * as Sc from './CoverAddBook.style'
import { ArrowRight } from 'assets/icons'

const CoverAddBook = ({ course, image, licenseCode, title }) => {
  const { t } = useTranslation()

  return (
    <>
      <Sc.Book>
        <p>{title}</p>
        <p>{course}</p>
        <p>{licenseCode}</p>
        <img alt={t('portadaLibro')} src={image} />
      </Sc.Book>
      <Sc.Row>
        <p>{t('Vincula este libro a tu cuenta')}</p>
        <ArrowRight />
      </Sc.Row>
    </>
  )
}

CoverAddBook.propTypes = {
  course: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  licenseCode: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default CoverAddBook
