import { createContext, useState } from 'react'

import PropTypes from 'prop-types'

export const ErrorContext = createContext()
export const ErrorProvider = ({ code = null, children }) => {
  const [error, setError] = useState(code)
  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
    </ErrorContext.Provider>
  )
}

ErrorProvider.defaultProps = {
  children: null,
  code: null,
}
ErrorProvider.propTypes = {
  children: PropTypes.node,
  code: PropTypes.string,
}

export default ErrorContext
