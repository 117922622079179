import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Container = styled.div`
  font-size: 12px;
  font-weight: 400;
  height: auto;
  width: 100%;
  span,
  a {
    color: var(--white);
    font-size: 14px;
    line-height: 21px;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`

export const FooterTop = styled.div`
  background-color: var(--grey);
  display: flex;
  height: 100%;
  min-height: 175px;
  padding: 0 15% 30px;
  position: relative;
  width: 100%;
  a {
    display: block;
    width: 98%;
  }
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    padding: 20px 30px;
    width: 100%;
  }
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    display: block;
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    padding: 0 10px 30px;
  }
`

export const FooterBotton = styled.div`
  background-color: var(--darkGrey);
  padding: 12px 0;
  position: relative;
  text-align: center;
  width: 100%;
  a {
    color: var(--white);
    font-family: var(--fontRegular);
    font-size: 14px;
  }
`

export const CopyRightText = styled.div`
  color: var(--white);
  font-family: var(--fontRegular);
`

export const SectionTitle = styled.p`
  color: var(--white);
  font-family: var(--fontBold);
  margin: 0 0 7px;
  text-transform: uppercase;
`

export const BoxMenu = styled.div`
  padding-top: 50px;
  width: 25%;

  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    :first-child {
      margin-right: 15px;
    }
  }
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    display: inline-block;
    margin-top: 25px;
    text-align: left;
    vertical-align: top;
    width: 33%;
    p,
    a {
      display: block;
      text-align: left;
      width: 100%;
    }
    :first-child {
      display: block;
      margin-top: 0;
      padding-top: 10px;
      text-align: left;
      width: 100%;
    }
  }
  @media (max-width: ${DEVICES.SIZE_MOBILE}) {
    width: 100%;
  }
  svg {
    display: inline-block;
    max-width: 145px;
  }
`

export const BoxMenuSocial = styled.div`
  width: 25%;
  svg {
    display: block;
    max-width: 145px;
  }
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    > div {
      margin: 40px auto;
    }
    > div:first-child {
      margin: 0;
      max-width: 165px;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    width: 100%;
    > div:first-child {
      max-width: 100%;
      text-align: left;
      top: 90px;
      width: 90%;
    }
    > div {
      text-align: center;
    }
  }
`

export const IconContainer = styled.div`
  margin-top: 15px;
  text-align: right;
  width: 125px;
  a {
    display: inline;
  }
  svg {
    display: inline-block;
    fill: var(--white);
    margin-left: 8px;
    max-height: 22px;
    max-width: 22px;
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
`
