import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import * as Sc from './PasswordReset.style'
import FormPasswordReset from 'components/formPasswordReset'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const PasswordRecovery = () => {
  const { t } = useTranslation()
  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.PASSWORD_RESET)
  }, [])
  return (
    <Sc.Container>
      <Sc.Main>
        <h2>{t('Restablece tu contraseña')}</h2>
        <FormPasswordReset />
      </Sc.Main>
    </Sc.Container>
  )
}

export default PasswordRecovery
