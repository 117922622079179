import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import * as Sc from './Login.style'
import CoverAddBook from 'components/coverAddBook'
import FormLogin from 'components/formLogin'
import LoginWithGoogle from 'components/LoginWithGoogle'
import useLoginWithLicenseContext from 'hooks/useLoginWithLicenseContext'
import { sessionStorageGetItem } from 'utils/storage'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const Login = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { licenseLogin } = useLoginWithLicenseContext()
  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.LOGIN)
  }, [])

  const courseLevel =
    licenseLogin?.license.product.publication.course.level.name
  const courseName = licenseLogin?.license.product.publication.course.name
  const isStudent = licenseLogin?.license.product.target === 'STUDENT'
  const title = licenseLogin
    ? t('Accede a tu cuenta para vincular el libro digital')
    : t('Entra en Edubook y accede a tus libros')
  const info = licenseLogin
    ? t('¿Todavía no tienes cuenta?')
    : t('¿Aún no tienes cuenta de Edubook?')

  let link = null
  switch (licenseLogin?.ageRange) {
    case 'UNDER_14':
      link = <Link to={t('routes::/register/family')}>{t('Regístrate')}</Link>
      break
    case 'NEAR_14':
      if (isStudent) {
        link = (
          <>
            <Link to={t('routes::/register')}>{t('Soy mayor de 14 años')}</Link>
            /
            <Link to={t('routes::/register/family')}>
              {t('Soy menor de 14 años')}
            </Link>
          </>
        )
      } else {
        link = <Link to={t('routes::/register')}>{t('Regístrate')}</Link>
      }
      break
    case 'OVER_14':
      link = <Link to={t('routes::/register')}>{t('Regístrate')}</Link>
      break

    default:
      link = window.fflags?.showTeachingArea && (
        <a
          data-testid='teaching-area-link-public-web'
          href={`${window.config?.teachingAreaUrl}?lang=${language}`}
          rel='noreferrer'
        >
          {t('Registro para docentes')}
        </a>
      )
      break
  }

  let url = `https://vicensvivesdigital.zendesk.com/hc/${language}/articles/360018622257`
  if (language === 'en') {
    url = 'https://vicensvivesdigital.zendesk.com/hc/es/articles/360018622257'
  }

  return (
    <Sc.Container>
      <Sc.Main>
        <Sc.Title>{title}</Sc.Title>
        {licenseLogin && (
          <Sc.SubTitle>
            {isStudent
              ? t(
                  'Vincula el libro digital a tu cuenta para aprender con Edubook'
                )
              : t(
                  'Vincula el libro digital a tu cuenta para aprovechar todas las ventajas de Edubook'
                )}
          </Sc.SubTitle>
        )}
        <Sc.Box>
          {licenseLogin && (
            <CoverAddBook
              course={`${t(courseName)} ${t(courseLevel)}`}
              image={
                licenseLogin.license.product.publication.covers?.cua_256?.url
              }
              licenseCode={licenseLogin.license.code}
              title={licenseLogin.license.product.publication.title}
            />
          )}
          <Sc.ContainerForm hasLicense={licenseLogin}>
            <FormLogin
              bookId={sessionStorageGetItem('regionalBookId') || ''}
              licenseCode={licenseLogin?.license.code}
              target={licenseLogin?.license.product.target}
            />
            <Sc.License>
              <p>{info}</p>
              <div>{link}</div>
            </Sc.License>
          </Sc.ContainerForm>
        </Sc.Box>
        {!licenseLogin && (
          <Sc.Google>
            <p>
              {t(
                '¿Tu cuenta Edubook de docente o estudiante ya esta vinculada con Google?'
              )}
            </p>
            <LoginWithGoogle />
            <a href={url} rel='noreferrer' target='_blank'>
              {t('¿Necesitas ayuda para vincular tu cuenta?')}
            </a>
          </Sc.Google>
        )}
      </Sc.Main>
    </Sc.Container>
  )
}

export default Login
