import { useEffect, useLayoutEffect } from 'react'

import { useTranslation, Trans } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import * as Sc from './Features.style'
import Activities from 'assets/icons/activities'
import Cloud from 'assets/icons/cloud'
import Conection from 'assets/icons/conection'
import Devices from 'assets/icons/devices'
import Gym from 'assets/icons/gym'
import Interactive from 'assets/icons/interactive'
import Material from 'assets/icons/material'
import Media from 'assets/icons/media'
import caracteristicFourCa from 'assets/images/feature/ca/caracteristic_four.png'
import caracteristicOneCa from 'assets/images/feature/ca/caracteristic_one.png'
import caracteristicThreeCa from 'assets/images/feature/ca/caracteristic_three.png'
import caracteristicTwo from 'assets/images/feature/caracteristic_two.png'
import caracteristicFourEn from 'assets/images/feature/en/caracteristic_four.png'
import caracteristicOneEn from 'assets/images/feature/en/caracteristic_one.png'
import caracteristicThreeEn from 'assets/images/feature/en/caracteristic_three.png'
import caracteristicFourEs from 'assets/images/feature/es/caracteristic_four.png'
import caracteristicOneEs from 'assets/images/feature/es/caracteristic_one.png'
import caracteristicThreeEs from 'assets/images/feature/es/caracteristic_three.png'
import studentsImg from 'assets/images/feature/students.webp'
import Footer from 'components/footer'
import Hero from 'components/hero'
import TextImage from 'components/TextImage'
import i18n from 'config/i18n'
import { grafanaUtils } from 'utils/Grafana'
import { GRAFANA_VIEWS } from 'utils/constants/grafana'

const Features = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { search } = useLocation()
  const { push } = useHistory()

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(search)
    if (!searchParams.get('qr')) {
      push(t('routes::/'))
    }
  }, [])

  useEffect(() => {
    grafanaUtils.setView(GRAFANA_VIEWS.FEATURES)
  }, [])

  const img = {
    four: {
      ca: caracteristicFourCa,
      en: caracteristicFourEn,
      es: caracteristicFourEs,
    },
    one: {
      ca: caracteristicOneCa,
      en: caracteristicOneEn,
      es: caracteristicOneEs,
    },
    three: {
      ca: caracteristicThreeCa,
      en: caracteristicThreeEn,
      es: caracteristicThreeEs,
    },
  }
  return (
    <>
      <Sc.Container>
        <Hero
          alt={t('Fondo de estudiantes con pc')}
          image={studentsImg}
          subTitle={t(
            'Más de 1800 centros educativos y de 100000 estudiantes y docentes ya utilizan Edubook'
          )}
          title={t('Descubre el entorno digital de Vicens Vives')}
        />
        <Sc.Feature>
          <TextImage
            alt='Características de la aplicación de edubook'
            border
            img={img.one[i18n.language]}
            imgLeft={false}
            right
          >
            <Trans i18nKey='Diseñado para el entorno digital'>
              <h2>Diseñado para el entorno digital</h2>
              <p>
                La estructura del libro se ha adaptado al entorno digital
                facilitando la navegación y convirtiéndola en una herramienta
                intuitiva y fácil de usar.
              </p>
              <p>
                Edubook se ha desarrollado con tecnología propia para garantizar
                su versatilidad y adaptabilidad a todos los dispositivos,
                sistemas operativos y navegadores.
              </p>
              <div>
                <Devices />
                <h3>Multidispositivo y multiplataforma</h3>
              </div>
              <p>
                Además de la versión online de Edubook, contamos con
                aplicaciones para iOS y Android, versión de escritorio para
                Windows, Mac y Linux y versión para PDI (Pizarra Digital
                Interactiva). También es posible la integración con Moodle y LMS
                compatibles con protocolo Marsupial.
              </p>
              <div>
                <Conection />
                <h3>Con o sin conexión a internet</h3>
              </div>
              <p>
                Las aplicaciones de Edubook permiten consultar el libro y
                realizar las actividades en cualquier lugar y sin depender de la
                conexión a internet.
              </p>
              <p>
                Las actividades realizadas en modo offline se sincronizan
                automáticamente cuando se establece la conexión a internet, por
                lo que nunca se pierde información.
              </p>
            </Trans>
          </TextImage>
          <TextImage
            alt='Características de la aplicación de edubook'
            border
            img={caracteristicTwo}
          >
            <Trans i18nKey='Gran variedad de contenido'>
              <h2>Gran variedad de contenido</h2>
              <p>
                El mejor contenido educativo con todas las ventajas del entorno
                digital y actividades autocorrectivas y abiertas para potenciar
                el aprendizaje personalizado.
              </p>

              <div>
                <Interactive />
                <h3>Contenido interactivo</h3>
              </div>
              <p>
                Edubook completa el contenido teórico del libro de texto con
                imágenes, vídeos, audios, animaciones 3D, enlaces y mapas que
                enriquecen las explicaciones.
              </p>
              <p>
                Además, con Edubook es posible realizar anotaciones y añadir
                enlaces propios dentro del libro.
              </p>

              <div>
                <Activities />
                <h3>Actividades</h3>
              </div>

              <p>
                Edubook dispone de actividades autocorrectivas y actividades
                abiertas que permitirán al alumnado avanzar a su propio ritmo y
                recibir una valoración inmediata.
              </p>
              <p>
                En cada actividad de Edubook se indica su equivalente en el
                libro de texto en papel, facilitando el uso conjunto de ambos
                recursos.
              </p>
            </Trans>
          </TextImage>
          <TextImage
            alt='Características de la aplicación de edubook'
            border
            img={img.three[i18n.language]}
            imgLeft={false}
            right
          >
            <Trans i18nKey='Recursos adicionales en la Zona ZOOM'>
              <h2>Recursos adicionales en la Zona ZOOM</h2>
              <p>
                Descubre la Zona ZOOM, una nueva área con recursos extra y
                actualización permanente, para practicar y afianzar los
                conceptos aprendidos en el primer ciclo de Primaria.
              </p>
              <p>Este espacio dispone de tres tipos de recursos:</p>

              <div>
                <Gym />
                <h3>Gimnasio</h3>
              </div>
              <p>
                Diferentes actividades autocorrectivas e interactivas para
                afianzar conocimientos.
              </p>

              <div>
                <Media />
                <h3>Mediateca</h3>
              </div>
              <p>Recursos audiovisuales para complementar lo aprendido.</p>

              <div>
                <Cloud />
                <h3>Biblioteca en la nube</h3>
              </div>
              <p>Variedad de textos para disfrutar con la lectura.</p>
              <a
                href={`http://zoomprimaria.vicensvives.com/?lang=${language}&_ga=2.221752860.548018809.1616663432-780997398.1615286388`}
                id='proyectozoombutton'
                rel='noreferrer'
                target='_blank'
              >
                Conoce el proyecto ZOOM
              </a>
            </Trans>
          </TextImage>
          <TextImage
            alt='Características de la aplicación de edubook'
            border
            img={img.four[i18n.language]}
          >
            <Trans i18nKey='Trazabilidad educativa y recursos didácticos'>
              <h2>Trazabilidad educativa y recursos didácticos</h2>
              <p>
                En cada actividad se registra la calificación y el número de
                intentos realizados, permitiendo que el profesorado obtenga la
                información necesaria para valorar la evolución de su alumnado.
              </p>
              <p>
                En la sección de calificaciones podremos realizar un seguimiento
                de cada clase, gestionar las evaluaciones y tener una visión
                global de cada grupo en tiempo real.
              </p>

              <div>
                <Material />
                <h3>Herramientas para el docente</h3>
              </div>

              <p>
                En el área de docente encontraremos las guías didácticas,
                propuestas curriculares, recursos para la atención a la
                diversidad, evaluaciones y todo el material de apoyo para
                facilitar la preparación de las clases.
              </p>
            </Trans>
          </TextImage>
        </Sc.Feature>
      </Sc.Container>
      <Footer />
    </>
  )
}

export default Features
