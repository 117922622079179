import styled from 'styled-components/macro'

export const Modal = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: calc(100vh - 70px);
  position: absolute;
  top: 70px;
  background: white;
  width: 100%;
`

export const Container = styled.div`
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  min-height: 490px;
  padding: 100px 70px;
  @media (max-width: 580px) {
    padding-left: 0;
    padding-right: 0;
  }
  max-width: 580px;
`

export const Logo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
  & svg {
    width: 90px;
  }
  @media (max-width: 300px) {
    width: 100%;
    & svg {
      width: 100%;
    }
  }
`

export const Btn = styled.button`
  background-color: var(--yellow);
  border-radius: 3px;
  border: none;
  color: var(--grey);
  flex-grow: 0;
  font-family: var(--fontRegular);
  cursor: pointer;
  font-size: 14px;
  margin: 29px auto;
  padding: 15px 10px;
  text-align: center;
  max-width: 200px;
`

export const Message = styled.p`
  margin: 27px 0;
  font-family: var(--fontBold);
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  @media (max-width: 580px) {
    max-width: 440px;
    margin-left: 10px;
    margin-right: 10px;
  }
  color: var(--grey);
`

export const Help = styled.a`
  flex-grow: 0;
  margin: 23px auto;
  font-family: var(--fontRegular);
  font-size: 14px;
  text-align: center;
  color: var(--footerLng);
  text-decoration: underline;
`
