import { PropTypes } from 'prop-types'
import { createPortal } from 'react-dom'

import usePortal from 'hooks/usePortal'

const Portal = ({ children, id }) => {
  const target = usePortal(id)
  return createPortal(children, target)
}

Portal.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
}

export default Portal
