function Video() {
  return (
    <svg
      height='512'
      viewBox='0 0 512 512'
      width='512'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M69.772 302.582H46.581v23.191h23.191v-23.191zM46.582 69.873h23.191V46.682H46.582v23.191zm0 46.581h23.191V93.063H46.582v23.391zm23.19 279.092H46.581v23.191h23.191v-23.191zm0-46.582H46.581v23.191h23.191v-23.191zm-23.19-185.928h23.191v-23.391H46.582v23.391zm23.19 279.091H46.581v23.191h23.191v-23.191zm-23.19-232.709h23.191v-23.191H46.582v23.191zm395.445 0h23.191v-23.191h-23.191v23.191zm0-139.545h23.191V46.682h-23.191v23.191zm0 46.581h23.191V93.063h-23.191v23.391zM465.219.1H46.582C20.792.1 0 20.892 0 46.682v418.837c0 25.59 20.792 46.382 46.582 46.382h418.837c25.79 0 46.582-20.792 46.582-46.582V46.682C511.801 20.892 491.009.1 465.22.1zM92.963 488.709H46.581c-12.795 0-23.191-10.396-23.191-23.191V267.795h69.772v220.913zm0-244.304H23.191V46.682c0-12.795 10.396-23.191 23.191-23.191h46.582v220.913zm302.482 244.304H116.354V23.291h279.091v465.418zm93.164-23.391c0 12.795-10.396 23.191-23.191 23.191h-46.582V267.596h69.772v197.723zm0-220.913h-69.772V23.292h46.582c12.795 0 23.191 10.396 23.191 23.191v197.923zM197.723 348.964c2.799 0 5.198-1 7.197-2.599l.2.2 127.95-81.368-.2-.2c2.599-2.199 4.399-5.198 4.399-8.997 0-3.599-1.8-6.798-4.399-8.996l.2-.2-127.95-81.368-.2.2c-1.999-1.6-4.399-2.599-7.197-2.599-6.398 0-11.595 5.198-11.595 11.595v162.936c0 6.197 5.198 11.396 11.595 11.396zm11.595-153.34L304.081 256l-94.763 60.377V195.624zm232.709-32.588h23.191v-23.391h-23.191v23.391zm23.192 139.546h-23.191v23.191h23.191v-23.191zm0 46.382h-23.191v23.191h23.191v-23.191zm0 93.163h-23.191v23.191h23.191v-23.191zm0-46.581h-23.191v23.191h23.191v-23.191z' />
    </svg>
  )
}

export default Video
