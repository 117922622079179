import { TYPES_DOMAIN } from './constants'

export const emailIsCorrect = ({ email }) => {
  const pattern =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g
  const isCorrect = email?.match(pattern) !== null
  return isCorrect
}

export const getUrlParams = (paramName) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const params = urlParams?.get(paramName)
  return params
}

export const checkLicense = ({ license }) => {
  const initialLetters = /^(VT|VS)/i
  const validCharacters = /^[A-Z0-9]+$/i
  const valid = !!(
    license.match(initialLetters) !== null &&
    license.match(validCharacters) !== null &&
    (license.length === 8 || license.length === 12)
  )
  return valid
}

export const getUrlByParams = () => {
  const queryParams = window.location.search
    .substr(1)
    .split('&')
    .reduce((object, query) => {
      const resultObject = { ...object }
      const chunks = query.split('=')
      const key = chunks[0]
      const value = chunks[1]
      // eslint-disable-next-line no-return-assign
      return (resultObject[key] = value)
    }, {})
  const url = queryParams ? decodeURIComponent(queryParams) : ''
  return url
}

export const getDomain = () => {
  return window.location.hostname.split('.').slice(-2).join('.')
}

export const isDevelopment = () => {
  const isDev = window.location.hostname.split('.').slice(-2)
  return isDev?.length && isDev[1] !== TYPES_DOMAIN.PRO
}
