import axios from 'axios'

export const student = async ({
  bookId,
  email,
  language,
  licenseCode,
  name,
  password,
  surnames,
  terms,
}) => {
  const { data } = await axios.post('/students', {
    bookId,
    email,
    language,
    licenseCode,
    name,
    password,
    surnames,
    terms,
  })
  return data
}

export const teacher = async ({
  bookId,
  email,
  language,
  licenseCode,
  name,
  password,
  surnames,
  terms,
}) => {
  const { data } = await axios.post('/teachers', {
    bookId,
    email,
    language,
    licenseCode,
    name,
    password,
    surnames,
    terms,
  })
  return data
}

export const relatives = async (data) => {
  const response = await axios.post('/relatives', data)
  return response
}
