export const GRAFANA_VIEWS = {
  APLICATIONS_DESKTOP: 'applications-desktop',
  APPLICATIONS: 'application',
  APPLICATIONS_MOODLE: 'applications-moodle',
  CLASSROOM: 'classroom',
  DEMO: 'demo',
  FEATURES: 'features',
  HOME: 'home',
  LICENSE: 'license',
  LOGIN: 'login',
  PASSWORD_RECOVERY: 'password-recovery',
  PASSWORD_RECOVERY_SENT: 'password-recovery-sent',
  PASSWORD_RESET: 'password-reset',
  REGISTER: 'register',
  REGISTER_FAMILY: 'register-family',
}
