import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Container = styled.section`
  display: flex;
  justify-content: center;
`
export const Main = styled.main`
  display: flex;
  gap: 40px;
  padding: 60px 0;
  width: 790px;
  @media (max-width: ${DEVICES.SIZE_LAPTOP}) {
    width: 80%;
  }
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
export const Description = styled.div`
  > h2 {
    color: var(--darkRed);
    font-family: var(--fontRegular);
    font-size: 30px;
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 30px;
    width: 100%;
  }
  > p {
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 17px;
    line-height: normal;
    margin-bottom: 7px;
  }
`
export const Form = styled.iframe`
  min-height: 85.3125rem;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    width: 90%;
  }
`
