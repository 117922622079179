import styled from 'styled-components/macro'

import { DEVICES } from 'utils/constants'

export const Book = styled.div`
  align-items: center;
  border: 2px solid var(--yellow);
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 200px;
  padding: 15px 20px 20px;
  p {
    :first-child {
      font-family: var(--fontSansBold);
    }
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 17px;
    margin: 0;
    text-align: center;
  }
  img {
    margin-top: 15px;
    max-height: 155px;
    vertical-align: top;
    width: 100%;
  }
`
export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 65px;
  @media (max-width: ${DEVICES.SIZE_TABLET}) {
    padding: 30px 0;
    svg {
      transform: rotate(90deg);
    }
  }
  p {
    color: var(--grey);
    font-family: var(--fontSansRegular);
    font-size: 14px;
    line-height: initial;
    margin: 0 0 7px;
    max-width: 90px;
    text-align: center;
  }
  svg {
    fill: var(--yellow);
    height: 40px;
    width: 40px;
  }
`
