const Icon = () => (
  <svg
    id='icon-vv-logo'
    viewBox='0 0 6212 1024'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>vv-logo</title>
    <path
      className='path1'
      d='M1021.573 1024h4.853l-2.427-2.427v-1019.147l2.427-2.427h-1026.427v1024h4.853z'
      fill='rgb(230, 43, 38)'
    />
    <path
      className='path2'
      d='M1404.967 317.877c-33.972-94.635-41.251-94.635-126.18-109.194v-21.839h201.403l203.829 565.384h2.427l196.55-565.384h67.943l-252.36 693.991h-89.782l-203.829-562.957z'
      fill='rgb(255, 255, 255)'
    />
    <path
      className='path3'
      d='M2018.882 536.265c0-72.796-16.986-89.782-80.076-92.209v-21.839l181.991-33.972v492.588h-104.341v-344.569h2.427zM2006.749 242.654c0-33.972 29.118-63.090 65.517-63.090 33.972 0 65.517 29.118 65.517 63.090 0 36.398-31.545 65.517-67.943 65.517-33.972 0-63.090-29.118-63.090-65.517z'
      fill='rgb(255, 255, 255)'
    />
    <path
      className='path4'
      d='M2584.265 868.701c-36.398 14.559-60.664 24.265-145.592 24.265-145.592 0-230.521-101.915-230.521-259.64 0-140.739 58.237-245.081 206.256-245.081 114.047 0 189.27 58.237 189.27 140.739h-121.327c0-41.251-9.706-106.768-75.223-106.768-70.37 0-84.929 77.649-84.929 174.711 0 152.872 70.37 237.801 165.005 237.801 31.545 0 75.223-7.28 99.488-14.559v48.531h-2.427z'
      fill='rgb(255, 255, 255)'
    />
    <path
      className='path5'
      d='M3025.896 866.275c-19.412 7.28-75.223 29.118-145.592 29.118-157.725 0-252.36-97.062-252.36-269.346 0-162.578 77.649-235.374 215.962-235.374 177.137 0 191.697 128.607 191.697 196.55h-293.611c0 104.341 41.251 249.934 189.27 249.934 43.678 0 80.076-9.706 97.062-16.986v46.104c0 0-2.427 0-2.427 0zM2931.261 545.972c0-24.265-2.427-121.327-92.209-121.327-77.649 0-99.488 82.502-99.488 121.327h191.697z'
      fill='rgb(255, 255, 255)'
    />
    <path
      className='path6'
      d='M3118.104 536.265c0-72.796-16.986-89.782-82.502-92.209v-21.839l186.844-33.972v133.46h2.427c14.559-36.398 70.37-133.46 172.284-133.46 94.635 0 126.18 65.517 126.18 145.592v346.995h-104.341v-266.919c0-82.502 0-152.872-70.37-152.872-67.943 0-126.18 97.062-126.18 169.858v252.36h-104.341l0-346.995z'
      fill='rgb(255, 255, 255)'
    />
    <path
      className='path7'
      d='M3884.891 485.308c-14.559-9.706-65.517-48.531-123.754-48.531-33.972 0-72.796 12.133-72.796 55.81 0 97.062 225.668 99.488 225.668 259.64 0 101.915-84.929 143.166-186.844 143.166-63.090 0-109.194-12.133-133.46-19.412v-80.076c29.118 21.839 77.649 53.384 135.886 53.384 65.517 0 89.782-33.972 89.782-70.37 0-101.915-225.668-106.768-225.668-249.934 0-75.223 50.957-138.313 172.284-138.313 53.384 0 97.062 14.559 116.474 16.986l2.427 77.649z'
      fill='rgb(255, 255, 255)'
    />
    <path
      className='path8'
      d='M4163.943 317.877c-33.972-94.635-41.251-94.635-126.18-109.194v-21.839h201.403l203.829 565.384h2.427l196.55-565.384h67.943l-252.36 696.417h-89.782l-203.829-565.384z'
      fill='rgb(255, 255, 255)'
    />
    <path
      className='path9'
      d='M4787.564 536.265c0-72.796-16.986-89.782-80.076-92.209v-21.839l181.991-33.972v492.588h-104.341v-344.569h2.427zM4777.858 242.654c0-33.972 29.118-63.090 65.517-63.090 33.972 0 65.517 29.118 65.517 63.090 0 36.398-31.545 65.517-67.943 65.517-33.972 0-63.090-29.118-63.090-65.517z'
      fill='rgb(255, 255, 255)'
    />
    <path
      className='path10'
      d='M5022.938 514.427c-26.692-70.37-36.398-87.355-106.768-92.209v-21.839h172.284l145.592 363.981 140.739-363.981h58.237l-184.417 480.455h-77.649l-148.019-366.408z'
      fill='rgb(255, 255, 255)'
    />
    <path
      className='path11'
      d='M5840.682 866.275c-19.412 7.28-75.223 29.118-145.592 29.118-157.725 0-252.36-97.062-252.36-269.346 0-162.578 77.649-235.374 215.962-235.374 177.137 0 191.697 128.607 191.697 196.55h-293.611c0 104.341 41.251 249.934 189.27 249.934 43.678 0 80.076-9.706 97.062-16.986l-2.427 46.104zM5746.047 545.972c0-24.265-2.427-121.327-92.209-121.327-77.649 0-99.488 82.502-99.488 121.327h191.697z'
      fill='rgb(255, 255, 255)'
    />
    <path
      className='path12'
      d='M6182.825 485.308c-14.559-9.706-65.517-48.531-123.754-48.531-33.972 0-72.796 12.133-72.796 55.81 0 97.062 225.668 99.488 225.668 259.64 0 101.915-84.929 143.166-186.844 143.166-63.090 0-109.194-12.133-133.46-19.412v-80.076c29.118 21.839 77.649 53.384 135.886 53.384 65.517 0 89.782-33.972 89.782-70.37 0-101.915-225.668-106.768-225.668-249.934 0-75.223 50.957-138.313 172.284-138.313 53.384 0 97.062 14.559 116.474 16.986l2.427 77.649z'
      fill='rgb(255, 255, 255)'
    />
    <path
      className='path13'
      d='M674.578 647.886l4.853 2.427-4.853 2.427-118.9 211.109-4.853 12.133-4.853-12.133-393.1-672.152-7.28-9.706 7.28 2.427h245.081l9.706-4.853-2.427 4.853z'
      fill='rgb(255, 255, 255)'
    />
    <path
      className='path14'
      d='M657.592 288.758c0-60.664 48.531-111.621 111.621-111.621 60.664 0 111.621 48.531 111.621 111.621 0 60.664-48.531 111.621-111.621 111.621s-111.621-48.531-111.621-111.621v0z'
      fill='rgb(255, 255, 255)'
    />
  </svg>
)

export default Icon
