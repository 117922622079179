import styled from 'styled-components/macro'

export const Button = styled.button`
  background-color: ${({ disabled }) =>
    disabled ? 'var(--buttonSilver)' : 'var(--yellow)'};
  border-radius: 3px;
  border: none;
  color: var(--buttonGrey);
  font-size: 14px;
  height: 45px;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  :hover {
    background-color: ${({ disabled }) =>
      disabled ? 'var(--buttonSilver)' : 'var(--yellowHover)'};
  }
`
