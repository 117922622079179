import PropTypes from 'prop-types'

import * as Sc from './Hero.style'

const Hero = ({ alt, children, image, subTitle, title }) => (
  <Sc.Image hasChildren={!!children}>
    <Sc.ImgContainer>
      <Sc.Text hasChildren={!!children}>
        <Sc.Title>{title}</Sc.Title>
        <Sc.SubTitle>{subTitle}</Sc.SubTitle>
        <Sc.Content>{children}</Sc.Content>
      </Sc.Text>
      <img alt={alt} src={image} />
    </Sc.ImgContainer>
  </Sc.Image>
)
Hero.defaultProps = {
  children: null,
}

Hero.propTypes = {
  alt: PropTypes.string.isRequired,
  children: PropTypes.node,
  image: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Hero
