import { createGlobalStyle } from 'styled-components'

import { Base } from './_base'
import { Colors } from './_colors'
import { Fonts } from './_fonts'
import { Reset } from './_reset'

const GlobalStyle = createGlobalStyle`
  ${Colors}
  ${Fonts}
  ${Reset}
  ${Base}
`

export default GlobalStyle
