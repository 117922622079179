import styled from 'styled-components'

export const Overlay = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`

export const Container = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 0.625rem;
  box-shadow: 0rem 0.125rem 0.25rem 0rem rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  padding: 3rem 1.5rem 2rem 1.5rem;
  width: 90%;
  > button {
    max-width: 100%;
  }
  @media (width > 383px) {
    gap: 2rem;
    max-width: 34.375rem;
    padding: 4rem 3rem 3rem 3rem;
    > svg {
      height: 5.3125rem;
      width: 16.75rem;
    }
  }
  @media (width > 550px) {
    max-width: 40.625rem;
  }
`

export const TitleModal = styled.h2`
  color: #444;
  font-family: Montserrat;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  @media (width > 430px) {
    font-size: 2.0625rem;
  }
`

export const BodyModal = styled.p`
  color: #444;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.625rem;
  text-align: center;
`
