import * as Sc from './loader.styles'

const loader = () => (
  <Sc.Container>
    <Sc.Layer>
      <Sc.Content>
        <Sc.Spinner />
      </Sc.Content>
    </Sc.Layer>
  </Sc.Container>
)

export default loader
